import { IYachtPrice } from "shared/types/yacht/IYachtPrice";
import { IMembership } from "shared/types/membership";

const getFreeMembershipPrice = (prices: IYachtPrice[]) => {
  const freeMembership = prices.find(({ membership_id }) => !membership_id);
  return freeMembership?.price || 0;
};

const getUserMembershipPrice = (
  prices: IYachtPrice[],
  userMembership?: IMembership
) => {
  if (!userMembership) return getFreeMembershipPrice(prices);
  const membershipPrice = prices.find(
    ({ membership_id }) => userMembership?.id === membership_id
  );
  return membershipPrice?.price || 0;
};

export { getFreeMembershipPrice };
export default getUserMembershipPrice;
