import { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import YachtReserveCard from "components/molecules/YachtReserveCard";
import FetchSection from "components/molecules/FetchSection";
import {
  MyBookingsFilter,
  MyBookingsNavigation,
  MyBookingsSection,
  MyBookingsFilters,
  MyBookingsSlider,
  ShareBookingModal,
} from "components/atoms/Profile/MyBookings";
import useUserReservations from "hooks/useUserReservations";
import getFormattedDate from "utils/getFormattedDate";
import getFormattedHour from "utils/getFormattedHour";
import IconButton from "components/atoms/IconButton";
import assets from "shared/constants/assets";
import routes from "shared/constants/routes";
import getReservePdfUrl from "utils/getReservePdfUrl";

const { arrowSecondaryLeft, arrowSecondaryRight, defaultYachtImage } = assets;
const { userReservationDetails } = routes;

enum EBookingsFilters {
  PAST = "past",
  FUTURE = "future",
}

interface IShareBookingModalState {
  isOpen: boolean;
  reserveId?: number;
}

const MyBookings = () => {
  const { t } = useTranslation();
  const sliderRef = useRef<Slider | null>(null);
  const {
    userReservations,
    setPastFilter,
    isFetchingUserReservations,
    hasErrorLoadingUserReservations,
  } = useUserReservations();
  const [activeBookingsFilter, setActiveBookingsFilter] =
    useState<EBookingsFilters>(EBookingsFilters.FUTURE);
  const [shareBookingModalState, setShareBookingModalState] =
    useState<IShareBookingModalState>({
      isOpen: false,
    });

  const onOpenShareBookingModal = (reserveId: number) => () => {
    setShareBookingModalState({ isOpen: true, reserveId });
  };

  const onCloseShareBookingModal = () => {
    setShareBookingModalState({ isOpen: false, reserveId: -1 });
  };

  useEffect(() => {
    setPastFilter(activeBookingsFilter === EBookingsFilters.PAST);
  }, [activeBookingsFilter]);

  const onChangeToPastBookings = () => {
    setActiveBookingsFilter(EBookingsFilters.PAST);
  };

  const onChangeToFutureBookings = () => {
    setActiveBookingsFilter(EBookingsFilters.FUTURE);
  };

  const onNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const onPreviousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <>
      <MyBookingsSection>
        <MyBookingsNavigation>
          <IconButton
            src={arrowSecondaryLeft}
            alt="go to previous page"
            width="25px"
            height="25px"
            onClick={onPreviousSlide}
          />
          <MyBookingsFilters>
            <MyBookingsFilter
              onClick={onChangeToFutureBookings}
              isActive={activeBookingsFilter === EBookingsFilters.FUTURE}
            >
              {t("profile.reserves.nav.future")}
            </MyBookingsFilter>
            <MyBookingsFilter
              onClick={onChangeToPastBookings}
              isActive={activeBookingsFilter === EBookingsFilters.PAST}
            >
              {t("profile.reserves.nav.past")}
            </MyBookingsFilter>
          </MyBookingsFilters>
          <IconButton
            src={arrowSecondaryRight}
            alt="go to next page"
            width="25px"
            height="25px"
            onClick={onNextSlide}
          />
        </MyBookingsNavigation>
        <FetchSection
          isFetching={isFetchingUserReservations}
          hasError={hasErrorLoadingUserReservations}
          isEmpty={userReservations.length === 0}
          hasErrorMessage={t("profile.reserves.error-loading-reserves")}
          isEmptyMessage={t("profile.reserves.no-reserves")}
        >
          <MyBookingsSlider ref={sliderRef}>
            {userReservations.map(
              ({ yacht, passengers_number, start_date, end_date, id }) => (
                <YachtReserveCard
                  key={id}
                  detailsUrl={userReservationDetails(id)}
                  yachtName={yacht.name}
                  yachtModelName={yacht.model || ""}
                  date={getFormattedDate(start_date)}
                  departureHour={getFormattedHour(start_date)}
                  arrivalHour={getFormattedHour(end_date)}
                  numberOfPassengers={passengers_number}
                  coverUrl={yacht.default_image?.url || defaultYachtImage}
                  onShare={onOpenShareBookingModal(id)}
                  pdfUrl={getReservePdfUrl(id)}
                />
              )
            )}
          </MyBookingsSlider>
        </FetchSection>
      </MyBookingsSection>
      <ShareBookingModal
        isOpen={shareBookingModalState.isOpen}
        onContinue={onCloseShareBookingModal}
        shareUrl={getReservePdfUrl(shareBookingModalState.reserveId)}
      />
    </>
  );
};

export default MyBookings;
