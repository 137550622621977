import styled from "styled-components";
import { borders, devices } from "shared/constants/design";

const { borderRadiusLg } = borders;
const { desktop } = devices;

const YachtCardImage = styled.img`
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
  border-radius: ${borderRadiusLg} 0 0 ${borderRadiusLg};

  @media ${desktop} {
    max-height: 180px;
  }
`;

export { YachtCardImage };
