import { FC, useRef } from "react";
import styled from "styled-components";
import { Popover, Overlay } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SecondaryButton from "components/atoms/SecondaryButton";
import PrimaryButton from "components/atoms/PrimaryButton";

const ActionButtonsContainer = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: auto auto;
  padding: 20px;
  padding-top: 0px;
`;

type ToDefineTargetType = any;

interface IPopConfirm {
  onConfirm?: () => void;
  confirmText?: () => void;
  cancelText?: () => void;
  descriptionText?: () => void;
  show: boolean;
  onClose: () => void;
  target?: ToDefineTargetType;
}

const PopConfirm: FC<IPopConfirm> = ({
  onConfirm = () => {},
  target,
  onClose,
  show,
  confirmText,
  cancelText,
  descriptionText,
  children,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);

  const handleOnConfirm = () => {
    onClose();
    onConfirm();
  };

  return (
    <div ref={containerRef}>
      {children}
      <Overlay
        show={show}
        target={target}
        placement="right"
        container={containerRef}
      >
        <Popover id="popover-contained">
          <Popover.Body>
            {descriptionText || t("pop.confirm.description")}
          </Popover.Body>
          <ActionButtonsContainer>
            <SecondaryButton onClick={onClose}>
              {cancelText || t("pop.confirm.no")}
            </SecondaryButton>
            <PrimaryButton onClick={handleOnConfirm}>
              {confirmText || t("pop.confirm.yes")}
            </PrimaryButton>
          </ActionButtonsContainer>
        </Popover>
      </Overlay>
    </div>
  );
};

export default PopConfirm;
