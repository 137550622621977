import { Link } from "react-router-dom";
import styled from "styled-components";
import assets from "shared/constants/assets";
import routes from "shared/constants/routes";

const { profile } = routes;
const { user } = assets;

const UserIcon = styled.img`
  width: 25px;
  height: 25px;
`;

const ProfileIcon = () => (
  <Link to={profile}>
    <UserIcon src={user} alt="" />
  </Link>
);

export { ProfileIcon };
