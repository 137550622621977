import { ChangeEvent, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useReservationContext from "context/ReservationContext";
import PassengersNumberSelector from "components/molecules/PassengersNumberSelector";
import { Select } from "components/atoms/form";
import useYachtsFiltersOptions from "hooks/useYachtsFiltersOptions";
import { ELocationStatus } from "shared/types/location/ELocationStatus";

const CLEAR_OPTION_VALUE = "clear";

const YachtsListFilters = () => {
  const { t } = useTranslation();
  const {
    availableLocations,
    selectedLocationId,
    setYachtsLocationFilter,
    setYachtsPassengersNumberFilter,
    setYachtsSizeFilter,
    setYachtsMinHoursFilter,
  } = useReservationContext();
  const passengersNumberFilterRef = useRef<HTMLSelectElement>(null);
  const yachtSizeFilterRef = useRef<HTMLSelectElement>(null);
  const minHoursFilterRef = useRef<HTMLSelectElement>(null);
  const { yachtSizes, maxNumberOfPassengers, minHoursOptions } =
    useYachtsFiltersOptions();
  const activeLocations = availableLocations.filter(
    ({ status }) => status === ELocationStatus.ACTIVE
  );

  const clearYachtSizeFilter = () => {
    if (yachtSizeFilterRef.current) {
      yachtSizeFilterRef.current.value = "";
      setYachtsSizeFilter(undefined);
    }
  };

  useEffect(() => {
    clearYachtSizeFilter();
  }, [selectedLocationId]);

  const onLocationFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setYachtsLocationFilter(Number(e.target.value));
  };

  const onPassengersNumberFilterChange = (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    const value = e.target.value;
    if (value === CLEAR_OPTION_VALUE && passengersNumberFilterRef.current) {
      passengersNumberFilterRef.current.value = "";
      setYachtsPassengersNumberFilter(undefined);
      return;
    }
    const passengersNumberFilter = value ? Number(value) : undefined;
    setYachtsPassengersNumberFilter(passengersNumberFilter);
  };

  const onYachtSizeFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === CLEAR_OPTION_VALUE) {
      clearYachtSizeFilter();
      return;
    }
    const yachtSizeFilter = value ? Number(value) : undefined;
    setYachtsSizeFilter(yachtSizeFilter);
  };

  const onYachtMinHoursFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === CLEAR_OPTION_VALUE && minHoursFilterRef.current) {
      minHoursFilterRef.current.value = "";
      setYachtsMinHoursFilter(undefined);
      return;
    }
    const yachtMinHoursFilter = value ? Number(value) : undefined;
    setYachtsMinHoursFilter(yachtMinHoursFilter);
  };

  const clearFilterOption = (
    <option value={CLEAR_OPTION_VALUE}>{t("yachts.list.filter.clear")}</option>
  );

  const minHoursFilterOptions = minHoursOptions.map((value, index) => (
    <option value={value} key={index}>
      {value}
    </option>
  ));

  return (
    <>
      <Select
        name="location"
        placeholder={t("yachts.list.filters.location")}
        defaultValue={selectedLocationId}
        onChange={onLocationFilterChange}
      >
        {activeLocations.map(({ name, id }) => (
          <option value={id} key={id}>
            {name}
          </option>
        ))}
      </Select>
      <PassengersNumberSelector
        name="size"
        placeholder={t("yachts.list.filters.size")}
        onChange={onPassengersNumberFilterChange}
        ref={passengersNumberFilterRef}
        passengersNumber={maxNumberOfPassengers}
      >
        {clearFilterOption}
      </PassengersNumberSelector>
      <Select
        name="yacht-size"
        placeholder={t("yachts.list.filters.yacht-size")}
        onChange={onYachtSizeFilterChange}
        ref={yachtSizeFilterRef}
      >
        {clearFilterOption}
        {yachtSizes.map(({ id, number, measurement_unity }) => (
          <option value={id} key={id}>
            {number} {measurement_unity}
          </option>
        ))}
      </Select>
      <Select
        name="min_hours"
        placeholder={t("yachts.list.filters.min-duration")}
        onChange={onYachtMinHoursFilterChange}
        ref={minHoursFilterRef}
      >
        {clearFilterOption}
        {minHoursFilterOptions}
      </Select>
    </>
  );
};

export { YachtsListFilters };
