import styled from "styled-components";
import { colors, fonts, fontSizes } from "shared/constants/design";

const { text } = colors;
const { nexaRegular } = fonts;
const { sm } = fontSizes;

const ToastBody = styled.div`
  color: ${text};
  font-family: ${nexaRegular};
  font-size: ${sm};
  padding: 10px;
`;

export { ToastBody };
