import { ReservationProvider } from "context/ReservationContext";
import CurrentReservationStepView from "components/organisms/reservation";

const Home = () => (
  <ReservationProvider>
    <CurrentReservationStepView />
  </ReservationProvider>
);

export default Home;
