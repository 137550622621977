import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const MyMembershipInformationList = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 15px;
  height: fit-content;

  @media ${desktop} {
    align-items: flex-start;
  }
`;

export { MyMembershipInformationList };
