import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "context/AuthContext";
import { NotificationProvider } from "context/NotificationContext";
import GlobalStyles from "components/GlobalStyles";
import PageTemplate from "components/templates/PageTemplate";
import { Helmet } from "react-helmet";
import Home from "pages/Home";
import Register from "pages/Register";
import Login from "pages/Login";
import ResetPassword from "pages/ResetPassword";
import Profile from "pages/Profile";
import BookingDetails from "pages/Profile/BookingDetails";
import PrivateRoute from "components/atoms/PrivateRoute";
import Policies from "pages/Policies";
import routes from "shared/constants/routes";
import "react-datepicker/dist/react-datepicker.css";
import tenantService from "services/tenant";
import GrowthBookProvider from "components/organisms/GrowthBookProvider";
import ExoticRolloutTest from "components/organisms/ExoticRolloutTest";

const {
  register,
  login,
  home,
  resetPassword,
  profile,
  userReservations,
  policies,
} = routes;

const App = () => {
  return (
    <Router>
      {tenantService.getConfigs().flags.allowPWAAttributes && (
        <Helmet>
          <link
            rel="manifest"
            href={tenantService.getConfigs().pwaAttributes.manifestPath}
          />
        </Helmet>
      )}
      <GlobalStyles />
      <NotificationProvider>
        <GrowthBookProvider>
          <ExoticRolloutTest>
            <AuthProvider>
              <PageTemplate>
                <Switch>
                  {tenantService.getConfigs().flags.allowUserRegister && (
                    <Route exact path={register}>
                      <Register />
                    </Route>
                  )}
                  {tenantService.getConfigs().flags.allowLogin && (
                    <Route exact path={login}>
                      <Login />
                    </Route>
                  )}

                  {tenantService.getConfigs().flags.allowPasswordRecovery && (
                    <Route path={resetPassword}>
                      <ResetPassword />
                    </Route>
                  )}
                  <PrivateRoute exact path={home}>
                    <Home />
                  </PrivateRoute>
                  <PrivateRoute path={`${userReservations}/:bookingId`} exact>
                    <BookingDetails />
                  </PrivateRoute>
                  <PrivateRoute path={profile}>
                    <Profile />
                  </PrivateRoute>
                  <Route exact path={policies}>
                    <Policies />
                  </Route>
                </Switch>
              </PageTemplate>
            </AuthProvider>
          </ExoticRolloutTest>
        </GrowthBookProvider>
      </NotificationProvider>
    </Router>
  );
};

export default App;
