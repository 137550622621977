import { FC } from "react";
import { useFeatureValue } from "@growthbook/growthbook-react";
import { useTranslation } from "react-i18next";
import PageTemplate from "components/templates/PageTemplate";
import CenteredCard from "components/atoms/CenteredCard";
import { RegisterFormTitle } from "components/atoms/Register/RegisterFormTitle";
import styled from "styled-components";
import Text from "components/atoms/Text";
import PrimaryButton from "components/atoms/PrimaryButton";
import tenantService from "services/tenant";

const TextContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 12px;
`;

const TextBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const ExoticRolloutTest: FC = ({ children }) => {
  const { t } = useTranslation();
  const allowAccess = useFeatureValue("exotic-rollout-test", false);
  const tenantId = tenantService.getId();

  if (!(tenantId === "YV_EXOTIC_TRAVELERS")) {
    return <>{children}</>;
  }

  if (!allowAccess) {
    return (
      <PageTemplate renderStaticNavbar>
        <CenteredCard maxWidth="400px">
          <RegisterFormTitle>
            {t("exotic-rollout.preparing-experience")}
          </RegisterFormTitle>
          <TextBtnContainer>
            <TextContainer>
              <Text>{t("exotic-rollout.access-soon")}</Text>
            </TextContainer>
            <PrimaryButton>
              {t("exotic-rollout.back-button.label")}
            </PrimaryButton>
          </TextBtnContainer>
        </CenteredCard>
      </PageTemplate>
    );
  }

  return <>{children}</>;
};

export default ExoticRolloutTest;
