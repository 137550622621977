import styled from "styled-components";
import { isBrowserAndDesktopMedia, isMobileAndDesktopMedia } from "utils/css";

const MainReservationLayout = styled.div`
  display: grid;
  grid-gap: 30px;

  ${isBrowserAndDesktopMedia(`
    grid-template-rows: 1fr 50%;
    height: 100%;
  `)}

  ${isMobileAndDesktopMedia(`
    grid-template-rows: auto 500px;
  `)}
`;

export { MainReservationLayout };
