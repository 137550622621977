import http from "services/http";
import { IYachtAddon } from "shared/types/yacht/IYachtAditional";

const AddonService = () => {
  const getOne = (addonId: number) => {
    return http.get<IYachtAddon>(`/additional/${addonId}`);
  };

  return {
    getOne,
  };
};

const addonService = AddonService();

export default addonService;
