import styled from "styled-components";
import { fonts, fontSizes, colors } from "shared/constants/design";

const { text } = colors;
const { sm } = fontSizes;
const { nexaBold } = fonts;

const FormDescription = styled.p`
  color: ${text};
  font-family: ${nexaBold};
  font-size: ${sm};
  letter-spacing: 0.63;
  line-height: 17px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
`;

export { FormDescription };
