import {
  forwardRef,
  ChangeEventHandler,
  FocusEventHandler,
  ReactNode,
} from "react";
import styled from "styled-components";
import { colors, fonts, fontSizes } from "shared/constants/design";
import { When } from "react-if";
import { ControlGroup } from "./ControlGroup";
import { ErrorMessage } from "./ErrorMessage";

const { primary, primaryBorderColor } = colors;
const { xsm } = fontSizes;
const { nexaRegular } = fonts;

const BaseCheckbox = styled.div`
  font-family: ${nexaRegular};
  font-size: ${xsm};
  line-height: 1.8;
  margin: 0;

  & .form-check-input:checked {
    background-color: ${primary};
    border-color: ${primaryBorderColor};
  }
`;

interface ICheckbox {
  name: string;
  label?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  checked?: boolean;
  children?: ReactNode;
  hasError?: boolean;
  errorMessage?: string;
}

const Checkbox = forwardRef<HTMLInputElement, ICheckbox>(
  ({ label, children, hasError, errorMessage, ...inputProps }, ref) => (
    <ControlGroup width="fit-content">
      <BaseCheckbox className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          ref={ref}
          {...inputProps}
        />
        <label className="form-check-label">{label || children}</label>
      </BaseCheckbox>
      <When condition={hasError && errorMessage}>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </When>
    </ControlGroup>
  )
);

export { Checkbox };
