import IconButton from "components/atoms/IconButton";
import {
  YachtReserveCardContainer,
  YachtReserveCardCover,
  YachtReserveCardContent,
  YachtReserveCardName,
  YachtReserveCardModelName,
  YachtReserveCardDate,
  YachtReserveCardDetail,
  YachtReserveCardButton,
  YachtReserveCardActionArea,
} from "components/atoms/YachtReserveCard";
import { useTranslation } from "react-i18next";
import assets from "shared/constants/assets";

const { pdf } = assets;

interface IYachtReserveCard {
  yachtName: string;
  yachtModelName: string;
  coverUrl: string;
  date: string;
  departureHour: string;
  arrivalHour: string;
  numberOfPassengers: number;
  onShare?: () => void;
  detailsUrl: string;
  pdfUrl: string;
}

const YachtReserveCard = ({
  yachtName,
  yachtModelName,
  coverUrl,
  date,
  departureHour,
  arrivalHour,
  numberOfPassengers,
  onShare,
  detailsUrl,
  pdfUrl,
}: IYachtReserveCard) => {
  const { t } = useTranslation();

  return (
    <YachtReserveCardContainer>
      <YachtReserveCardCover src={coverUrl} alt={yachtName} onShare={onShare} />
      <YachtReserveCardContent>
        <div>
          <YachtReserveCardName>{yachtName}</YachtReserveCardName>
          <YachtReserveCardModelName>
            {yachtModelName}
          </YachtReserveCardModelName>
        </div>
        <div>
          <YachtReserveCardDate>{date}</YachtReserveCardDate>
          <YachtReserveCardDetail>
            {t("profile.reserves.card.departure")} {departureHour}
          </YachtReserveCardDetail>
          <YachtReserveCardDetail>
            {t("profile.reserves.card.arrival")} {arrivalHour}
          </YachtReserveCardDetail>
          <YachtReserveCardDetail>
            {numberOfPassengers} {t("profile.reserves.card.passengers")}
          </YachtReserveCardDetail>
        </div>
        <YachtReserveCardActionArea>
          <IconButton
            src={pdf}
            width="30px"
            height="30px"
            alt="see pdf"
            href={pdfUrl}
            target="_blank"
          />
          <YachtReserveCardButton href={detailsUrl}>
            {t("profile.reserves.card.details")}
          </YachtReserveCardButton>
        </YachtReserveCardActionArea>
      </YachtReserveCardContent>
    </YachtReserveCardContainer>
  );
};

export default YachtReserveCard;
