import styled from "styled-components";
import { colors, fontSizes, fonts } from "shared/constants/design";

const { text } = colors;
const { xsm } = fontSizes;
const { nexaRegular } = fonts;

const YachtReserveCardDetail = styled.h6`
  color: ${text};
  font: normal normal ${xsm} ${nexaRegular};
  text-decoration: none;
`;

export { YachtReserveCardDetail };
