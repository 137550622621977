import http from "services/http";
import type { ICountry } from "shared/types/country";

const CountryService = () => {
  const getCountries = async () => http.get<ICountry[]>("/country");

  return {
    getCountries,
  };
};

export default CountryService();
