import { useContext } from "react";
import NotificationContext from "./NotificationContext";

const useNotificationContext = () => {
  const contextValue = useContext(NotificationContext);

  if (contextValue === undefined) {
    throw new Error(
      "You must use useNotificationContext within a NotificationProvider"
    );
  }

  return contextValue;
};

export default useNotificationContext;
