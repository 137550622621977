import { FC } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import styled from "styled-components";
import { Else, If, Then } from "react-if";
import { colors, fonts, fontSizes } from "shared/constants/design";

const { primary, primaryHover } = colors;
const { nexaRegular } = fonts;
const { sm } = fontSizes;

const RouterLink = styled(ReactRouterLink)`
  color: ${primary};
  font-family: ${nexaRegular};
  font-size: ${sm};
  letter-spacing: 0.63px;
  line-height: 17px;

  &:hover {
    color: ${primaryHover};
  }
`;

const ExternalLink = styled.a`
  color: ${primary};
  font-family: ${nexaRegular};
  font-size: ${sm};
  letter-spacing: 0.63px;
  line-height: 17px;

  &:hover {
    color: ${primaryHover};
  }
`;

interface ILink {
  to: string;
  target?: "_blank" | "_self";
  isExternal?: boolean;
}

const Link: FC<ILink> = ({
  to,
  target = "_self",
  isExternal = false,
  children,
}) => (
  <If condition={isExternal}>
    <Then>
      <ExternalLink href={to} target={target} rel="noopener noreferrer">
        {children}
      </ExternalLink>
    </Then>
    <Else>
      <RouterLink to={to} target={target}>
        {children}
      </RouterLink>
    </Else>
  </If>
);

export default Link;
