import { ChangeEvent } from "react";
import { Checkbox } from "components/atoms/form";
import { IMembership } from "shared/types/membership";
import { IYachtAddon } from "shared/types/yacht/IYachtAditional";
import getFormattedPrice from "utils/getFormattedPrice";
import getUserMembershipPrice from "utils/getUserMembershipPrice";
import useTranslatedProperty from "hooks/useTranslatedProperty";

interface IAddonsSelector {
  addons?: IYachtAddon[];
  selectedAddons: IYachtAddon[];
  userMembership?: IMembership;
  onAddOnCheckboxChange: (
    addon: IYachtAddon
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
}

const AddonsSelector = ({
  addons = [],
  selectedAddons = [],
  userMembership,
  onAddOnCheckboxChange,
}: IAddonsSelector) => {
  const getTranslation = useTranslatedProperty();

  return (
    <>
      {addons?.map((addon) => {
        const addonPrice = getUserMembershipPrice(
          addon.prices || [],
          userMembership
        );
        const addonLabel = `${getTranslation(
          "name",
          addon
        )} (${getFormattedPrice(addonPrice)})`;

        return (
          <Checkbox
            label={addonLabel}
            name={addon.name_en}
            onChange={onAddOnCheckboxChange(addon)}
            checked={selectedAddons.some(({ id }) => id === addon.id)}
            key={addon.id}
          />
        );
      })}
    </>
  );
};

export { AddonsSelector };
