import styled, { css } from "styled-components";
import { colors, fontSizes, fonts } from "shared/constants/design";

const { text } = colors;
const { md } = fontSizes;
const { nexaRegular, nexaBold } = fonts;

interface IMyBookingsFilter {
  isActive?: boolean;
}

const MyBookingsFilter = styled.button<IMyBookingsFilter>(
  ({ isActive = false }) => {
    const activeTextDecoration = "underline";
    const activeFontFamily = nexaBold;
    const fontFamily = isActive ? activeFontFamily : nexaRegular;
    const textDecoration = isActive ? activeTextDecoration : "none";

    return css`
      background: none;
      border: none;
      color: ${text};
      font: normal normal ${md} ${fontFamily};
      text-decoration: ${textDecoration};
      text-transform: uppercase;

      &:hover {
        font-family: ${activeFontFamily};
        text-decoration: ${activeTextDecoration};
      }
    `;
  }
);

export { MyBookingsFilter };
