import mixpanel from "mixpanel-browser";

interface IInitPaymentActionsEvents {
  mixpanelIsEnabled: boolean;
}

const PaymentActionsEvents = ({
  mixpanelIsEnabled,
}: IInitPaymentActionsEvents) => {
  const newPaymentMethodAdded = () => {
    if (!mixpanelIsEnabled) return;
    mixpanel.track("New Payment Method Added");
  };

  const paymentMethodDeleted = () => {
    if (!mixpanelIsEnabled) return;
    mixpanel.track("Payment Method Deleted");
  };

  const favoritePaymentMethodSelected = () => {
    if (!mixpanelIsEnabled) return;
    mixpanel.track("Favorite Payment Method Selected");
  };

  return {
    newPaymentMethodAdded,
    paymentMethodDeleted,
    favoritePaymentMethodSelected,
  };
};

export default PaymentActionsEvents;
