import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const MyMembershipSectionsContainer = styled.div`
  display: grid;
  grid-gap: 35px;

  @media ${desktop} {
    display: flex;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
  }
`;

export { MyMembershipSectionsContainer };
