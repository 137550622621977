import styled from "styled-components";
import { colors, fontSizes, fonts, devices } from "shared/constants/design";

const { textInverted } = colors;
const { xl } = fontSizes;
const { nexaBold } = fonts;
const {desktop} = devices

const ProfileDetailsUserName = styled.h5`
  color: ${textInverted};
  font-family: ${nexaBold};
  font-size: ${xl};
  font-weight: unset;
  letter-spacing: 1.35px;
  line-height: 30px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;

  @media ${desktop} {
    text-align: right;
  }
`;

export { ProfileDetailsUserName };
