import qs from "qs";
import mixpanelService from "services/mixpanel";
import http from "services/http";
import type {
  IReservation,
  ICreateReservation,
  IGetAvailableHoursPerDay,
} from "shared/types/reservation";

const ReservationService = () => {
  const endpointName = "reserve";

  const getReservationsByUserId = async (
    userId: number,
    past?: boolean
  ): Promise<IReservation[]> => {
    const queryParams = qs.stringify({ user_id: userId, past: Boolean(past) });
    return http.get<IReservation[]>(`/${endpointName}?${queryParams}`);
  };

  const getReservationById = async (reservationId: number) =>
    http.get<IReservation>(`/${endpointName}/${reservationId}`);

  const createReservation = async (body: ICreateReservation) => {
    mixpanelService.track.bookingAttempt();
    return http.post<any>(`/${endpointName}`, body).then((response) => {
      mixpanelService.track.bookingCompleted();
      return response;
    });
  };

  const getAvailableHoursPerDay = (
    params: IGetAvailableHoursPerDay
  ): Promise<Date[]> => {
    const queryParams = qs.stringify(params);
    return http.get<Date[]>(
      `${endpointName}/available-hours-per-day?${queryParams}`
    );
  };

  return {
    getReservationsByUserId,
    getReservationById,
    createReservation,
    getAvailableHoursPerDay,
  };
};

export default ReservationService();
