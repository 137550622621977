import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  colors,
  fontWeights,
  fontSizes,
  fonts,
  devices,
} from "shared/constants/design";

const { text, textInverted, background } = colors;
const { semiBold } = fontWeights;
const { sm, md, lg } = fontSizes;
const { conthrax } = fonts;
const { desktop, desktopXl } = devices;

const activeNavClassName = "profile-nav--active";

const BaseProfileNavLink = styled(NavLink)`
  color: ${text};
  font: italic normal ${semiBold} ${sm} ${conthrax};
  text-decoration: none;
  text-transform: uppercase;

  &.${activeNavClassName} {
    background: ${background};
    border-radius: 10px;
    color: ${textInverted};
    padding: 10px 30px;
    transform: skew(-10deg);
  }

  @media ${desktop} {
    font-size: ${md};
  }

  @media ${desktopXl} {
    font-size: ${lg};
  }
`;

interface IProfileNavLink {
  label: string;
  to: string;
}

const ProfileNavLink = ({ label, to }: IProfileNavLink) => (
  <BaseProfileNavLink to={to} activeClassName={activeNavClassName} exact>
    {label}
  </BaseProfileNavLink>
);

export { ProfileNavLink };
