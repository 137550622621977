import styled, { css } from "styled-components";
import { ENotificationType } from "shared/types/notification";
import { colors } from "shared/constants/design";
import type { IBaseToast } from "./BaseToast";

const TypeIndicator = styled.div<IBaseToast>(
  ({ type = ENotificationType.SUCCESS }) => css`
    background-color: ${colors[type]};
    border-radius: 5px;
    height: 20px;
    margin-right: 10px;
    width: 20px;
  `
);

export { TypeIndicator };
