import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { If, Then, Else } from "react-if";
import { colors, fontSizes, fonts } from "shared/constants/design";

const { white, primary } = colors;
const { xsm } = fontSizes;
const { nexaLight } = fonts;

const Link = styled(RouterLink)`
  color: ${white};
  font-family: ${nexaLight};
  font-size: ${xsm};
  letter-spacing: 0.54px;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    color: ${primary};
  }
`;

const ExternalLink = styled.a`
  color: ${white};
  font-family: ${nexaLight};
  font-size: ${xsm};
  letter-spacing: 0.54px;
  line-height: 1;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    color: ${primary};
  }
`;

interface INavbarLink {
  to: string;
  target?: "_blank" | "_self";
  isExternal?: boolean;
}

const NavbarLink: FC<INavbarLink> = ({
  to,
  target = "_self",
  isExternal = false,
  children,
}) => (
  <li>
    <If condition={isExternal}>
      <Then>
        <ExternalLink href={to} target={target} rel="noopener noreferrer">
          {children}
        </ExternalLink>
      </Then>
      <Else>
        <Link to={to} target={target}>
          {children}
        </Link>
      </Else>
    </If>
  </li>
);

export { NavbarLink };
