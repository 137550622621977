import styled from "styled-components";
import { When } from "react-if";
import IconButton from "components/atoms/IconButton";
import assets from "shared/constants/assets";

const { edit } = assets;

const ProfileDetailsImageContainer = styled.div`
  height: 150px;
  position: relative;
  width: 150px;
`;

const ProfileImage = styled.img`
  border-radius: 360px;
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const EditionDiamondButtonContainer = styled.div`
  bottom: 0;
  position: absolute;
  right: 0;
`;

interface IProfileDetailsImage {
  src: string;
  onClick?: () => void;
  displayEditButton?: boolean;
}

const ProfileDetailsImage = ({
  src,
  onClick,
  displayEditButton = true,
}: IProfileDetailsImage) => (
  <ProfileDetailsImageContainer>
    <ProfileImage src={src} />
    <When condition={displayEditButton}>
      <EditionDiamondButtonContainer>
        <IconButton
          width="50px"
          height="40px"
          src={edit}
          alt="Edit profile"
          onClick={onClick}
        />
      </EditionDiamondButtonContainer>
    </When>
  </ProfileDetailsImageContainer>
);

export { ProfileDetailsImage };
