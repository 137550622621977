import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const ColumnNames = styled.div`
  display: none;

  @media ${desktop} {
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr 1fr;
    margin-right: 20px;
  }
`;

export { ColumnNames };
