import { ClientJS } from "clientjs";
import { GrowthBook } from "@growthbook/growthbook-react";
import mixpanelService from "services/mixpanel";
import environment from "shared/constants/environment";

const client = new ClientJS();

const GrowthBookService = () => {
  let growthBookInstance: GrowthBook | undefined = undefined;

  const getInstance = () => {
    return growthBookInstance;
  };

  const init = async () => {
    const { growthBookHost, growthBookClientKey, isDevelopment } = environment;
    if (!growthBookHost || !growthBookClientKey) {
      console.info("GrowthBook not initialized, missing config");
      return;
    }
    growthBookInstance = new GrowthBook({
      apiHost: growthBookHost,
      clientKey: growthBookClientKey,
      enableDevMode: isDevelopment,
      attributes: {
        id: client.getFingerprint(),
      },
      trackingCallback: (experiment, variant) => {
        if (mixpanelService.isEnabled) {
          mixpanelService.trackEvent("$experiment_started", {
            "Experiment name": experiment.key,
            "Variant value": variant.value,
            $source: "growthbook",
          });
        }
      },
    });
    await growthBookInstance.loadFeatures();
  };

  return {
    init,
    getInstance,
  };
};

const growthBookService = GrowthBookService();

export default growthBookService;
