import { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router";
import { useTranslation } from "react-i18next";
import useReservationContext from "context/ReservationContext";
import PaymentMethods, {
  IPaymentMethodItem,
} from "components/organisms/PaymentMethods";
import PrimaryButton from "components/atoms/PrimaryButton";
import {
  MainReservationLayout,
  ReservationStepTitle,
  ReservationStepSubtitle,
  Currency,
  TotalSectionContainer,
} from "components/atoms/reservation";
import Text from "components/atoms/Text";
import {
  TotalPrice,
  TotalPriceTitle,
} from "components/atoms/reservation/SelectAddons";
import {
  PaymentMethodsSection,
  AnnotationsSection,
  Annotations,
  CheckoutContentLayout,
} from "components/atoms/reservation/Checkout";
import useNotification from "hooks/useNotification";
import getCardBrandIcon from "utils/getCardBrandIcon";
import { ENotificationType } from "shared/types/notification";
import { IPaymentMethod } from "shared/types/payment";
import useAuthContext from "context/AuthContext";
import getFormattedPrice from "utils/getFormattedPrice";
import ImagesSliderSection from "./ImagesSliderSection";
import { PricingSection } from "components/atoms/reservation/ReservationResume";
import { Checkbox } from "components/atoms/form";
import Link from "components/atoms/Link";
import routes from "shared/constants/routes";
import { GoBackButtonContainer } from "components/atoms/reservation";
import SecondaryButton from "components/atoms/SecondaryButton";

const { policies } = routes;

const Checkout = () => {
  const { t } = useTranslation();
  const { user, updateUserData } = useAuthContext();
  const {
    onProceedToPayment,
    availablePaymentMethods,
    onSelectPaymentMethod,
    selectedPaymentMethodId,
    updateUserPaymentMethods,
    onRemovePaymentMethod,
    onSetPaymentMethodAsDefault,
    bookingTotal,
    onPreviousStep,
  } = useReservationContext();
  const [parsedPaymentMethods, setParsedPaymentMethods] = useState<
    IPaymentMethodItem[]
  >([]);
  const notify = useNotification();
  const [isCreatingReserve, setIsCreatingReserve] = useState(false);
  const policiesCheckboxRef = useRef<HTMLInputElement>(null);
  const nauticalPoliciesCheckboxRef = useRef<HTMLInputElement>(null);

  const getOnRemovePaymentMethod = (paymentId: string) => async () => {
    try {
      await onRemovePaymentMethod(paymentId);
      await updateUserPaymentMethods();
      await updateUserData();
    } catch (error: any) {
      notify({
        type: ENotificationType.ERROR,
        description: error.message,
      });
    }
  };

  const getOnSetPaymentMethodAsDefault = (paymentId: string) => async () => {
    try {
      await onSetPaymentMethodAsDefault(paymentId);
      await updateUserPaymentMethods();
      await updateUserData();
    } catch (error: any) {
      notify({
        type: ENotificationType.ERROR,
        description: error.message,
      });
    }
  };

  const getParsedPaymentMethodsList = (): IPaymentMethodItem[] =>
    availablePaymentMethods.map((paymentMethod: IPaymentMethod) => ({
      id: paymentMethod.id,
      last4: paymentMethod.card.last4,
      isSelected: selectedPaymentMethodId === paymentMethod.id,
      brandIconUrl: getCardBrandIcon(paymentMethod.card.brand),
      onClick: () => onSelectPaymentMethod(paymentMethod.id),
      onRemove: getOnRemovePaymentMethod(paymentMethod.id),
      onSetAsDefault: getOnSetPaymentMethodAsDefault(paymentMethod.id),
      isDefault: user?.profile?.payment_key === paymentMethod.id,
    }));

  useEffect(() => {
    setParsedPaymentMethods(getParsedPaymentMethodsList());
  }, [availablePaymentMethods, selectedPaymentMethodId, user]);

  const onSubmitPayment = async () => {
    const userHasAcceptedPolicies = policiesCheckboxRef.current?.checked;
    const userHasAcceptedNauticalPolicies =
      nauticalPoliciesCheckboxRef.current?.checked;
    if (!userHasAcceptedPolicies) {
      notify({
        type: ENotificationType.ERROR,
        description: t("policies.confirm.error"),
      });
      return;
    }

    if (!userHasAcceptedNauticalPolicies) {
      notify({
        type: ENotificationType.ERROR,
        description: t("nautical-policies.confirm.error"),
      });
      return;
    }

    setIsCreatingReserve(true);
    try {
      await onProceedToPayment();
      notify({
        description: t("reservation.success-message"),
      });
    } catch (error: any) {
      notify({
        type: ENotificationType.ERROR,
        description: error.message,
      });
    } finally {
      setIsCreatingReserve(false);
    }
  };

  const onSuccessNewPaymentMethod = async () => {
    await updateUserPaymentMethods();
    await updateUserData();
  };

  return (
    <>
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
          }

          return location.pathname === "/"
            ? true
            : `Si sales de esta página perderás la información que has introducido. ¿Estás seguro de que quieres salir?`;
        }}
      />
      <MainReservationLayout>
        <div>
          <GoBackButtonContainer>
            <SecondaryButton onClick={onPreviousStep}>
              {t("go-back-button-label")}
            </SecondaryButton>
          </GoBackButtonContainer>
        </div>
        <CheckoutContentLayout>
          <PaymentMethodsSection>
            <div>
              <ReservationStepTitle>
                {t("reservation.checkout.title")}
              </ReservationStepTitle>
              <Text>{t("reservation.checkout.subtitle")}</Text>
            </div>
            <PaymentMethods
              paymentMethods={parsedPaymentMethods}
              onUpdatePaymentMethods={onSuccessNewPaymentMethod}
            />
          </PaymentMethodsSection>
          <PricingSection>
            <AnnotationsSection>
              <ReservationStepSubtitle>
                {t("reservation.checkout.annotations")}
              </ReservationStepSubtitle>
              <Annotations name="annotations" />
              <Checkbox name="acceptPolicies" ref={policiesCheckboxRef}>
                {t("policies.confirm.read")}{" "}
                <Link to={policies} target="_blank" isExternal={false}>
                  {t("policies.confirm.read.link")}
                </Link>
              </Checkbox>
              <Checkbox
                name="acceptNauticalPolicies"
                ref={nauticalPoliciesCheckboxRef}
              >
                {t("policies.confirm.read")}{" "}
                <Link
                  to={t("nautical-policies.link")}
                  target="_blank"
                  isExternal
                >
                  {t("nautical-policies.confirm.read.link")}
                </Link>
              </Checkbox>
            </AnnotationsSection>
            <TotalSectionContainer>
              <div>
                <TotalPriceTitle>Total</TotalPriceTitle>
                <TotalPrice>
                  {getFormattedPrice(bookingTotal)} <Currency>usd</Currency>
                </TotalPrice>
              </div>
              <PrimaryButton
                onClick={onSubmitPayment}
                isLoading={isCreatingReserve}
              >
                {t("book-button-label")}
              </PrimaryButton>
            </TotalSectionContainer>
          </PricingSection>
        </CheckoutContentLayout>
        <ImagesSliderSection />
      </MainReservationLayout>
    </>
  );
};

export default Checkout;
