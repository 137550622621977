import styled from "styled-components";
import { isBrowserAndDesktopMedia } from "utils/css";

const YachtsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 15px;

  ${isBrowserAndDesktopMedia(`
    height: 100%;
    overflow: hidden;
    padding-left: 100px;
    padding-right: 200px;
  `)}
`;

export { YachtsListContainer };
