import {
  ProfileNavLink,
  ProfileNavigationContainer,
  ProfileNavigationList,
} from ".";
import routes from "shared/constants/routes";
import { useTranslation } from "react-i18next";

const { profile, userPaymentMethods, userReservations } = routes;

const ProfileNavigation = () => {
  const { t } = useTranslation();

  return (
    <ProfileNavigationContainer>
      <ProfileNavigationList>
        <ProfileNavLink to={profile} label={t("profile.nav.membership")} />
        <ProfileNavLink
          to={userReservations}
          label={t("profile.nav.bookings")}
        />
        <ProfileNavLink
          to={userPaymentMethods}
          label={t("profile.nav.payment-methods")}
        />
      </ProfileNavigationList>
    </ProfileNavigationContainer>
  );
};

export { ProfileNavigation };
