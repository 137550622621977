const environment = {
  apiUrl: process.env.REACT_APP_API_URL,
  googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY || "",
  stripeKey: process.env.REACT_APP_STRIPE_KEY || "",
  tenantId: process.env.REACT_APP_TENANT_ID || "",
  growthBookHost: process.env.REACT_APP_GROWTHBOOK_HOST || "",
  growthBookClientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY || "",
  isDevelopment: process.env.NODE_ENV === "development",
  mixpanelKey: process.env.REACT_APP_MIXPANEL_KEY || "",
};

export default environment;
