import { FC } from "react";
import styled, { css } from "styled-components";
import { isBrowserAndDesktopMedia } from "utils/css";
import { EShipPosition } from "shared/types/common";
import { colors, borders, devices } from "shared/constants/design";
import assets from "shared/constants/assets";

const { waves } = assets;
const { white, blackShadowDark } = colors;
const { borderRadiusLg, shipBorderRadius } = borders;
const { tabletSmall } = devices;

interface IBackgroundContainer {
  gridArea?: string;
  shouldDisplayDesktopQueries?: boolean;
}

const BackgroundContainer = styled.div<IBackgroundContainer>(
  ({ gridArea, shouldDisplayDesktopQueries = false }) => css`
    position: relative;

    ${isBrowserAndDesktopMedia(
      `
      grid-area: ${gridArea};
    `,
      shouldDisplayDesktopQueries
    )}
  `
);

const navbarAndPaddingHeight = "165px";

interface ICardContainer {
  shouldDisplayDesktopQueries?: boolean;
}

const CardContainer = styled.div<ICardContainer>(
  ({ shouldDisplayDesktopQueries = false }) => css`
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 0;

    ${isBrowserAndDesktopMedia(
      `
    height: calc(100vh - ${navbarAndPaddingHeight});
    max-height: 1000px;
  `,
      shouldDisplayDesktopQueries
    )}
  `
);

interface IShipBackground {
  position?: EShipPosition;
  shouldDisplayDesktopQueries?: boolean;
}

const ShipBackground = styled.div<IShipBackground>(
  ({ position = EShipPosition.LEFT, shouldDisplayDesktopQueries = false }) => {
    const isLeftPosition = position === EShipPosition.LEFT;
    const right = isLeftPosition ? "0" : "unset";
    const left = isLeftPosition ? "unset" : "0";
    const borderRadius = isLeftPosition
      ? `0 ${borderRadiusLg} ${shipBorderRadius} 0`
      : `${borderRadiusLg} 0 0 ${shipBorderRadius}`;

    return css`
      background-color: ${white};
      border-radius: 10px;
      box-shadow: 6px 6px 64px ${blackShadowDark};
      height: 100%;
      position: absolute;
      right: ${right};
      left: ${left};
      width: 100%;
      z-index: -2;

      ${isBrowserAndDesktopMedia(
        `
        border-radius: ${borderRadius};
        max-height: 1000px;
        width: 100vw;
      `,
        shouldDisplayDesktopQueries
      )}
    `;
  }
);

interface IWavesBackground {
  position?: EShipPosition;
}

const WavesBackground = styled.img<IWavesBackground>(
  ({ position = EShipPosition.LEFT }) => {
    const isLeftPosition = position === EShipPosition.LEFT;
    const right = isLeftPosition ? "-10%" : "unset";
    const left = isLeftPosition ? "unset" : "-10%";
    const transform = isLeftPosition ? "scale(1)" : "scale(1) scaleX(-1)";

    return css`
      bottom: 80px;
      object-fit: cover;
      object-position: center;
      position: absolute;
      transform: scale(1.7);
      z-index: -1;

      @media ${tabletSmall} {
        bottom: -15%;
        height: 80%;
        right: ${right};
        left: ${left};
        transform: ${transform};
        width: 90%;
      }
    `;
  }
);

const CardContent = styled.div`
  border-radius: 0 0 ${shipBorderRadius} 0;
  height: 100%;
  padding: 20px;
  width: 100%;
`;

interface IShipCard {
  gridArea?: string;
  position?: EShipPosition;
  shouldDisplayDesktopQueries?: boolean;
}

const ShipCard: FC<IShipCard> = ({
  children,
  position = EShipPosition.LEFT,
  gridArea,
  shouldDisplayDesktopQueries = false,
}) => (
  <BackgroundContainer
    gridArea={gridArea}
    shouldDisplayDesktopQueries={shouldDisplayDesktopQueries}
  >
    <ShipBackground
      position={position}
      shouldDisplayDesktopQueries={shouldDisplayDesktopQueries}
    />
    <CardContainer>
      <WavesBackground
        src={waves}
        position={position}
        alt=""
        width="480"
        height="204"
      />
      <CardContent>{children}</CardContent>
    </CardContainer>
  </BackgroundContainer>
);

export default ShipCard;
