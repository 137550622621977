import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { EditProfileForm } from "components/organisms/Profile";
import { devices, colors, limits, borders } from "shared/constants/design";
import "./edit-profile-modal.css";

const { desktop } = devices;
const { backgroundSecondary } = colors;
const { desktopMaxWidth } = limits;
const {borderRadiusLg, shipBorderRadius} = borders

const navbarHeight = "105px";
const verticalPadding = "60px";

const BaseDesktopEditProfileModal = styled(Modal)`
  display: none !important;

  @media ${desktop} {
    bottom: 0;
    display: block !important;
    height: calc(100vh - ${navbarHeight});
    top: unset;
    width: 100%;

    & .modal-dialog {
      display: grid;
      height: calc(100% - ${verticalPadding});
      max-width: ${desktopMaxWidth};
      padding: 20px 70px;
      position: relative;
      width: 100%;
    }

    & .modal-content {
      background: ${backgroundSecondary};
      border-radius: ${borderRadiusLg} ${borderRadiusLg} ${borderRadiusLg} ${shipBorderRadius};
      width: fit-content;
    }
  }
`;

interface IDesktopEditProfileModal {
  isOpen: boolean;
  onCancel: () => void;
}

const DesktopEditProfileModal = ({
  isOpen,
  onCancel,
}: IDesktopEditProfileModal) => (
  <BaseDesktopEditProfileModal
    show={isOpen}
    onHide={onCancel}
    backdropClassName="glass-backdrop"
    animation={false}
  >
    <EditProfileForm onCancel={onCancel} />
  </BaseDesktopEditProfileModal>
);

export { DesktopEditProfileModal };
