import http from "services/http";
import { IBlockedDate } from "shared/types/blockedDates";

const BlockedDatesService = () => {
  const getBlockedDates = () => http.get<IBlockedDate[]>("/blocked-date");

  return {
    getBlockedDates,
  };
};

export default BlockedDatesService();
