import styled from "styled-components";
import { fonts, colors, fontSizes } from "shared/constants/design";
import getFormattedPrice from "utils/getFormattedPrice";

const { white } = colors;
const { nexaRegular, nexaBold } = fonts;
const { md } = fontSizes;

const PricingItemContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  width: 100%;
`;

const PricingItemName = styled.p`
  color: ${white};
  font-family: ${nexaRegular};
  font-size: ${md};
  letter-spacing: 0.63px;
  line-height: ${md};
  margin: 0;
`;

const PricingItemPrice = styled.p`
  color: ${white};
  font-family: ${nexaBold};
  font-size: ${md};
  letter-spacing: 0.63px;
  line-height: ${md};
  margin: 0;
`;

interface IPricingItem {
  name: string;
  price: number;
}

const PricingItem = ({ name, price }: IPricingItem) => (
  <PricingItemContainer>
    <PricingItemName>{name}</PricingItemName>
    <PricingItemPrice>{getFormattedPrice(price)} usd</PricingItemPrice>
  </PricingItemContainer>
);

export { PricingItem };
