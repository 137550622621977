import moment from "moment";
import { useTranslation } from "react-i18next";
import { When } from "react-if";
import useAuthContext from "context/AuthContext";
import Link from "components/atoms/Link";
import {
  ProfileDetailsTitle,
  ProfileDetailsContainer,
  ProfileDetailsImage,
  ProfileDetailsUserName,
  ProfileDetailsMembershipTag,
  ProfileDetailsSection,
  ProfileDetailText,
  TotalSavings,
  TotalSavingsTitle,
} from "components/atoms/Profile";
import useUserMembershipTitle from "hooks/useUserMembershipTitle";
import isDefined from "utils/isDefined";
import assets from "shared/constants/assets";
import SecondaryButton from "components/atoms/SecondaryButton";
import { Currency } from "components/atoms/reservation";
import getFormattedPrice from "utils/getFormattedPrice";
import routes from "shared/constants/routes";

const { policies } = routes;
const { defaultUserImage } = assets;

interface IProfileDetails {
  onEditProfile: () => void;
}

const ProfileDetails = ({ onEditProfile }: IProfileDetails) => {
  const { t } = useTranslation();
  const { user, logOutUser } = useAuthContext();
  const userAvatar = user?.avatar || defaultUserImage;
  const userBirthDate = user?.profile?.birthdate
    ? moment(user.profile.birthdate).format("MMM D, YYYY")
    : null;
  const { userMembershipTitle } = useUserMembershipTitle();

  return (
    <When condition={isDefined(user)}>
      <ProfileDetailsContainer>
        <ProfileDetailsTitle>{t("profile.details.title")}</ProfileDetailsTitle>
        <ProfileDetailsImage src={userAvatar} onClick={onEditProfile} />
        <ProfileDetailsSection>
          <ProfileDetailsUserName>
            {user?.first_name} {user?.last_name}
          </ProfileDetailsUserName>
          <ProfileDetailsMembershipTag>
            {userMembershipTitle}
          </ProfileDetailsMembershipTag>
        </ProfileDetailsSection>
        <ProfileDetailsSection>
          <TotalSavingsTitle>{t("profile.details.savings")}</TotalSavingsTitle>
          <TotalSavings>
            {getFormattedPrice(user?.total_saving)} <Currency>usd</Currency>
          </TotalSavings>
        </ProfileDetailsSection>
        <ProfileDetailsSection>
          <ProfileDetailText>{user?.email}</ProfileDetailText>
          <ProfileDetailText>{user?.profile?.phone}</ProfileDetailText>
          <ProfileDetailText>{userBirthDate}</ProfileDetailText>
          <ProfileDetailText>{user?.profile?.address}</ProfileDetailText>
          <ProfileDetailText>{user?.country?.name}</ProfileDetailText>
        </ProfileDetailsSection>
        <Link to={policies}>{t("policies.title")}</Link>
        <SecondaryButton onClick={logOutUser}>
          {t("profile.log-out")}
        </SecondaryButton>
      </ProfileDetailsContainer>
    </When>
  );
};

export { ProfileDetails };
