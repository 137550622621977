import { useState } from "react";

const useDisclosure = (initialIsOpen?: boolean) => {
  const [isOpen, setIsOpen] = useState(Boolean(initialIsOpen));

  const onToggle = () => setIsOpen((prevState) => !prevState);

  const onClose = () => setIsOpen(false);

  const onOpen = () => setIsOpen(true);

  return { isOpen, onOpen, onClose, onToggle };
};

export default useDisclosure;
