import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation, TFunction } from "react-i18next";
import useAuthContext from "context/AuthContext";
import {
  Input,
  Form,
  FormSection,
  FormContainer,
  FormLogo,
  FormTitle,
  FormRecommendation,
} from "components/atoms/form";
import PrimaryButton from "components/atoms/PrimaryButton";
import useForm from "hooks/useForm";

interface IChangePasswordWithTokenFormData {
  password: string;
  confirmPassword: string;
}

const minPasswordLength = 8;

const getValidationSchema = (t: TFunction<"translation">) => {
  const isRequiredMessage = t("forms.errors.required");
  const isInvalidPasswordMessage = t("forms.errors.invalid-password");

  return yup.object().shape({
    password: yup
      .string()
      .min(minPasswordLength, isInvalidPasswordMessage)
      .required(isRequiredMessage),
    confirmPassword: yup
      .string()
      .min(minPasswordLength, isInvalidPasswordMessage)
      .required(isRequiredMessage),
  });
};

interface IChangePasswordWithTokenForm {
  token: string;
}

const ChangePasswordWithTokenForm = ({
  token,
}: IChangePasswordWithTokenForm) => {
  const { t } = useTranslation();
  const { changePassword } = useAuthContext();

  const onSubmit = async ({
    password,
    confirmPassword,
  }: IChangePasswordWithTokenFormData) => {
    if (password !== confirmPassword) {
      throw new Error(t("forms.errors.passwords-don't-match"));
    }
    await changePassword({ password, token });
  };

  const {
    submit,
    isSubmitting,
    register,
    formState: { errors },
  } = useForm<IChangePasswordWithTokenFormData>({
    resolver: yupResolver(getValidationSchema(t)),
    onSubmit,
    successMessage: t("forms.messages.change-password-success"),
  });

  return (
    <FormContainer>
      <FormLogo />
      <Form onSubmit={submit}>
        <FormTitle>{t("reset-password.password-form.title")}</FormTitle>
        <FormSection>
          <Input
            placeholder={t("forms.fields.new-password")}
            type="password"
            {...register("password")}
            hasError={Boolean(errors.password)}
            errorMessage={errors.password?.message}
          />
          <Input
            placeholder={t("forms.fields.confirm-new-password")}
            type="password"
            {...register("confirmPassword")}
            hasError={Boolean(errors.confirmPassword)}
            errorMessage={errors.confirmPassword?.message}
          />
        </FormSection>
        <FormRecommendation>
          {t("forms.recommendations.password")}
        </FormRecommendation>
        <PrimaryButton type="submit" isLoading={isSubmitting}>
          {t("reset-password.password-form.submit")}
        </PrimaryButton>
      </Form>
    </FormContainer>
  );
};

export default ChangePasswordWithTokenForm;
