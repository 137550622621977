import styled from "styled-components";
import {
  fonts,
  fontSizes,
  fontWeights,
  colors,
  devices,
} from "shared/constants/design";

const { white } = colors;
const { semiBold } = fontWeights;
const { xl } = fontSizes;
const { conthrax } = fonts;
const { desktop, desktopMd } = devices;

const TotalPrice = styled.h3`
  color: ${white};
  font: italic normal ${semiBold} ${xl} ${conthrax};
  line-height: 32px;
  margin: 0;
  text-transform: uppercase;

  @media ${desktop} {
    line-height: 42px;
  }

  @media ${desktopMd} {
    font-size: ${fontSizes["2xl"]};
  }
`;

export { TotalPrice };
