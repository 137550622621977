import styled from "styled-components";
import { fonts, fontSizes, colors, devices } from "shared/constants/design";

const { textLight } = colors;
const { md } = fontSizes;
const { nexaLight } = fonts;
const { desktop } = devices;

const YachtCardSubtitle = styled.h6`
  color: ${textLight};
  font-family: ${nexaLight};
  font-size: ${fontSizes["2xsm"]};
  letter-spacing: 0.45;
  line-height: 12px;
  margin: 0;
  margin-left: 10px;
  text-transform: uppercase;
  width: fit-content;

  @media ${desktop} {
    font-size: ${md};
    letter-spacing: 0.81;
    line-height: 20px;
  }
`;

export { YachtCardSubtitle };
