import styled from "styled-components";
import { devices, limits } from "shared/constants/design";

const { desktop } = devices;
const { desktopMaxWidth } = limits;

const NavbarContentContainer = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
  max-width: ${desktopMaxWidth};
  padding: 60px 30px;
  width: 100%;

  @media ${desktop} {
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
  }
`;

export { NavbarContentContainer };
