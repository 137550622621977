import { useState } from "react";
import {
  useForm as useHookForm,
  Resolver,
  UnpackNestedValue,
  FieldValues,
  DeepPartial,
} from "react-hook-form";
import useNotification from "./useNotification";
import { ENotificationType } from "shared/types/notification";

interface IUseForm<T extends FieldValues> {
  resolver?: Resolver<T, object>;
  onSubmit: (values: UnpackNestedValue<T>) => Promise<void>;
  onSuccess?: () => void;
  successMessage?: string;
  defaultValues?: UnpackNestedValue<DeepPartial<T>>;
  errorMessage?: string;
}

const useForm = <T extends FieldValues>({
  resolver,
  onSubmit,
  onSuccess = () => {},
  successMessage = "",
  defaultValues,
  errorMessage,
}: IUseForm<T>) => {
  const { handleSubmit, reset, ...restOfValues } = useHookForm<T>({
    resolver,
    defaultValues,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const notify = useNotification();

  const submit = handleSubmit(async (values) => {
    try {
      setIsSubmitting(true);
      await onSubmit(values);
      reset();
      notify({
        description: successMessage,
      });
      onSuccess();
    } catch (error: any) {
      notify({
        type: ENotificationType.ERROR,
        description: errorMessage || error.message,
      });
    } finally {
      setIsSubmitting(false);
    }
  });

  return {
    submit,
    isSubmitting,
    reset,
    ...restOfValues,
  };
};

export default useForm;
