import { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import useAuthContext from "context/AuthContext";
import tenantService from "services/tenant";
import { Else, If, Then } from "react-if";
import routes from "shared/constants/routes";

const PrivateRoute: FC<RouteProps> = ({ children, ...routeProps }) => {
  const { isLoggedIn } = useAuthContext();
  const allowLogin = tenantService.getConfigs().flags.allowLogin;
  const { login } = routes;

  return (
    <If condition={isLoggedIn}>
      <Then>
        <Route {...routeProps}>{children}</Route>
      </Then>
      <Else>{allowLogin && <Redirect to={login} />}</Else>
    </If>
  );
};

export default PrivateRoute;
