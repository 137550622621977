import styled from "styled-components";
import {
  fonts,
  fontSizes,
  fontWeights,
  colors,
  devices,
} from "shared/constants/design";

const { white } = colors;
const { semiBold } = fontWeights;
const { lg, md } = fontSizes;
const { conthrax } = fonts;
const { desktop } = devices;

const ReservationStepSubtitle = styled.h3`
  color: ${white};
  font: italic normal ${semiBold} ${md} ${conthrax};
  line-break: anywhere;
  line-height: ${md};
  margin: 0;
  text-transform: uppercase;
  overflow-wrap @media ${desktop} {
    font-size: ${lg};
    line-height: ${lg};
  }
`;

export { ReservationStepSubtitle };
