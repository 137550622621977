import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "translations";
import { EAvailableLanguages } from "shared/types/common";

i18n.use(initReactI18next).init({
  resources: translations,
  lng: EAvailableLanguages.ES,
  fallbackLng: EAvailableLanguages.ES,
  interpolation: {
    escapeValue: false,
  },
});
