import styled from "styled-components";
import { fonts, fontSizes, fontWeights, colors } from "shared/constants/design";

const { text } = colors;
const { semiBold } = fontWeights;
const { sm } = fontSizes;
const { conthrax } = fonts;

const SoonLocationItem = styled.h6`
  color: ${text};
  font: italic normal ${semiBold} ${sm} ${conthrax};
  height: fit-content;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  width: 110px;
`;

export { SoonLocationItem };
