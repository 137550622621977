import styled from "styled-components";
import {
  fonts,
  fontSizes,
  fontWeights,
  colors,
  devices,
} from "shared/constants/design";

const { text } = colors;
const { semiBold } = fontWeights;
const { xsm, xl } = fontSizes;
const { conthrax } = fonts;
const { desktop } = devices;

const YachtCardMembershipRate = styled.h5`
  color: ${text};
  font: italic normal ${semiBold} ${xsm} ${conthrax};
  line-height: 18px;
  margin: 0;
  text-transform: lowercase;
  text-align: right;

  @media ${desktop} {
    font-size: ${xl};
    line-height: 42px;
  }
`;

export { YachtCardMembershipRate };
