import styled from "styled-components";
import { fonts, fontSizes, fontWeights, colors } from "shared/constants/design";

const { primary } = colors;
const { semiBold } = fontWeights;
const { lg } = fontSizes;
const { conthrax } = fonts;

const ReservationStepTitle = styled.h3`
  color: ${primary};
  font: italic normal ${semiBold} ${lg} ${conthrax};
  line-height: 42px;
  margin: 0;
  text-transform: uppercase;
`;

export { ReservationStepTitle };
