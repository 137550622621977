import i18n from "i18next";
import momentTz from "moment-timezone";
import environment from "shared/constants/environment";

const { apiUrl } = environment;

const getReservePdfUrl = (reserveId?: number | string) =>
  `${apiUrl}/reserve/${reserveId}/pdf?timezone=${momentTz.tz.guess()}&locale=${
    i18n.language
  }`;

export default getReservePdfUrl;
