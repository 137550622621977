import {
  forwardRef,
  ChangeEventHandler,
  FocusEventHandler,
  ReactNode,
} from "react";
import styled from "styled-components";
import { When } from "react-if";
import { ControlGroup } from "./ControlGroup";
import { ErrorMessage } from "./ErrorMessage";
import { colors, fontSizes, fonts, borders } from "shared/constants/design";

const { text, borderColor, blackShadowLight } = colors;
const { sm } = fontSizes;
const { nexaBold } = fonts;
const { borderRadius } = borders;

const BaseSelect = styled.select`
  border-radius: ${borderRadius};
  border: 1px solid ${borderColor};
  box-shadow: 0px 3px 6px ${blackShadowLight};
  color: ${text};
  font-family: ${nexaBold};
  font-size: ${sm};
  letter-spacing: 0.63px;
  line-height: 17px;
  padding: 6px;
  text-align-last: center;

  &:invalid {
    color: var(--bs-gray);
  }

  &:disabled {
    color: var(--bs-gray);
  }
`;

interface ISelect {
  name: string;
  placeholder?: string;
  hasError?: boolean;
  errorMessage?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  onBlur?: FocusEventHandler<HTMLSelectElement>;
  defaultValue?: string | number;
  disabled?: boolean;
  value?: string | number;
  children?: ReactNode;
}

const Select = forwardRef<HTMLSelectElement, ISelect>(
  (
    {
      hasError = false,
      errorMessage = "",
      children,
      placeholder,
      defaultValue = "",
      ...selectProps
    },
    ref
  ) => (
    <ControlGroup>
      <BaseSelect
        {...selectProps}
        ref={ref}
        className="form-select"
        defaultValue={defaultValue}
        required
      >
        <option disabled value="">
          {placeholder}
        </option>
        {children}
      </BaseSelect>
      <When condition={hasError && errorMessage}>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </When>
    </ControlGroup>
  )
);

export type { ISelect };
export { Select };
