import { useState } from "react";
import reservationService from "services/reservation";
import useAuthContext from "context/AuthContext";
import useFetch from "./useFetch";
import { IReservation } from "shared/types/reservation";
import isDefined from "utils/isDefined";

interface IGetUserReservations {
  userId?: number;
  past?: boolean;
}

const getUserReservations = async ({
  userId,
  past,
}: IGetUserReservations): Promise<IReservation[]> => {
  if (userId) {
    return await reservationService.getReservationsByUserId(userId, past);
  }
  return [];
};

const useUserReservations = () => {
  const { user } = useAuthContext();
  const [pastFilter, setPastFilter] = useState(false);
  const {
    data: userReservations,
    isFetching: isFetchingUserReservations,
    hasError: hasErrorLoadingUserReservations,
  } = useFetch<IReservation[]>({
    initialData: [],
    fetcher: () => getUserReservations({ userId: user?.id, past: pastFilter }),
    shouldFetch: isDefined(user),
    dependencies: [user, pastFilter],
  });

  return {
    userReservations,
    isFetchingUserReservations,
    hasErrorLoadingUserReservations,
    setPastFilter,
  };
};

export default useUserReservations;
