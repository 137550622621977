import styled from "styled-components";
import { colors, fontSizes, fonts } from "shared/constants/design";

const { text } = colors;
const { xsm } = fontSizes;
const { nexaRegular } = fonts;

const YachtReserveCardModelName = styled.h6`
  color: ${text};
  font: normal normal ${xsm} ${nexaRegular};
  margin-left: 15px;
  text-decoration: none;
  text-transform: uppercase;
`;

export { YachtReserveCardModelName };
