enum ECardBrandNames {
  VISA = "visa",
  MASTERCARD = "mastercard",
  AMERICAN_EXPRESS = "amex",
  UNION_PAY = "unionpay",
  JCB = "jcb",
  DINERS = "diners",
  DISCOVER = "discover",
}

export { ECardBrandNames };
