import { useLocation } from "react-router-dom";

const usePageUrl = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return searchParams;
};

export default usePageUrl;
