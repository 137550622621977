import yachtService from "services/yacht";
import useFetch from "hooks/useFetch";
import { IYacht, IYachtSize } from "shared/types/yacht";
import { useEffect, useState } from "react";
import useYachtSizes from "./useYachtSizes";
import useReservationContext from "context/ReservationContext";

const fromMinToMax = (a: number, b: number) => a - b;

const toYachtsMinHours = (options: number[], yacht: IYacht) => {
  const yachtMinHours = yacht.min_hours;
  const isRepeatedValues = options.includes(yachtMinHours || -1);
  if (yachtMinHours && !isRepeatedValues) {
    return [...options, yachtMinHours];
  }
  return options;
};

const getMinHours = (yachts: IYacht[]) =>
  yachts.reduce(toYachtsMinHours, [] as number[]).sort(fromMinToMax);

const getActiveYachts = async () =>
  await yachtService.getYachts({ active: true });

const getMaxNumberOfPassengers = (yachts: IYacht[]) =>
  yachts.reduce((maxNumber, yacht) => {
    const passengersNumber = yacht.passengers_number;
    if (maxNumber < passengersNumber) {
      return passengersNumber;
    }
    return maxNumber;
  }, 0);

const useYachtsFiltersOptions = () => {
  const { selectedLocationId } = useReservationContext();
  const { yachtSizes } = useYachtSizes();
  const { data: activeYachts } = useFetch<IYacht[]>({
    initialData: [],
    fetcher: getActiveYachts,
  });
  const [minHoursOptions, setMinHoursOptions] = useState<number[]>([]);
  const [maxNumberOfPassengers, setMaxNumberOfPassengers] = useState(0);
  const [yachtSizesOptions, setYachtSizesOption] = useState<IYachtSize[]>([]);

  useEffect(() => {
    setMinHoursOptions(getMinHours(activeYachts));
    setMaxNumberOfPassengers(getMaxNumberOfPassengers(activeYachts));
  }, [activeYachts]);

  useEffect(() => {
    if (selectedLocationId) {
      const activeYachtsInSelectedLocation = activeYachts.filter(
        ({ location }) => location.id === selectedLocationId
      );
      const yachtsSizesOption = yachtSizes.filter(({ id }) =>
        activeYachtsInSelectedLocation.some(({ size }) => size?.id === id)
      );
      setYachtSizesOption(yachtsSizesOption);
    }
  }, [selectedLocationId, activeYachts, yachtSizes]);

  return {
    minHoursOptions,
    maxNumberOfPassengers,
    yachtSizes: yachtSizesOptions,
  };
};

export default useYachtsFiltersOptions;
