import http from "services/http";
import type {
  ICreatePaymentMethod,
  IPaymentMethod,
  IUpdatePaymentMethod,
} from "shared/types/payment";
import mixpanelService from "services/mixpanel";

const PaymentService = () => {
  const endpointName = "/card";

  const getUserPaymentMethods = () => http.get<IPaymentMethod[]>(endpointName);

  const createUserPaymentMethod = (body: ICreatePaymentMethod) => {
    http.post(endpointName, body);
    mixpanelService.track.newPaymentMethodAdded();
  };

  const deleteUserPaymentMethod = (body: IUpdatePaymentMethod) => {
    http.remove(endpointName, { data: body }).then(() => {
      mixpanelService.track.paymentMethodDeleted();
    });
  };

  const setUserPaymentMethodAsDefault = (body: IUpdatePaymentMethod) => {
    http.put(`${endpointName}/default`, body).then(() => {
      mixpanelService.track.favoritePaymentMethodSelected();
    });
  };

  return {
    getUserPaymentMethods,
    createUserPaymentMethod,
    deleteUserPaymentMethod,
    setUserPaymentMethodAsDefault,
  };
};

export default PaymentService();
