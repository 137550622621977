import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const YachtsListItems = styled.div`
  display: grid;
  grid-gap: 20px;
  padding-top: 5px;

  @media ${desktop} {
    padding-top: 15px;
    overflow-y: auto;
  }
`;

export { YachtsListItems };
