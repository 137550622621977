import useAuthContext from "context/AuthContext";
import { IBlockedDate } from "shared/types/blockedDates";
import { EPartnerStatus } from "shared/types/user";
import { IYacht } from "shared/types/yacht";

const useBlockedDatesValidation = (selectedYacht?: IYacht) => {
  const { user } = useAuthContext();

  const isDayOff = ({ whole_fleet }: IBlockedDate) => whole_fleet;

  const isBlockedCurrentLocation = ({ locations, yachts }: IBlockedDate) => {
    const isBlockedByLocation = Boolean(locations?.length && !yachts?.length);
    const matchesSelectedYachtLocation = locations?.some(
      ({ id }) => selectedYacht?.location_id === id
    );
    return isBlockedByLocation && matchesSelectedYachtLocation;
  };

  const isBlockedUserMembership = ({
    memberships,
    membership_free,
    locations,
    yachts,
  }: IBlockedDate) => {
    const isBlockingRegularMembership = membership_free;
    const isBlockingPaidMembership = Boolean(memberships?.length);
    const isBlockingFreeOrPaidMembership =
      isBlockingRegularMembership || isBlockingPaidMembership;
    const isBlockedByMembership =
      isBlockingFreeOrPaidMembership && !locations?.length && !yachts?.length;
    const userHasPaidMembership =
      user?.partner_status === EPartnerStatus.PROCESSABLE;
    const userHasRegularMembership =
      user?.partner_status === EPartnerStatus.LEAD && !user?.membership_id;
    const isBlockingUserRegularMembership =
      isBlockingRegularMembership &&
      isBlockedByMembership &&
      userHasRegularMembership;
    const isBlockingUserPaidMembership =
      isBlockingPaidMembership &&
      isBlockedByMembership &&
      userHasPaidMembership &&
      memberships?.some(({ id }) => user?.membership_id);
    return isBlockingUserRegularMembership || isBlockingUserPaidMembership;
  };

  const isBlockedSelectedYacht = ({
    membership_free,
    memberships,
    locations,
    yachts,
  }: IBlockedDate) => {
    const isBlockedByYacht = Boolean(locations?.length && yachts?.length);
    const isBlockingRegularMembership = membership_free;
    const isBlockingPaidMembership = Boolean(memberships?.length);
    const isBlockingFreeOrPaidMembership =
      isBlockingRegularMembership || isBlockingPaidMembership;
    const matchesSelectedYachtLocation = locations?.some(
      ({ id }) => selectedYacht?.location_id === id
    );
    const matchesSelectedYacht = yachts?.some(
      ({ id }) => selectedYacht?.id === id
    );
    if (isBlockingFreeOrPaidMembership && isBlockedByYacht) {
      const userHasPaidMembership =
        user?.partner_status === EPartnerStatus.PROCESSABLE;
      const userHasRegularMembership =
        user?.partner_status === EPartnerStatus.LEAD && !user?.membership_id;
      const isBlockingUserRegularMembership =
        isBlockingRegularMembership && userHasRegularMembership;
      const isBlockingUserPaidMembership =
        isBlockingPaidMembership &&
        userHasPaidMembership &&
        memberships?.some(({ id }) => user?.membership_id);
      const matchesUserMembership =
        isBlockingUserRegularMembership || isBlockingUserPaidMembership;
      return (
        matchesUserMembership &&
        matchesSelectedYacht &&
        matchesSelectedYachtLocation
      );
    }
    return (
      isBlockedByYacht && matchesSelectedYacht && matchesSelectedYachtLocation
    );
  };

  return {
    isBlockedCurrentLocation,
    isBlockedSelectedYacht,
    isBlockedUserMembership,
    isDayOff,
  };
};

export default useBlockedDatesValidation;
