import styled from "styled-components";
import { devices, fonts, colors, fontSizes } from "shared/constants/design";

const { desktop } = devices;
const { text } = colors;
const { nexaRegular, nexaBold } = fonts;
const { sm, md } = fontSizes;

const ItemContainer = styled.div`
  align-items: baseline;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 15px 1fr;
  width: fit-content;
  margin-bottom: 5px;

  @media ${desktop} {
    grid-template-columns: 25px 1fr;
  }
`;

const Label = styled.p`
  color: ${text};
  font-family: ${nexaRegular};
  font-size: ${sm};
  letter-spacing: 0.63px;
  line-height: 14px;
  margin: 0;
  opacity: 0.7;
`;

const ItemName = styled.p`
  color: ${text};
  font-family: ${nexaBold};
  font-size: ${md};
  letter-spacing: 0.63px;
  line-height: ${md};
  margin: 0;
`;

const Icon = styled.img`
  width: 12px;
  height: 12px;

  @media ${desktop} {
    width: 21px;
    height: 21px;
  }
`;

interface IReservationDetailItem {
  label: string;
  name: string;
  iconSrc: string;
}

const ReservationDetailItem = ({
  label,
  name,
  iconSrc,
}: IReservationDetailItem) => (
  <div>
    <ItemContainer>
      <div></div>
      <Label>{label}</Label>
    </ItemContainer>
    <ItemContainer>
      <Icon src={iconSrc} alt="" />
      <ItemName>{name}</ItemName>
    </ItemContainer>
  </div>
);

export { ReservationDetailItem };
