import styled, { css } from "styled-components";

interface IControlGroup {
  width?: string;
}

const ControlGroup = styled.div<IControlGroup>(
  ({ width = "100%" }) => css`
    display: grid;
    grid-gap: 10px;
    width: ${width};
  `
);

export { ControlGroup };
