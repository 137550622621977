import styled from "styled-components";
import { fonts, fontSizes, fontWeights, colors } from "shared/constants/design";

const { primary } = colors;
const { semiBold } = fontWeights;
const { lg } = fontSizes;
const { conthrax } = fonts;

const FormTitle = styled.h1`
  color: ${primary};
  font: italic normal ${semiBold} ${lg} ${conthrax};
  margin: 0;
  text-align: center;
  text-transform: uppercase;
`;

export { FormTitle };
