import mixpanel from "mixpanel-browser";

interface ITrackUserLoginPayload {
  email: string;
  country: string;
  fullName: string;
  membership: string;
  isAutoLoginWithToken: boolean;
}

interface ITrackUserRegisterPayload {
  email: string;
  fullName: string;
  country: number;
  hearAboutUs: string;
}

interface ITrackUserMembershipActivatedPayload {
  fullName: string;
  email: string;
  country: string;
}

interface IInitAuthFlowEvents {
  mixpanelIsEnabled: boolean;
}

const AuthFlowEvents = ({ mixpanelIsEnabled }: IInitAuthFlowEvents) => {
  const userLoggedIn = ({
    email,
    country,
    membership,
    fullName,
    isAutoLoginWithToken,
  }: ITrackUserLoginPayload) => {
    if (!mixpanelIsEnabled) return;
    mixpanel.identify(email);
    mixpanel.people.set({
      $email: email,
      $name: fullName,
      Country: country,
      Membership: membership,
    });
    mixpanel.track("User Logged In", {
      Email: email,
      Country: country,
      Membership: membership,
      Name: fullName,
      "Auto Login With Token": isAutoLoginWithToken,
    });
  };

  const userRegistered = ({
    fullName,
    email,
    country,
    hearAboutUs,
  }: ITrackUserRegisterPayload) => {
    if (!mixpanelIsEnabled) return;
    mixpanel.track("User Registered", {
      Name: fullName,
      Email: email,
      Country: country,
      "Hear About Us": hearAboutUs,
    });
  };

  const userMembershipActivated = ({
    fullName,
    email,
    country,
  }: ITrackUserMembershipActivatedPayload) => {
    if (!mixpanelIsEnabled) return;
    mixpanel.identify(email);
    mixpanel.track("User Membership Activated", {
      Name: fullName,
      Email: email,
      Country: country,
    });
  };

  const passwordRecoveryRequested = (email: string) => {
    if (!mixpanelIsEnabled) return;
    mixpanel.track("Password Recovery Requested", { Email: email });
  };

  const passwordRecovered = () => {
    if (!mixpanelIsEnabled) return;
    mixpanel.track("Password Recovered");
  };

  const userLoggedOut = () => {
    if (!mixpanelIsEnabled) return;
    mixpanel.track("User Logged Out");
  };

  return {
    userLoggedIn,
    userRegistered,
    userMembershipActivated,
    passwordRecoveryRequested,
    passwordRecovered,
    userLoggedOut,
  };
};

export default AuthFlowEvents;
