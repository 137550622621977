import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const DisplayInDesktop = styled.div`
  display: none;

  @media ${desktop} {
    display: block;
  }
`;

export { DisplayInDesktop };
