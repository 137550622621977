import http from "services/http";
import type { ILocation } from "shared/types/location";

const LocationService = () => {
  const getLocations = () => http.get<ILocation[]>("/location");

  return {
    getLocations,
  };
};

export default LocationService();
