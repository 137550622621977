import styled from "styled-components";
import { colors, fonts, fontSizes, devices } from "shared/constants/design";

const { text } = colors;
const { nexaRegular } = fonts;
const { sm, md } = fontSizes;
const { desktop } = devices;

const AmenityName = styled.p`
  color: ${text};
  font-family: ${nexaRegular};
  font-size: ${sm};
  letter-spacing: 0.63px;
  line-height: 14px;
  margin: 0;

  @media ${desktop} {
    font-size: ${md};
    line-height: 18px;
  }
`;

export { AmenityName };
