import { useTranslation } from "react-i18next";
import { If, Then, Else } from "react-if";
import {
  YachtCardContainer,
  YachtCardMobileContent,
  YachtCardImage,
  YachtCardMembershipRate,
  YachtCardRegularRate,
  YachtCardTitle,
  YachtCardSubtitle,
  YachtCardDetail,
  YachtCardDesktopContent,
  YachtCardDesktopDetails,
  YachtCardDesktopData,
  YachtCardDetailIcon,
} from "components/atoms/reservation/SelectYacht";
import { Currency } from "components/atoms/reservation";
import assets from "shared/constants/assets";

const { users, locationArrow, ruler } = assets;

interface IYachtCard {
  imageUrl?: string;
  yachtName: string;
  yachtModelName?: string;
  size?: number | string;
  passengersCount: number;
  locationName?: string;
  regularRate: number;
  membershipRate: number;
  measurementUnit?: string;
  onClick?: () => void;
}

const YachtCard = ({
  imageUrl = "",
  yachtName,
  yachtModelName = "",
  size = "",
  measurementUnit = "",
  passengersCount,
  locationName,
  membershipRate,
  regularRate,
  onClick = () => {},
}: IYachtCard) => {
  const { t } = useTranslation();

  const numberOfPassengersDetail = (
    <YachtCardDetail>
      <YachtCardDetailIcon
        src={users}
        alt={t("yachts.card.details.users-icon.alt")}
      />
      <span>
        {passengersCount} {t("yachts.details.passengers")}
      </span>
    </YachtCardDetail>
  );

  const yachtSizeDetail = (
    <YachtCardDetail>
      <YachtCardDetailIcon
        src={ruler}
        alt={t("yachts.card.details.size-icon.alt")}
      />
      <span>
        <If condition={size}>
          <Then>
            {size} {measurementUnit}
          </Then>
          <Else>{t("yachts.card.details.not-available")}</Else>
        </If>
      </span>
    </YachtCardDetail>
  );

  const yachtTitleAndModelName = (
    <div>
      <YachtCardTitle>{yachtName}</YachtCardTitle>
      <YachtCardSubtitle>{yachtModelName}</YachtCardSubtitle>
    </div>
  );

  const yachtRates = (
    <>
      <YachtCardRegularRate>
        ${regularRate} <Currency>usd</Currency>
      </YachtCardRegularRate>
      <YachtCardMembershipRate>
        ${membershipRate} <Currency>usd</Currency>
      </YachtCardMembershipRate>
    </>
  );

  return (
    <YachtCardContainer onClick={onClick}>
      <YachtCardImage src={imageUrl} alt={yachtName} />
      <YachtCardMobileContent>
        {yachtTitleAndModelName}
        <div>
          {numberOfPassengersDetail}
          {yachtSizeDetail}
        </div>
        <div>{yachtRates}</div>
      </YachtCardMobileContent>
      <YachtCardDesktopContent>
        <YachtCardDesktopDetails>
          {yachtSizeDetail}
          {numberOfPassengersDetail}
          <YachtCardDetail>
            <YachtCardDetailIcon
              src={locationArrow}
              alt={t("yachts.card.details.location-icon.alt")}
            />
            <span>
              {locationName || t("yachts.card.details.not-available")}
            </span>
          </YachtCardDetail>
        </YachtCardDesktopDetails>
        <YachtCardDesktopData>
          {yachtTitleAndModelName}
          {yachtRates}
        </YachtCardDesktopData>
      </YachtCardDesktopContent>
    </YachtCardContainer>
  );
};

export { YachtCard };
