import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

const LoaderSectionContainer = styled.div`
  display: grid;
  height: fit-content;
  place-items: center;
`;

const LoaderSection = () => (
  <LoaderSectionContainer>
    <Spinner animation="border" />
  </LoaderSectionContainer>
);

export { LoaderSectionContainer };
export default LoaderSection;
