import styled from "styled-components";

const Form = styled.form`
  display: grid;
  grid-gap: 25px;
  place-items: center;
  width: 100%;
`;

export { Form };
