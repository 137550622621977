import {
  BaseToast,
  IBaseToast,
  ToastContainer,
  ToastHeader,
  ToastBody,
  TitleContainer,
  TypeIndicator,
} from "components/atoms/Toast";

interface IToast extends IBaseToast {
  title: string;
  description: string;
  show: boolean;
  onClose: () => void;
  autoHide?: boolean;
}

const fiveSeconds = 5000;

const Toast = ({
  title,
  description,
  show,
  autoHide = true,
  type,
  ...baseToastProps
}: IToast) => (
  <ToastContainer show={show}>
    <BaseToast
      show={show}
      type={type}
      delay={fiveSeconds}
      autohide={autoHide}
      {...baseToastProps}
    >
      <ToastHeader>
        <TitleContainer>
          <TypeIndicator type={type} />
          <span>{title}</span>
        </TitleContainer>
      </ToastHeader>
      <ToastBody>{description}</ToastBody>
    </BaseToast>
  </ToastContainer>
);

export type { IToast };
export default Toast;
