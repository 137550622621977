import styled from "styled-components";
import { colors, fonts, fontSizes } from "shared/constants/design";
import { Toast } from "react-bootstrap";

const { nexaBold } = fonts;
const { sm } = fontSizes;
const { text } = colors;

const ToastHeader = styled(Toast.Header)`
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background: rgba(255, 255, 255, 0.25);
  border-radius: 5px 5px 0 0;
  color: ${text};
  display: flex;
  font-family: ${nexaBold};
  font-size: ${sm};
  justify-content: space-between;
  padding: 10px;
  width: 100%;
`;

export { ToastHeader };
