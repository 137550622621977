import CenteredCard from "components/atoms/CenteredCard";
import PrimaryButton from "components/atoms/PrimaryButton";
import { RegisterFormTitle } from "components/atoms/Register";
import Text from "components/atoms/Text";
import { useTranslation } from "react-i18next";
import tenantService from "services/tenant";
import PageTemplate from "components/templates/PageTemplate";
import styled from "styled-components";

const TextContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 12px;
`;

const TextBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const LoginError = () => {
  const { t } = useTranslation();

  const submit = () => {
    window.location.href = tenantService.getConfigs().links.tenantLink;
  };

  return (
    <PageTemplate renderStaticNavbar>
      <CenteredCard maxWidth="400px">
        <RegisterFormTitle>{t("login.invalid.title")}</RegisterFormTitle>
        <TextBtnContainer>
          <TextContainer>
            <Text>{t("login.invalid.description")}</Text>
          </TextContainer>
          <PrimaryButton type="submit" onClick={submit}>
            {t("login.invalid.button.label")}
          </PrimaryButton>
        </TextBtnContainer>
      </CenteredCard>
    </PageTemplate>
  );
};

export default LoginError;
