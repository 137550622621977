import styled from "styled-components";

const ProfileMainContent = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 25px;
  grid-template-rows: auto 1fr;
`;

export { ProfileMainContent };
