import styled from "styled-components";

const FormFooter = styled.div`
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  gap: 1ch;
  justify-content: center;
`;

export { FormFooter };
