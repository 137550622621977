import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const PrimaryActionsContainer = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media ${desktop} {
    flex-direction: row-reverse;
  }
`;

export { PrimaryActionsContainer };
