import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useReservationContext from "context/ReservationContext";
import {
  GoBackButtonContainer,
  SliderContainer,
} from "components/atoms/reservation";
import SecondaryButton from "components/atoms/SecondaryButton";
import { YachtImagesSlider } from "components/molecules/reservation/SelectYacht";

const ImagesSliderSection = () => {
  const { t } = useTranslation();
  const { selectedYacht, onPreviousStep, selectedYachtAddonsImages } =
    useReservationContext();
  const yachtImages = selectedYacht?.images || [];
  const carouselImages = [...yachtImages, ...selectedYachtAddonsImages];
  const sliderContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sliderContainerRef.current) {
      const sliderContainer = sliderContainerRef.current;
      const containerHeight = sliderContainer.offsetHeight;
      const scrollBarHeightOffset = 80;
      sliderContainer.scrollTo(0, containerHeight / 2 + scrollBarHeightOffset);
    }
  }, [sliderContainerRef.current]);

  return (
    <>
      <SliderContainer ref={sliderContainerRef}>
        <YachtImagesSlider images={carouselImages} />
      </SliderContainer>
    </>
  );
};

export default ImagesSliderSection;
