import styled from "styled-components";
import Button, { ButtonProps } from "react-bootstrap/Button";

const BaseButton = styled(Button)`
  background-color: transparent;
  border-radius: 5px;
  border: none;
  height: fit-content;
  padding: 0;
  width: fit-content;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
  }
`;

interface IIconButton extends ButtonProps {
  src: string;
  alt: string;
  width: string;
  height: string;
}

const IconButton = ({
  src,
  alt,
  width,
  height,
  ...buttonProps
}: IIconButton) => (
  <BaseButton {...buttonProps}>
    <img src={src} alt={alt} width={width} height={height} />
  </BaseButton>
);

export default IconButton;
