import styled from "styled-components";
import {
  isBrowserAndDesktopMedia,
  isBrowserAndDesktopMdMedia,
} from "utils/css";

const ReservationResumeContent = styled.div`
  display: grid;
  grid-gap: 40px;
  overflow: hidden;
  margin-top: 50px;

  ${isBrowserAndDesktopMedia(`
    grid-template-columns: 1fr 1fr 50%;
  `)}

  ${isBrowserAndDesktopMdMedia(`
    grid-gap: 60px;
  `)}
`;

export { ReservationResumeContent };
