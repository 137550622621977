import styled from "styled-components";

const FormSection = styled.div`
  display: grid;
  grid-gap: 20px;
  place-items: center;
  width: 100%;
`;

export { FormSection };
