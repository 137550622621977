import qs from "qs";
import http from "services/http";
import { IYacht, IGetYachtsFilters, IYachtAddon } from "shared/types/yacht";

const YachtService = () => {
  const getYachts = async (filters?: IGetYachtsFilters) => {
    const params = filters ? qs.stringify(filters) : "";
    return await http.get<IYacht[]>(`/yacht?${params}`);
  };

  const getYachtAddons = async (yachtId: number) =>
    await http.get<IYachtAddon[]>(`/yacht/${yachtId}/addons`);

  return {
    getYachts,
    getYachtAddons,
  };
};

export default YachtService();
