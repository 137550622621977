import IconButton from "components/atoms/IconButton";

interface ILanguageButton {
  src: string;
  disabledSrc: string;
  alt: string;
  onClick?: () => void;
  disabled?: boolean;
}

const LanguageButton = ({
  src,
  disabledSrc,
  alt,
  onClick,
  disabled = false,
}: ILanguageButton) => {
  const iconSrc = disabled ? disabledSrc : src;

  return (
    <IconButton
      src={iconSrc}
      onClick={onClick}
      alt={alt}
      width="34"
      height="34"
    />
  );
};

export { LanguageButton };
