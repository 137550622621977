import * as rdd from "react-device-detect";
import { devices } from "shared/constants/design";

// @ts-ignore this line is for testing, comment it before shipping
// rdd.isBrowser = false;

const { desktop } = devices;

const isBrowserAndDesktopMedia = (css: string, condition: boolean = false) =>
  rdd.isBrowser || condition
    ? `@media ${desktop} {
        ${css}
      }`
    : "";

export { isBrowserAndDesktopMedia };
