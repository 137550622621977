import styled from "styled-components";
import {
  fonts,
  fontSizes,
  fontWeights,
  colors,
  devices,
} from "shared/constants/design";

const { primary } = colors;
const { semiBold } = fontWeights;
const { xl } = fontSizes;
const { conthrax } = fonts;
const { desktop, desktopXl } = devices;

const YachtName = styled.h3`
  color: ${primary};
  font: italic normal ${semiBold} ${xl} ${conthrax};
  line-height: 32px;
  margin: 0;
  text-transform: uppercase;

  @media ${desktop} {
    font-size: ${fontSizes["2xl"]};
    line-height: 42px;
  }

  @media ${desktopXl} {
    font-size: ${fontSizes["4xl"]};
    line-height: 60px;
  }
`;

export { YachtName };
