import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Prompt } from "react-router";
import { useTranslation } from "react-i18next";
import useReservationContext from "context/ReservationContext";
import ImagesSliderSection from "./ImagesSliderSection";
import { AddonsSelector } from "components/molecules/reservation/SelectAddons";
import PrimaryButton from "components/atoms/PrimaryButton";
import {
  YachtName,
  Details,
  YachtModelName,
  Amenities,
  AmenitiesTitle,
  AmenityIcon,
  AmenityItem,
  AmenityName,
  Addons,
  ReservationConfigContainer,
  AddonsTitle,
  TotalPrice,
  TotalPriceTitle,
  SelectAddonsContentLayout,
} from "components/atoms/reservation/SelectAddons";
import {
  MainReservationLayout,
  Currency,
  TotalSectionContainer,
} from "components/atoms/reservation";
import Text from "components/atoms/Text";
import assets from "shared/constants/assets";
import { IYachtAddon } from "shared/types/yacht/IYachtAditional";
import useAuthContext from "context/AuthContext";
import getFormattedPrice from "utils/getFormattedPrice";
import DateSelector from "components/molecules/DateSelector";
import HourSelector from "components/molecules/HourSelector";
import PassengersNumberSelector from "components/molecules/PassengersNumberSelector";
import useTranslatedProperty from "hooks/useTranslatedProperty";
import { GoBackButtonContainer } from "components/atoms/reservation";
import SecondaryButton from "components/atoms/SecondaryButton";
import { useHistory } from "react-router-dom";

const { users, bed, restroom, serviceDiamond } = assets;

const SelectAddons = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const {
    selectedYacht,
    setReservationConfig,
    startHour,
    onStartHourChange,
    endHour,
    onEndHourChange,
    date,
    onDateChange,
    availableStartHours,
    availableEndHours,
    blockedDates,
    onAddAddon,
    onRemoveAddon,
    setNumberOfPassengers,
    numberOfPassengers,
    bookingTotal,
    selectedAddons,
    onPreviousStep,
  } = useReservationContext();
  const getTranslation = useTranslatedProperty();
  const startHourSelectRef = useRef<HTMLSelectElement>(null);
  const endHourSelectRef = useRef<HTMLSelectElement>(null);
  const passengersSelectRef = useRef<HTMLSelectElement>(null);
  const history = useHistory();
  const [locationKeys, setLocationKeys] = useState<(string | undefined)[]>([]);

  useEffect(() => {
    if (startHourSelectRef.current) {
      startHourSelectRef.current.value = startHour?.toISOString() || "";
    }
  }, [startHour]);

  useEffect(() => {
    if (endHourSelectRef.current) {
      endHourSelectRef.current.value = endHour?.toISOString() || "";
    }
  }, [endHour]);

  useEffect(() => {
    if (passengersSelectRef.current) {
      passengersSelectRef.current.value = numberOfPassengers?.toString() || "";
    }
  }, [numberOfPassengers]);

  const onStartHourSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onStartHourChange(event.target.value);
  };

  const onEndHourSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onEndHourChange(event.target.value);
  };

  const onAddOnCheckboxChange =
    (addon: IYachtAddon) => (event: ChangeEvent<HTMLInputElement>) => {
      const isSelected = event.target.checked;
      if (isSelected) {
        onAddAddon(addon);
      } else {
        onRemoveAddon(addon);
      }
    };

  const onPassengersNumberChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setNumberOfPassengers(Number(event.target.value));
  };

  return (
    <>
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
          }

          return location.pathname === "/"
            ? true
            : `Si sales de esta página perderás la información que has introducido. ¿Estás seguro de que quieres salir?`;
        }}
      />
      <MainReservationLayout>
        <div>
          <GoBackButtonContainer>
            <SecondaryButton onClick={onPreviousStep}>
              {t("go-back-button-label")}
            </SecondaryButton>
          </GoBackButtonContainer>
        </div>
        <SelectAddonsContentLayout>
          <Details>
            <div>
              <YachtName>{selectedYacht?.name}</YachtName>
              <YachtModelName>{selectedYacht?.model}</YachtModelName>
            </div>
            <Text>
              <div
                dangerouslySetInnerHTML={{
                  __html: getTranslation("description", selectedYacht, true),
                }}
              />
            </Text>
          </Details>
          <Amenities>
            <AmenitiesTitle>{t("amenities.title")}</AmenitiesTitle>
            <AmenityItem>
              <AmenityIcon src={users} alt="" />
              <AmenityName>
                {selectedYacht?.passengers_number}{" "}
                {t("yachts.details.passengers")}
              </AmenityName>
            </AmenityItem>
            <AmenityItem>
              <AmenityIcon src={bed} alt="" />
              <AmenityName>
                {selectedYacht?.bedrooms_number} {t("yachts.details.cabins")}
              </AmenityName>
            </AmenityItem>
            <AmenityItem>
              <AmenityIcon src={bed} alt="" />
              <AmenityName>
                {selectedYacht?.beds_number} {t("yachts.details.beds")}
              </AmenityName>
            </AmenityItem>
            <AmenityItem>
              <AmenityIcon src={restroom} alt="" />
              <AmenityName>
                {selectedYacht?.bathrooms_number}{" "}
                {t("yachts.details.bathrooms")}
              </AmenityName>
            </AmenityItem>
            {selectedYacht?.services?.map(({ service, quantity }: any) => (
              <AmenityItem key={service.id}>
                <AmenityIcon src={serviceDiamond} alt={service.name_en} />
                <AmenityName>
                  {quantity} {getTranslation("name", service)}
                </AmenityName>
              </AmenityItem>
            ))}
          </Amenities>
          <Addons>
            <ReservationConfigContainer>
              <DateSelector
                onDateChange={(date: Date) => onDateChange(date || new Date())}
                selectedDate={date}
                blockedDates={blockedDates}
              />
              <HourSelector
                name="startHour"
                placeholder={t("reservation.config.departure-hour")}
                disabled={!availableStartHours.length}
                onChange={onStartHourSelectChange}
                ref={startHourSelectRef}
                availableHours={availableStartHours}
              />
              <HourSelector
                name="endHour"
                placeholder={t("reservation.config.arrival-hour")}
                disabled={!availableEndHours.length}
                onChange={onEndHourSelectChange}
                ref={endHourSelectRef}
                availableHours={availableEndHours}
              />
              <PassengersNumberSelector
                name="numberOfPassengers"
                placeholder={t("reservation.config.passengers-number")}
                onChange={onPassengersNumberChange}
                ref={passengersSelectRef}
                passengersNumber={selectedYacht?.passengers_number}
              />
            </ReservationConfigContainer>
            <ReservationConfigContainer shouldDisplayScroll>
              <AddonsTitle>{t("reservation.config.addons")}</AddonsTitle>
              <AddonsSelector
                addons={selectedYacht?.additionals}
                selectedAddons={selectedAddons}
                userMembership={user?.membership}
                onAddOnCheckboxChange={onAddOnCheckboxChange}
              />
            </ReservationConfigContainer>
            <TotalSectionContainer>
              <div>
                <TotalPriceTitle>Total</TotalPriceTitle>
                <TotalPrice>
                  {getFormattedPrice(bookingTotal)} <Currency>usd</Currency>
                </TotalPrice>
              </div>
              <PrimaryButton onClick={setReservationConfig}>
                {t("book-button-label")}
              </PrimaryButton>
            </TotalSectionContainer>
          </Addons>
        </SelectAddonsContentLayout>
        <ImagesSliderSection />
      </MainReservationLayout>
    </>
  );
};

export default SelectAddons;
