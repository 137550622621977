import mixpanel from "mixpanel-browser";
import ReservationFlowEvents from "./events/reservationFlowEvents";
import AuthFlowEvents from "./events/authFlowEvents";
import PaymentActionsEvents from "./events/paymentActionsEvents";
import UserActionsEvents from "./events/userActionsEvents";
import tenantService from "services/tenant";
import environment from "shared/constants/environment";

const { mixpanelKey } = environment;

const MixpanelService = () => {
  const isEnabled = Boolean(mixpanelKey);
  const reservationFlowEvents = ReservationFlowEvents({
    mixpanelIsEnabled: isEnabled,
  });
  const authFlowEvents = AuthFlowEvents({ mixpanelIsEnabled: isEnabled });
  const paymentActionsEvents = PaymentActionsEvents({
    mixpanelIsEnabled: isEnabled,
  });
  const userActionsEvents = UserActionsEvents({ mixpanelIsEnabled: isEnabled });

  const init = () => {
    if (isEnabled) {
      mixpanel.init(mixpanelKey, { ignore_dnt: true });
      mixpanel.register({
        "Tenant Name": tenantService.getId(),
      });
    }
  };

  const trackEvent = (eventName: string, properties?: any) => {
    if (isEnabled) {
      mixpanel.track(eventName, properties);
    }
  };

  return {
    init,
    isEnabled,
    trackEvent,
    track: {
      ...reservationFlowEvents,
      ...authFlowEvents,
      ...paymentActionsEvents,
      ...userActionsEvents,
    },
  };
};

const mixpanelService = MixpanelService();
export default mixpanelService;
