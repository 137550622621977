const fontSizes = {
  "3xsm": "8px",
  "2xsm": "10px",
  xsm: "12px",
  sm: "14px",
  lsm: "16px",
  md: "18px",
  lg: "24px",
  xl: "28px",
  "2xl": "36px",
  "3xl": "42px",
  "4xl": "54px",
};

export { fontSizes };
