import styled from "styled-components";
import BootstrapToast from "react-bootstrap/Toast";
import { ENotificationType } from "shared/types/notification";
import { colors } from "shared/constants/design";

const { white } = colors;

interface IBaseToast {
  type?: ENotificationType;
}

const BaseToast = styled(BootstrapToast)`
  background-color: ${white};
  border-radius: 5px;
`;

export { BaseToast };
export type { IBaseToast };
