import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const AmenityIcon = styled.img`
  height: 15px;
  width: 15px;

  @media ${desktop} {
    height: 25px;
    width: 25px;
  }
`;

export { AmenityIcon };
