import { FC } from "react";
import { If, Then, Else } from "react-if";
import ShipLayout from "components/templates/ShipLayout";
import {
  ProfileDetails,
  EditProfileForm,
  DesktopEditProfileModal,
} from "components/organisms/Profile";
import Card from "components/atoms/Card";
import {
  ProfileMobileLayout,
  ProfileDesktopLayout,
  ProfileMobileDetails,
  ProfileDesktopDetails,
  ProfileDesktopContentLayout,
  ProfileNavigation,
  ProfileMainContent,
} from "components/atoms/Profile";
import useDisclosure from "hooks/useDisclosure";
import useWindowSizes from "hooks/useWindowSizes";
import { EShipPosition } from "shared/types/common";

const ProfileTemplate: FC = ({ children }) => {
  const { width } = useWindowSizes();
  const {
    isOpen: displayEditProfileForm,
    onToggle: toggleDisplayEditProfileForm,
  } = useDisclosure();
  const desktopBreakpoints = 1200;
  const isHittingDesktopBreakpoint = width && width >= desktopBreakpoints;

  return (
    <>
      <ProfileMobileLayout>
        <ProfileMobileDetails>
          <If condition={displayEditProfileForm}>
            <Then>
              <EditProfileForm onCancel={toggleDisplayEditProfileForm} />
            </Then>
            <Else>
              <ProfileDetails onEditProfile={toggleDisplayEditProfileForm} />
            </Else>
          </If>
        </ProfileMobileDetails>
        <Card maxWidth="100%">
          <ProfileMainContent>
            <ProfileNavigation />
            {children}
          </ProfileMainContent>
        </Card>
      </ProfileMobileLayout>
      <ProfileDesktopLayout>
        <ShipLayout position={EShipPosition.RIGHT} shouldDisplayDesktopQueries>
          <ProfileDesktopContentLayout>
            <ProfileDesktopDetails>
              <ProfileDetails onEditProfile={toggleDisplayEditProfileForm} />
            </ProfileDesktopDetails>
            <ProfileMainContent>
              <ProfileNavigation />
              {children}
            </ProfileMainContent>
          </ProfileDesktopContentLayout>
        </ShipLayout>
      </ProfileDesktopLayout>
      <DesktopEditProfileModal
        isOpen={Boolean(displayEditProfileForm && isHittingDesktopBreakpoint)}
        onCancel={toggleDisplayEditProfileForm}
      />
    </>
  );
};

export default ProfileTemplate;
