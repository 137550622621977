import { useState, useEffect } from "react";

interface IWindowSizes {
  width?: number;
  height?: number;
}

const useWindowSizes = () => {
  const [windowSizes, setWindowSizes] = useState<IWindowSizes>({
    width: undefined,
    height: undefined,
  });

  const onWindowResized = () => {
    setWindowSizes({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", onWindowResized);
    onWindowResized();
    return () => window.removeEventListener("resize", onWindowResized);
  }, []);

  return windowSizes;
};

export default useWindowSizes;
