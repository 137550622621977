import styled from "styled-components";
import { fonts, fontSizes, colors } from "shared/constants/design";

const { text } = colors;
const { nexaRegular } = fonts;

const FormRecommendation = styled.p`
  color: ${text};
  font-family: ${nexaRegular};
  font-size: ${fontSizes["2xsm"]};
  letter-spacing: 0.45;
  line-height: 12px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
`;

export { FormRecommendation };
