import { forwardRef } from "react";
import { ISelect, Select } from "components/atoms/form";

interface IPassengersNumberSelector extends ISelect {
  passengersNumber?: number;
}

const PassengersNumberSelector = forwardRef<
  HTMLSelectElement,
  IPassengersNumberSelector
>(({ passengersNumber = 0, children, ...selectProps }, ref) => (
  <Select {...selectProps} ref={ref}>
    {children}
    {[...new Array(passengersNumber)].map((value, index) => (
      <option value={index + 1} key={index}>
        {index + 1}
      </option>
    ))}
  </Select>
));

export default PassengersNumberSelector;
