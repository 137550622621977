import styled from "styled-components";
import { ProfileDetailsContainer } from ".";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const EditProfileFormContainer = styled(ProfileDetailsContainer)`
  @media ${desktop} {
    justify-items: flex-end;
    grid-template-columns: 350px 300px;
    align-items: flex-start;
    grid-gap: 50px;
  }
`;

export { EditProfileFormContainer };
