import { When } from "react-if";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import environment from "shared/constants/environment";

const { googleMapsApiKey } = environment;

const mapContainerStyles = {
  width: "100%",
  height: "200px",
};

interface IAddressMap {
  lat: number;
  lng: number;
}

const AddressMap = ({ lat, lng }: IAddressMap) => {
  
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
  });

  return (
    <When condition={isLoaded}>
      <GoogleMap
        mapContainerStyle={mapContainerStyles}
        center={{ lat, lng }}
        zoom={15}
      >
        <Marker position={{ lat, lng }} />
      </GoogleMap>
    </When>
  );
};

export { AddressMap };
