import styled from "styled-components";
import Slider from "react-slick";
import { IImage } from "shared/types/common";
import { limits } from "shared/constants/design";
import {isBrowserAndDesktopMedia} from 'utils/css'

const Slide = styled.img`
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const { desktopMaxWidth } = limits;

const totalMobilePaddingCount = "80px";
const totalDesktopPaddingCount = "100px";
const shipDesktopPadding = "40px";

const CustomSlider = styled(Slider)`
  max-width: calc(100vw - ${totalMobilePaddingCount});

  & .slick-list {
    border-radius: 10px;
    height: 100%;
  }

  & .slick-track {
    height: 100%;
  }

  & .slick-slide > div {
    height: 100%;
  }

  & .slick-dots {
    bottom: unset;
    top: 15px;
  }

  & .slick-dots > li > button::before {
    border-radius: 360px;
    border: 3px solid white;
    color: transparent;
    height: 15px;
    width: 15px;
  }

  & .slick-dots > .slick-active > button::before {
    background-color: white;
  }

  ${isBrowserAndDesktopMedia(`
    max-width: min(calc(100vw - ${totalDesktopPaddingCount}), calc(${desktopMaxWidth} - ${shipDesktopPadding}));

    & .slick-list {
      border-radius: 10px 10px 200px 10px;
    }
  `)}
`;

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
};

interface IYachtImagesSlider {
  images: IImage[];
}

const YachtImagesSlider = ({ images }: IYachtImagesSlider) => (
  <CustomSlider {...sliderSettings}>
    {images.map((image) => (
      <Slide src={image.url} key={image.url} />
    ))}
  </CustomSlider>
);

export { YachtImagesSlider };
