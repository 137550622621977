import { ECardBrandNames } from "shared/types/payment";
import assets from "shared/constants/assets";

const {
  americanExpress,
  visa,
  mastercard,
  discover,
  dinersClub,
  jcb,
  unionPay,
} = assets;

type TCardBrandsIcons = {
  [key in ECardBrandNames]: string;
};

const cardBrandsIcons: TCardBrandsIcons = {
  [ECardBrandNames.AMERICAN_EXPRESS]: americanExpress,
  [ECardBrandNames.MASTERCARD]: mastercard,
  [ECardBrandNames.VISA]: visa,
  [ECardBrandNames.DINERS]: dinersClub,
  [ECardBrandNames.JCB]: jcb,
  [ECardBrandNames.DISCOVER]: discover,
  [ECardBrandNames.UNION_PAY]: unionPay,
};

const getCardBrandIcon = (cardBrandName: ECardBrandNames) =>
  cardBrandsIcons[cardBrandName];

export default getCardBrandIcon;
