import styled from "styled-components";
import { fonts, fontSizes, colors } from "shared/constants/design";

const { textInverted } = colors;
const { md } = fontSizes;
const { nexaBold } = fonts;

const FiltersTitle = styled.h4`
  color: ${textInverted};
  font-family: ${nexaBold};
  font-size: ${md};
  letter-spacing: 0.81;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
`;

export { FiltersTitle };
