import styled from "styled-components";
import {
  fonts,
  fontSizes,
  fontWeights,
  colors,
  devices,
} from "shared/constants/design";

const { text } = colors;
const { semiBold } = fontWeights;
const { lg, xl } = fontSizes;
const { conthrax } = fonts;
const { desktop } = devices;

const YachtsListTitle = styled.h3`
  color: ${text};
  font: italic normal ${semiBold} ${lg} ${conthrax};
  line-height: ${lg};
  margin: 0;
  text-transform: uppercase;

  @media ${desktop} {
    font-size: ${xl};
    line-height: ${xl};
  }
`;

export { YachtsListTitle };
