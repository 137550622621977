import styled from "styled-components";
import { colors, borders } from "shared/constants/design";

const { backgroundSecondary } = colors;
const {borderRadiusLg, shipBorderRadius} = borders

const ProfileDesktopDetails = styled.div`
  background: ${backgroundSecondary};
  border-radius: ${borderRadiusLg} ${borderRadiusLg} ${borderRadiusLg} ${shipBorderRadius};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  max-width: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  width: 100%;
`;

export { ProfileDesktopDetails };
