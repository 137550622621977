import styled from "styled-components";
import {
  isBrowserAndDesktopMedia,
  isBrowserAndDesktopMdMedia,
} from "utils/css";

const SelectAddonsContentLayout = styled.div`
  display: grid;
  grid-gap: 30px;
  overflow: hidden;

  ${isBrowserAndDesktopMedia(`
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 55%;
  `)}

  ${isBrowserAndDesktopMdMedia(`
    grid-template-columns: 1fr 1fr 50%;
    grid-gap: 60px;
  `)}
`;

export { SelectAddonsContentLayout };
