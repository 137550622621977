import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const DisplayInMobile = styled.div`
  display: block;

  @media ${desktop} {
    display: none;
  }
`;

export { DisplayInMobile };
