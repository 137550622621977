import reservationService from "services/reservation";
import useFetch from "./useFetch";
import type { IReservation } from "shared/types/reservation";

const useReservationDetails = (reservationId?: number) => {
  const {
    data: reservationDetails,
    isFetching: isFetchingDetails,
    hasError: hasErrorFetchingDetails,
  } = useFetch<IReservation | undefined>({
    initialData: undefined,
    fetcher: async () =>
      await reservationService.getReservationById(reservationId!),
    shouldFetch: Boolean(reservationId),
  });

  return {
    reservationDetails,
    isFetchingDetails,
    hasErrorFetchingDetails,
  };
};

export default useReservationDetails;
