import styled from "styled-components";
import { colors, devices } from "shared/constants/design";

const { text } = colors;
const { desktop } = devices;

const ListHeaderDivider = styled.hr`
  display: none;

  @media ${desktop} {
    background-color: transparent;
    border-top: 1px solid ${text};
    display: block;
    margin: 0;
    margin-top: 5px;
    opacity: 1;
    width: 100%;
  }
`;

export { ListHeaderDivider };
