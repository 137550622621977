import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const ProfileDesktopLayout = styled.div`
  display: none;

  @media ${desktop} {
    display: block;
    height: 100%;
    overflow: hidden;
  }
`;

export { ProfileDesktopLayout };
