import styled from "styled-components";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import SecondaryButton from "components/atoms/SecondaryButton";
import PrimaryButton from "components/atoms/PrimaryButton";
import {
  colors,
  fonts,
  fontSizes,
  fontWeights,
  devices,
} from "shared/constants/design";

const { text, primary } = colors;
const { nexaRegular, conthrax } = fonts;
const { md, lg } = fontSizes;
const { semiBold } = fontWeights;
const { desktop } = devices;

const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-items: right;
`;

const ModalBody = styled(Modal.Body)`
  color: ${text};
  font-family: ${nexaRegular};
  font-size: ${md};
  letter-spacing: 0.63px;
  line-height: 17px;
`;

const ModalTitle = styled.h4`
  color: ${primary};
  font: italic normal ${semiBold} ${md} ${conthrax};
  margin: 0;
  text-transform: uppercase;
  height: fit-content;

  @media ${desktop} {
    font-size: ${lg};
  }
`;

interface IPreReservationModal {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

const PreReservationModal = ({
  isOpen,
  onClose,
  onSubmit,
}: IPreReservationModal) => {
  const { t } = useTranslation();

  return (
    <Modal show={isOpen} centered size="lg">
      <Modal.Header>
        <ModalTitle>{t("reservation.pre-reservation.title")}</ModalTitle>
      </Modal.Header>
      <ModalBody>{t("reservation.pre-reservation-message")}</ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onClose}>
          {t("reservation.pre-reservation.modify-reservation")}
        </SecondaryButton>
        <PrimaryButton onClick={onSubmit}>
          {t("reservation.pre-reservation-finish")}
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  );
};

export { PreReservationModal };
