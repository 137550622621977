import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const ProfileDetailsSection = styled.div`
  display: grid;
  grid-gap: 5px;
  place-items: center;

  @media ${desktop} {
    justify-items: flex-end;
  }
`;

export { ProfileDetailsSection };
