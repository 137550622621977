import styled from "styled-components";
import { devices } from "shared/constants/design";

const { tabletLg, desktop } = devices;

const ProfileNavigationList = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-rows: auto auto auto;
  place-items: center;

  @media ${tabletLg} {
    grid-gap: 30px;
    grid-template-columns: auto auto auto;
    grid-template-rows: none;
    margin: 0 auto;
    width: fit-content;
  }

  @media ${desktop} {
    margin: 0;
  }
`;

export { ProfileNavigationList };
