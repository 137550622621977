import { useState, FC } from "react";
import { useTranslation } from "react-i18next";
import NotificationContext from "./NotificationContext";
import Toast, { IToast } from "components/molecules/Toast";
import {
  INotifyConfiguration,
  ENotificationType,
} from "shared/types/notification";

const NotificationProvider: FC = ({ children }) => {
  const { t } = useTranslation();
  const [toastProps, setToastProps] = useState<IToast>({
    show: false,
    onClose: () => {
      setToastProps({
        ...toastProps,
        show: false,
      });
    },
    title: "",
    description: "",
  });

  const notify = ({
    type = ENotificationType.SUCCESS,
    title = t(`notification.default-title.${type}`)!,
    ...restOfConfiguration
  }: INotifyConfiguration) => {
    setToastProps({
      ...toastProps,
      title,
      type,
      ...restOfConfiguration,
      show: true,
    });
  };

  const contextValue = {
    notify,
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      <Toast {...toastProps} />
    </NotificationContext.Provider>
  );
};

export { NotificationProvider };
