import styled from "styled-components";

const CreditCardContent = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  padding: 15px;
  width: 100%;
`;

export { CreditCardContent };
