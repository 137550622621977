import styled from "styled-components";
import { fonts, fontSizes, fontWeights, colors } from "shared/constants/design";

const { white } = colors;
const { semiBold } = fontWeights;
const { xl } = fontSizes;
const { conthrax } = fonts;

const TotalSavings = styled.h3`
  color: ${white};
  font: italic normal ${semiBold} ${xl} ${conthrax};
  line-height: 32px;
  margin: 0;
  text-transform: uppercase;
`;

export { TotalSavings };
