import { FC } from "react";
import styled, { css } from "styled-components";
import { colors, borders, devices } from "shared/constants/design";
import assets from "shared/constants/assets";

const { waves } = assets;
const { white, blackShadowDark } = colors;
const { borderRadiusLg } = borders;
const { tabletSmall } = devices;

interface ICard {
  width?: string;
  maxWidth?: string;
}

const CardContainer = styled.div<ICard>(
  ({ width = "100%", maxWidth = "450px" }) => css`
    background-color: ${white};
    border-radius: ${borderRadiusLg};
    box-shadow: 6px 6px 64px ${blackShadowDark};
    max-width: ${maxWidth};
    overflow: hidden;
    position: relative;
    width: ${width};
    z-index: 0;

    @media ${tabletSmall} {
      padding: 30px;
    }
  `
);

const WavesBackground = styled.img`
  bottom: 80px;
  object-fit: cover;
  object-position: center;
  position: absolute;
  transform: scale(1.7);
  z-index: -1;
`;

const CardContent = styled.div`
  height: 100%;
  padding: 30px;
  width: 100%;
`;

const Card: FC<ICard> = ({ children, ...cardProps }) => (
  <CardContainer {...cardProps}>
    <WavesBackground src={waves} alt="" width="480" height="204" />
    <CardContent>{children}</CardContent>
  </CardContainer>
);

export type { ICard };
export default Card;
