import styled from "styled-components";
import { colors, fonts, fontSizes } from "shared/constants/design";

const { text } = colors;
const { nexaRegular } = fonts;
const { sm } = fontSizes;

const Text = styled.p`
  color: ${text};
  font-family: ${nexaRegular};
  font-size: ${sm};
  letter-spacing: 0.63px;
  line-height: 17px;
  margin: 0;
`;

export default Text;
