import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const MobileFiltersContainer = styled.div`
  display: grid;
  grid-gap: 15px;
  height: fit-content;
  padding: 100px 30px;

  @media ${desktop} {
    display: none;
  }
`;

export { MobileFiltersContainer };
