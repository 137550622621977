import styled from "styled-components";
import {
  fonts,
  fontSizes,
  fontWeights,
  colors,
  devices,
} from "shared/constants/design";

const { text } = colors;
const { semiBold } = fontWeights;
const { xl } = fontSizes;
const { conthrax } = fonts;
const { desktop } = devices;

const YachtCardRegularRate = styled.h5`
  color: ${text};
  font: italic normal ${semiBold} ${fontSizes["2xsm"]} ${conthrax};
  line-height: 18px;
  margin: 0;
  opacity: 0.6;
  text-align: right;
  text-decoration: line-through;
  text-transform: lowercase;

  @media ${desktop} {
    font-size: ${xl};
    line-height: 42px;
    opacity: 1;
    text-decoration: none;
  }
`;

export { YachtCardRegularRate };
