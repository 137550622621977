import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const MobileNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  padding-top: 50px;
  position: sticky;
  top: 0;

  @media ${desktop} {
    display: none;
  }
`;

export { MobileNavbar };
