import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { colors, fontSizes, devices, fonts } from "shared/constants/design";

const { background, secondary } = colors;
const { desktop } = devices;
const { xsm } = fontSizes;
const { nexaBold } = fonts;

const YachtReserveCardButton = styled(Button)`
  background-color: ${background};
  border-radius: 5px;
  border: none;
  font-family: ${nexaBold};
  font-size: ${fontSizes["2xsm"]};
  letter-spacing: 0.45px;
  padding: 10px 15px;
  text-transform: uppercase;
  line-height: 1;

  &:hover {
    background-color: ${secondary};
  }

  &:focus {
    background-color: ${secondary};
  }

  @media ${desktop} {
    font-size: ${xsm};
  }
`;

export { YachtReserveCardButton };
