import { useTranslation } from "react-i18next";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  NewPaymentMethodFormActions,
  NewPaymentMethodFormContainer,
  NewPaymentMethodFormInputContainer,
} from "components/atoms/PaymentMethods";
import PrimaryButton from "components/atoms/PrimaryButton";
import { FormTitle } from "components/atoms/form";
import SecondaryButton from "components/atoms/SecondaryButton";
import useNotification from "hooks/useNotification";
import { ENotificationType } from "shared/types/notification";
import { colors } from "shared/constants/design";
import { useState } from "react";

const { text, error } = colors;

const cardElementOptions = {
  style: {
    base: {
      color: text,
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: error,
      iconColor: error,
    },
  },
};

type ToDefineCardToken = any;

interface INewPaymentMethodForm {
  onSubmit: (cardToken: ToDefineCardToken) => Promise<void> | void;
  onCancel: () => void;
}

const NewPaymentMethodForm = ({
  onSubmit,
  onCancel,
}: INewPaymentMethodForm) => {
  const { t } = useTranslation();
  const elements = useElements();
  const stripe = useStripe();
  const notify = useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const stripeIsLoaded = stripe && elements;
    if (!stripeIsLoaded) return;

    setIsSubmitting(true);
    try {
      const card = elements?.getElement(CardElement)!;
      const tokenizationResult = await stripe?.createPaymentMethod({
        type: "card",
        card,
      });
      if (tokenizationResult?.error) {
        notify({
          type: ENotificationType.ERROR,
          description: tokenizationResult.error.message || "",
        });
        return;
      }
      await onSubmit(tokenizationResult?.paymentMethod);
      notify({
        description: t("payment-methods.form.success"),
      });
    } catch (error: any) {
      notify({
        type: ENotificationType.ERROR,
        description: error.message,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <NewPaymentMethodFormContainer onSubmit={handleSubmit}>
      <FormTitle>{t("payment-methods.form.title")}</FormTitle>
      <NewPaymentMethodFormInputContainer>
        <CardElement options={cardElementOptions} />
      </NewPaymentMethodFormInputContainer>
      <NewPaymentMethodFormActions>
        <SecondaryButton onClick={onCancel} isLoading={isSubmitting}>
          {t("payment-methods.form.cancel")}
        </SecondaryButton>
        <PrimaryButton type="submit" isLoading={isSubmitting}>
          {t("payment-methods.form.add")}
        </PrimaryButton>
      </NewPaymentMethodFormActions>
    </NewPaymentMethodFormContainer>
  );
};

export { NewPaymentMethodForm };
