import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const TotalSectionContainer = styled.div`
  align-self: end;
  display: grid;
  grid-gap: 30px;
  height: fit-content;
  place-items: center;

  @media ${desktop} {
    grid-gap: 15px;
  }
`;

export { TotalSectionContainer };
