import styled from "styled-components";
import { colors, devices } from "shared/constants/design";

const { background } = colors;
const { desktop } = devices;

const DesktopNavbar = styled.div`
  background-color: ${background};
  display: none;
  padding: 0;
  padding-bottom: 10px;
  place-items: center;

  @media ${desktop} {
    display: grid;
  }
`;

export { DesktopNavbar };
