import styled from "styled-components";

const MyMembershipSection = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  overflow: hidden;
`;

export { MyMembershipSection };
