import styled from "styled-components";
import { fonts, fontSizes, fontWeights, colors } from "shared/constants/design";

const { primary } = colors;
const { semiBold } = fontWeights;
const { md } = fontSizes;
const { conthrax } = fonts;

const RegisterFormTitle = styled.h1`
  color: ${primary};
  font: italic normal ${semiBold} ${md} ${conthrax};
  margin: 0;
  text-align: center;
  text-transform: uppercase;
`;

export { RegisterFormTitle };
