import moment from "moment";
import { IReservation } from "shared/types/reservation";

const getReservationNumberOfHours = (reserve?: IReservation) => {
  const startMoment = moment(reserve?.start_date);
  const endMoment = moment(reserve?.end_date);
  const numberOfHours = moment.duration(endMoment.diff(startMoment)).asHours();
  return numberOfHours;
};

export default getReservationNumberOfHours;
