import styled from "styled-components";
import { colors, fontWeights, fontSizes, fonts } from "shared/constants/design";

const { text } = colors;
const { semiBold } = fontWeights;
const { lg } = fontSizes;
const { conthrax } = fonts;

const YachtReserveCardName = styled.h6`
  color: ${text};
  font: italic normal ${semiBold} ${lg} ${conthrax};
  text-decoration: none;
  text-transform: uppercase;
`;

export { YachtReserveCardName };
