import { FC } from "react";
import { Else, If, Then } from "react-if";
import IsEmptyMessageSection from "components/atoms/IsEmptyMessageSection";
import LoaderSection from "components/atoms/LoaderSection";

interface IFetchSection {
  hasError: boolean;
  isFetching: boolean;
  isEmpty: boolean;
  hasErrorMessage: string;
  isEmptyMessage: string;
}

const FetchSection: FC<IFetchSection> = ({
  isFetching,
  hasError,
  isEmpty,
  isEmptyMessage,
  hasErrorMessage,
  children,
}) => (
  <If condition={isFetching}>
    <Then>
      <LoaderSection />
    </Then>
    <Else>
      <If condition={hasError}>
        <Then>{hasErrorMessage}</Then>
        <Else>
          <If condition={isEmpty}>
            <Then>
              <IsEmptyMessageSection>{isEmptyMessage}</IsEmptyMessageSection>
            </Then>
            <Else>{children}</Else>
          </If>
        </Else>
      </If>
    </Else>
  </If>
);

export default FetchSection;
