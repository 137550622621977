import { useContext } from "react";
import AuthContext from "./AuthContext";

const useAuthContext = () => {
  const contextValue = useContext(AuthContext);

  if (contextValue === undefined) {
    throw new Error("You must use useAuthContext within a AuthProvider");
  }

  return contextValue;
};

export default useAuthContext;
