import mixpanel from "mixpanel-browser";

interface ITrackDestinationSelectedPayload {
  destinationName: string;
}

interface ITrackYachtSelectedPayload {
  yachtName: string;
}

interface ITrackBookingDetailsFilledPayload {
  date: string;
  departureHour: string;
  returnHour: string;
  passengersNumber: number;
}

interface IInitReservationFlow {
  mixpanelIsEnabled: boolean;
}

const ReservationFlowEvents = ({ mixpanelIsEnabled }: IInitReservationFlow) => {
  const destinationSelected = ({
    destinationName,
  }: ITrackDestinationSelectedPayload) => {
    if (!mixpanelIsEnabled) return;
    mixpanel.track("Destination Selected", {
      "Destination Name": destinationName,
    });
  };

  const yachtSelected = ({ yachtName }: ITrackYachtSelectedPayload) => {
    if (!mixpanelIsEnabled) return;
    mixpanel.track("Yacht Selected", { "Yacht Name": yachtName });
  };

  const bookingDetailsFilled = ({
    date,
    departureHour,
    returnHour,
    passengersNumber,
  }: ITrackBookingDetailsFilledPayload) => {
    if (!mixpanelIsEnabled) return;
    mixpanel.track("Booking Details Filled", {
      Date: date,
      Departure: departureHour,
      Return: returnHour,
      "Passengers Number": passengersNumber,
    });
  };

  const bookingAttempt = () => {
    if (!mixpanelIsEnabled) return;
    mixpanel.track("Booking Attempt");
  };

  const bookingCompleted = () => {
    if (!mixpanelIsEnabled) return;
    mixpanel.track("Booking Completed");
  };

  return {
    destinationSelected,
    yachtSelected,
    bookingDetailsFilled,
    bookingAttempt,
    bookingCompleted,
  };
};

export default ReservationFlowEvents;
