import styled from "styled-components";
import { borders, colors } from "shared/constants/design";

const { borderRadiusLg } = borders;
const { white, blackShadowLight } = colors;

const YachtReserveCardContainer = styled.div`
  background: ${white};
  border-radius: ${borderRadiusLg};
  box-shadow: 6px 6px 32px ${blackShadowLight};
  display: grid;
  grid-template-rows: 40% 1fr;
  height: 400px;
  width: 100%;
`;

export { YachtReserveCardContainer };
