import styled from "styled-components";

const YachtCardDesktopData = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 35% 1fr 1fr;
  margin-right: 30px;
`;

export { YachtCardDesktopData };
