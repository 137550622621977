import styled from "styled-components";
import Button, { ButtonProps } from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { When } from "react-if";
import { colors, fontSizes, fonts, fontWeights } from "shared/constants/design";

const { primaryOpaque, primaryBorderColor } = colors;
const { sm } = fontSizes;
const { semiBold } = fontWeights;
const { conthrax } = fonts;

const BasePrimaryButton = styled(Button)`
  align-items: flex-end;
  background-color: ${primaryOpaque};
  border-radius: 14px;
  border: 1px solid ${primaryBorderColor};
  display: flex;
  font: italic normal ${semiBold} ${sm} ${conthrax};
  gap: 10px;
  letter-spacing: 1.15px;
  line-height: 1;
  padding: 7px 30px;
  text-transform: uppercase;

  &:hover {
    background-color: ${primaryBorderColor};
  }

  &:focus {
    background-color: ${primaryBorderColor};
  }

  &:disabled {
    background-color: ${primaryBorderColor};
    cursor: not-allowed;
  }
`;

interface IPrimaryButton extends ButtonProps {
  isLoading?: boolean;
}

const PrimaryButton = ({
  children,
  isLoading = false,
  disabled,
  ...restOfProps
}: IPrimaryButton) => (
  <BasePrimaryButton disabled={disabled || isLoading} {...restOfProps}>
    <When condition={isLoading}>
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    </When>
    <span>{children}</span>
  </BasePrimaryButton>
);

export default PrimaryButton;
