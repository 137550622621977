import { createGlobalStyle } from "styled-components";
import { fontWeights } from "shared/constants/design";

const { regular, light, bold } = fontWeights;

const nexaBoldUrl = "/fonts/NexaBold.woff2";
const nexaLightUrl = "/fonts/NexaLight.woff2";
const nexaRegularUrl = "/fonts/NexaRegular.woff2";
const conthraxUrl = "/fonts/conthrax.ttf";

const globalStyles = createGlobalStyle`
  @font-face {
    font-family: NexaBold;
    src: url(${nexaBoldUrl}) format('woff2');
    font-weight: ${bold};
    font-style: normal;
  }

  @font-face {
    font-family: NexaLight;
    src: url(${nexaLightUrl}) format('woff2');
    font-weight: ${light};
    font-style: normal;
  }

  @font-face {
    font-family: NexaRegular;
    src: url(${nexaRegularUrl}) format('woff2');
    font-weight: ${regular};
    font-style: normal;
  }

  @font-face {
    font-family: Conthrax;
    src: url(${conthraxUrl}) format('truetype');
  }

  // Hides strange icon displaying above chat diamond
  #retailcrm-consultant-app .consultant-icon__img {
    display: none;
  }
`;

export default globalStyles;
