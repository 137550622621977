import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const ProfileMobileLayout = styled.div`
  display: grid;
  grid-gap: 20px;
  height: fit-content;
  padding: 20px;

  @media ${desktop} {
    display: none;
  }
`;

export { ProfileMobileLayout };
