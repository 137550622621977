import { FC } from "react";
import styled from "styled-components";
import BaseOffcanvas, { OffcanvasProps } from "react-bootstrap/Offcanvas";
import "./sidebar.css";

const Offcanvas = styled(BaseOffcanvas)`
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background: rgba(25, 25, 25, 0.7);
  width: 250px;
`;

const Sidebar: FC<OffcanvasProps> = ({ children, ...canvasProps }) => (
  <Offcanvas {...canvasProps} backdropClassName="sidebar-backdrop">
    {children}
  </Offcanvas>
);

export default Sidebar;
