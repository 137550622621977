import styled from "styled-components";
import { fonts, fontSizes, colors } from "shared/constants/design";

const { textLight } = colors;
const { lsm } = fontSizes;
const { nexaBold } = fonts;

const ColumnName = styled.h6`
  color: ${textLight};
  font-family: ${nexaBold};
  font-size: ${lsm};
  letter-spacing: 0.81;
  line-height: 24px;
  text-align: right;
  text-transform: uppercase;
`;

export { ColumnName };
