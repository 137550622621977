import styled from "styled-components";
import { Form as BaseForm } from "components/atoms/form";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const EditProfileFormComponent = styled(BaseForm)`
  @media ${desktop} {
    padding-top: 50px;
    padding-right: 50px;
  }
`;

export { EditProfileFormComponent };
