import { useTranslation } from "react-i18next";
import useAuthContext from "context/AuthContext";
import getUserMembershipName from "utils/getUserMembershipName";
import { EAvailableLanguages } from "shared/types/common";

const useUserMembershipTitle = () => {
  const { i18n } = useTranslation();
  const { user } = useAuthContext();
  const userMembershipName = user ? getUserMembershipName(user) : "";
  const userMembershipTitle =
    i18n.language === EAvailableLanguages.EN
      ? `${userMembershipName} Membership`
      : `Membresía ${userMembershipName}`;

  return {
    userMembershipTitle,
  };
};

export default useUserMembershipTitle;
