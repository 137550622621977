import styled from "styled-components";
import { isBrowserAndDesktopMedia, isMobileAndDesktopMedia } from "utils/css";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const SliderContainer = styled.div`
  direction: rtl;
  display: flex;
  flex-direction: column;
  overflow-x: auto;

  ${isBrowserAndDesktopMedia(`
    border-radius: 10px 10px 200px 10px;
  `)}

  ${isMobileAndDesktopMedia(`
    border-radius: 10px;
  `)}

  @media ${desktop} {
    z-index: 10;
  }
`;

export { SliderContainer };
