import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const YachtCardDesktopContent = styled.div`
  display: none;

  @media ${desktop} {
    display: grid;
    grid-gap: 40px;
    padding: 25px;
  }
`;

export { YachtCardDesktopContent };
