import navbarEn from "./navbar/en.json";
import navbarEs from "./navbar/es.json";
import notificationsEn from "./notifications/en.json";
import notificationsEs from "./notifications/es.json";
import registerEn from "./register/en.json";
import registerEs from "./register/es.json";
import loginEn from "./login/en.json";
import loginEs from "./login/es.json";
import resetPasswordEn from "./resetPassword/en.json";
import resetPasswordEs from "./resetPassword/es.json";
import formsEn from "./forms/en.json";
import formsEs from "./forms/es.json";
import yachtsEn from "./yachts/en.json";
import yachtsEs from "./yachts/es.json";
import reservationsEn from "./reservations/en.json";
import reservationsEs from "./reservations/es.json";
import paymentEn from "./payment/en.json";
import paymentEs from "./payment/es.json";
import profileEn from "./profile/en.json";
import profileEs from "./profile/es.json";
import popoverEn from "./popover/en.json";
import popoverEs from "./popover/es.json";
import policiesEn from "./policies/en.json";
import policiesEs from "./policies/es.json";
import activateEn from "./activate/en.json";
import activateEs from "./activate/es.json";
import exoticRolloutEn from "./exoticRollout/en.json";
import exoticRolloutEs from "./exoticRollout/es.json";
import termsAndConditionsEn from "./terms/en.json";
import termsAndConditionsEs from "./terms/es.json";
import { EAvailableLanguages } from "shared/types/common";

type TTranslations = {
  [K in EAvailableLanguages]: {
    translation: {
      [key: string]: string;
    };
  };
};

const translations: TTranslations = {
  [EAvailableLanguages.EN]: {
    translation: {
      ...navbarEn,
      ...notificationsEn,
      ...registerEn,
      ...loginEn,
      ...resetPasswordEn,
      ...formsEn,
      ...yachtsEn,
      ...reservationsEn,
      ...paymentEn,
      ...profileEn,
      ...popoverEn,
      ...policiesEn,
      ...activateEn,
      ...exoticRolloutEn,
      ...termsAndConditionsEn,
    },
  },
  [EAvailableLanguages.ES]: {
    translation: {
      ...navbarEs,
      ...notificationsEs,
      ...registerEs,
      ...loginEs,
      ...resetPasswordEs,
      ...formsEs,
      ...yachtsEs,
      ...reservationsEs,
      ...paymentEs,
      ...profileEs,
      ...popoverEs,
      ...policiesEs,
      ...activateEs,
      ...exoticRolloutEs,
      ...termsAndConditionsEs,
    },
  },
};

export default translations;
