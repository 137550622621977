import { FC } from "react";
import { GrowthBookProvider as BaseGrowthBookProvider } from "@growthbook/growthbook-react";
import growthBookService from "services/growthBook";

const GrowthBookProvider: FC = ({ children }) => (
  <BaseGrowthBookProvider growthbook={growthBookService.getInstance()}>
    {children}
  </BaseGrowthBookProvider>
);

export default GrowthBookProvider;
