import { useLocation } from "react-router-dom";

interface IResult {
  [key: string]: string | null;
}

const useQueryParams = (paramsNames: string[]) => {
  const query = new URLSearchParams(useLocation().search);
  const params = paramsNames.reduce((result, paramName) => {
    result[paramName] = query.get(paramName);
    return result;
  }, {} as IResult);

  return params;
};

export default useQueryParams;
