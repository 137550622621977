import { If, Then, Else } from "react-if";
import SendResetTokenToEmailForm from "components/organisms/SendResetTokenToEmailForm";
import ChangePasswordWithTokenForm from "components/organisms/ChangePasswordWithTokenForm";
import CenteredCard from "components/atoms/CenteredCard";
import useQueryParams from "hooks/useQueryParams";

const ResetPassword = () => {
  const { token } = useQueryParams(["token"]);

  return (
    <CenteredCard maxWidth="484px">
      <If condition={token}>
        <Then>
          <ChangePasswordWithTokenForm token={token!} />
        </Then>
        <Else>
          <SendResetTokenToEmailForm />
        </Else>
      </If>
    </CenteredCard>
  );
};

export default ResetPassword;
