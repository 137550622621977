import { IImage } from "shared/types/common";

const baseImagesUrl = "/assets/regular-membership";

const regularMembershipImages: IImage[] = [
  {
    id: 1,
    url: `${baseImagesUrl}/1.jpeg`,
  },
  {
    id: 2,
    url: `${baseImagesUrl}/2.jpeg`,
  },
  {
    id: 3,
    url: `${baseImagesUrl}/3.jpeg`,
  },
  {
    id: 4,
    url: `${baseImagesUrl}/4.jpeg`,
  },
  {
    id: 5,
    url: `${baseImagesUrl}/5.jpeg`,
  },
];

export default regularMembershipImages;
