import styled from "styled-components";
import { colors } from "shared/constants/design";

const { tertiary } = colors;

const NavbarLinksDivider = styled.hr`
  background-color: transparent;
  border-top: 1px solid ${tertiary};
  margin: 0;
  opacity: 1;
  width: 100%;
`;

export { NavbarLinksDivider };
