import { useState, FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import momentTimezone from "moment-timezone";
import useAuthContext from "context/AuthContext";
import ReservationContext, { IReservationContext } from "./ReservationContext";
import reservationService from "services/reservation";
import yachtService from "services/yacht";
import locationService from "services/location";
import useFetch from "hooks/useFetch";
import {
  EReservationFlowStatus,
  EReservationSteps,
  EStatusReserve,
} from "shared/types/reservation";
import { EPaymentMethodType, IPaymentMethod } from "shared/types/payment";
import type { IGetYachtsFilters, IYacht } from "shared/types/yacht";
import type { ILocation } from "shared/types/location";
import routes from "shared/constants/routes";
import paymentMethodService from "services/payment";
import blockedDatesService from "services/blockedDates";
import getDateFromStringValue from "utils/getDateFromStringValue";
import useBlockedDatesValidation from "hooks/useBlockedDatesValidation";
import { IYachtAddon } from "shared/types/yacht/IYachtAditional";
import getUserMembershipPrice from "utils/getUserMembershipPrice";
import scrollToTop from "utils/scrollToTop";
import useValidateReserveConfiguration from "hooks/useValidateReserveConfiguration";
import { IImage } from "shared/types/common";
import addonService from "services/addon/AddonService";
import mixpanelService from "services/mixpanel";

const { userReservations } = routes;

const initialReservationStep = EReservationSteps.LOCATION;

type TPreviousSteps = {
  [key in EReservationSteps]: EReservationSteps;
};

const previousStepOf: TPreviousSteps = {
  [EReservationSteps.LOCATION]: EReservationSteps.LOCATION,
  [EReservationSteps.YACHT]: EReservationSteps.LOCATION,
  [EReservationSteps.ADDONS]: EReservationSteps.YACHT,
  [EReservationSteps.RESUME]: EReservationSteps.ADDONS,
  [EReservationSteps.CHECKOUT]: EReservationSteps.RESUME,
};

const numberLimitOfHours = 8;

const ReservationProvider: FC = ({ children }) => {
  const history = useHistory();
  const { user } = useAuthContext();
  const [reservationFlowStatus, setReservationFlowStatus] =
    useState<EReservationFlowStatus>(EReservationFlowStatus.NOT_INITIALIZED);
  const [currentReservationStep, setCurrentReservationStep] =
    useState<EReservationSteps>(initialReservationStep);
  const [yachtsFilters, setYachtsFilters] = useState<IGetYachtsFilters>({
    active: true,
  });
  const [selectedAddons, setSelectedAddons] = useState<IYachtAddon[]>([]);
  const [selectedYachtAddonsImages, setSelectedYachtAddonsImages] = useState<
    IImage[]
  >([]);

  const fetchYachtsWithFilters = async () =>
    await yachtService.getYachts(yachtsFilters);
  const {
    data: availableYachts,
    isFetching: isFetchingAvailableYachts,
    hasError: hasErrorFetchingYachts,
  } = useFetch<IYacht[]>({
    initialData: [],
    fetcher: fetchYachtsWithFilters,
    shouldFetch: currentReservationStep === EReservationSteps.YACHT,
    dependencies: [yachtsFilters],
  });
  const {
    data: availableLocations,
    isFetching: isFetchingAvailableLocations,
    hasError: hasErrorFetchingAvailableLocations,
  } = useFetch<ILocation[]>({
    initialData: [],
    fetcher: locationService.getLocations,
  });
  const [selectedYacht, setSelectedYacht] = useState<IYacht | undefined>(
    undefined
  );
  const [displayPreReservationModal, setDisplayPreReservationModal] =
    useState(false);
  const [displaySuccessModal, setDisplaySuccessModal] = useState(false);
  const { data: availablePaymentMethods, fetchData: updateUserPaymentMethods } =
    useFetch<IPaymentMethod[]>({
      initialData: [],
      fetcher: paymentMethodService.getUserPaymentMethods,
      shouldFetch: Boolean(user),
      dependencies: [user],
    });
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<
    string | undefined
  >(undefined);
  const [startHour, setStartHour] = useState<Date | undefined>(undefined);
  const [endHour, setEndHour] = useState<Date | undefined>(undefined);
  const [date, onDateChange] = useState(new Date());
  const [createdReserveId, setCreatedReserveId] = useState<number | undefined>(
    undefined
  );
  const [availableStartHours, setAvailableStartHours] = useState<Date[]>([]);
  const [availableEndHours, setAvailableEndHours] = useState<Date[]>([]);
  const [blockedDates, setBlockedDates] = useState<Date[]>([]);
  const {
    isBlockedCurrentLocation,
    isBlockedSelectedYacht,
    isBlockedUserMembership,
    isDayOff,
  } = useBlockedDatesValidation(selectedYacht);
  const [numberOfPassengers, setNumberOfPassengers] = useState<
    number | undefined
  >(undefined);
  const [bookingTotal, setBookingTotal] = useState(0);
  const {
    validateEndHourAndPassengersNumber,
    validateWholeConfiguration,
    validateStartAndEndHours,
    validateStartHour,
    validateEndHour,
    validateNumberOfPassengers,
  } = useValidateReserveConfiguration(startHour, endHour, numberOfPassengers);

  useEffect(() => {
    scrollToTop();
  }, [currentReservationStep]);

  const calculateAndUpdateBookingTotal = () => {
    if (selectedYacht && startHour && endHour) {
      const startMoment = moment(startHour);
      const endMoment = moment(endHour);
      const numberOfHours = moment
        .duration(endMoment.diff(startMoment))
        .asHours();
      const yachtPrice =
        getUserMembershipPrice(selectedYacht.prices, user?.membership) *
        numberOfHours;
      const total = selectedAddons.reduce(
        (currentTotal, addon) =>
          currentTotal +
          getUserMembershipPrice(addon.prices || [], user?.membership),
        yachtPrice
      );
      setBookingTotal(total);
    } else {
      setBookingTotal(0);
    }
  };

  useEffect(() => {
    calculateAndUpdateBookingTotal();
  }, [selectedYacht, selectedAddons, startHour, endHour]);

  const fetchAndSetStartAvailableHours = async () => {
    if (selectedYacht && date && user) {
      const yachtAvailableHours =
        await reservationService.getAvailableHoursPerDay({
          date,
          yacht_id: selectedYacht?.id,
          timezone: momentTimezone.tz.guess(),
          user_id: user.id,
        });
      const nowMoment = moment();
      const availableHoursAfterNow = yachtAvailableHours.filter((date) =>
        moment(date).isAfter(nowMoment)
      );
      setAvailableStartHours(availableHoursAfterNow);
      setAvailableEndHours([]);
      setStartHour(undefined);
      setEndHour(undefined);
    }
  };

  useEffect(() => {
    fetchAndSetStartAvailableHours();
  }, [selectedYacht, date]);

  const fetchAndSetBlockedDates = async () => {
    if (user) {
      const generalBlockedDates = await blockedDatesService.getBlockedDates();
      const daysOffDates = generalBlockedDates
        .filter(isDayOff)
        .map(({ date }) => new Date(date));
      const blockedLocationDates = generalBlockedDates
        .filter(isBlockedCurrentLocation)
        .map(({ date }) => new Date(date));
      const blockedUserMembershipDates = generalBlockedDates
        .filter(isBlockedUserMembership)
        .map(({ date }) => new Date(date));
      const blockedSelectedYachtDates = generalBlockedDates
        .filter(isBlockedSelectedYacht)
        .map(({ date }) => new Date(date));
      const blockedDatesForUser = [
        ...daysOffDates,
        ...blockedLocationDates,
        ...blockedUserMembershipDates,
        ...blockedSelectedYachtDates,
      ];
      setBlockedDates(blockedDatesForUser);
    }
  };

  const getSelectedYachtsAddonsImages = async () => {
    if (selectedYacht) {
      const addonsImages = await Promise.all(
        selectedYacht.additionals.map(async ({ id }) => {
          const addonWithImage = await addonService.getOne(id);
          const addonImages = addonWithImage.images || [];
          return addonImages;
        })
      );
      setSelectedYachtAddonsImages(addonsImages.flat());
    }
  };

  useEffect(() => {
    fetchAndSetBlockedDates();
    setNumberOfPassengers(undefined);
    setSelectedAddons([]);
    getSelectedYachtsAddonsImages();
  }, [selectedYacht]);

  useEffect(() => {
    if (!selectedPaymentMethodId) {
      const defaultPaymentMethod = availablePaymentMethods.find(
        ({ id }) => user?.profile?.payment_key === id
      );
      setSelectedPaymentMethodId(defaultPaymentMethod?.id);
    }
  }, [availablePaymentMethods]);

  useEffect(() => {
    setDisplayPreReservationModal(
      reservationFlowStatus ===
        EReservationFlowStatus.DISPLAYING_PROMOTIONS_MODAL
    );

    setDisplaySuccessModal(
      reservationFlowStatus === EReservationFlowStatus.FINISHED
    );

    if (reservationFlowStatus === EReservationFlowStatus.NOT_INITIALIZED) {
      setCurrentReservationStep(initialReservationStep);
    }
  }, [reservationFlowStatus]);

  const onSelectLocation = (locationId: number) => {
    setYachtsFilters({
      ...yachtsFilters,
      location_id: locationId,
    });
    setCurrentReservationStep(EReservationSteps.YACHT);
  };

  const onSelectYacht = async (yacht: IYacht) => {
    mixpanelService.track.yachtSelected({ yachtName: yacht.name });
    const yachtAddons = await yachtService.getYachtAddons(yacht.id);
    setSelectedYacht({ ...yacht, additionals: yachtAddons });
    setCurrentReservationStep(EReservationSteps.ADDONS);
  };

  const onPreviousStep = () => {
    setCurrentReservationStep(previousStepOf[currentReservationStep]);
  };

  const setReservationConfig = () => {
    const hasIncompleteConfiguration =
      !startHour || !endHour || !numberOfPassengers;
    if (hasIncompleteConfiguration) {
      validateStartHour();
      validateEndHour();
      validateNumberOfPassengers();
      validateStartAndEndHours();
      validateEndHourAndPassengersNumber();
      validateWholeConfiguration();
      return;
    }
    const startMoment = moment(startHour);
    const endMoment = moment(endHour);
    const numberOfHours = moment
      .duration(endMoment.diff(startMoment))
      .asHours();
    if (numberOfHours >= numberLimitOfHours) {
      setReservationFlowStatus(
        EReservationFlowStatus.DISPLAYING_PROMOTIONS_MODAL
      );
      return;
    }

    mixpanelService.track.bookingDetailsFilled({
      // @ts-ignore - Validated at the beginning of the function
      date: date.toDateString(),
      // @ts-ignore - Validated at the beginning of the function
      departureHour: startHour.toDateString(),
      // @ts-ignore - Validated at the beginning of the function
      returnHour: endHour.toDateString(),
      // @ts-ignore - Validated at the beginning of the function
      passengersNumber: numberOfPassengers,
    });
    setCurrentReservationStep(EReservationSteps.RESUME);
  };

  const onClosePreReservationModal = () =>
    setReservationFlowStatus(EReservationFlowStatus.IN_COURSE);

  interface IOnFinishReservation {
    isPreReserve?: boolean;
  }

  const createReservation = async (props?: IOnFinishReservation) => {
    const additionals_ids = selectedAddons.map(({ id }) => id);

    if (selectedYacht && user && startHour && endHour && numberOfPassengers) {
      const response = await reservationService.createReservation({
        start_date: startHour,
        end_date: endHour,
        user_id: user.id,
        yacht_id: selectedYacht.id,
        timezone: momentTimezone.tz.guess(),
        amount: bookingTotal,
        status: props?.isPreReserve
          ? EStatusReserve.PRE_RESERVE
          : EStatusReserve.RESERVE,
        passengers_number: numberOfPassengers,
        payment_method_type: props?.isPreReserve
          ? EPaymentMethodType.CASH
          : EPaymentMethodType.CARD,
        payment_id: selectedPaymentMethodId,
        additionals_ids,
      });
      setCreatedReserveId(response.reserve.id);
    }
  };

  const onSendPreReservationRequest = async () => {
    await createReservation({ isPreReserve: true });
    onFinishReservation();
  };

  const goToCheckout = () =>
    setCurrentReservationStep(EReservationSteps.CHECKOUT);

  const onProceedToPayment = async () => {
    await createReservation();
    setReservationFlowStatus(EReservationFlowStatus.FINISHED);
  };

  const onFinishReservation = async () => {
    setReservationFlowStatus(EReservationFlowStatus.NOT_INITIALIZED);
    history.push(userReservations);
  };

  const onSelectPaymentMethod = (paymentMethodId: string) =>
    setSelectedPaymentMethodId(
      availablePaymentMethods.find(({ id }) => id === paymentMethodId)?.id
    );

  const onRemovePaymentMethod = async (paymentMethodId: string) => {
    await paymentMethodService.deleteUserPaymentMethod({
      payment_id: paymentMethodId,
    });
  };

  const onSetPaymentMethodAsDefault = async (paymentMethodId: string) => {
    await paymentMethodService.setUserPaymentMethodAsDefault({
      payment_id: paymentMethodId,
    });
  };

  const setYachtsLocationFilter = (locationId?: number) =>
    setYachtsFilters({
      ...yachtsFilters,
      location_id: locationId,
    });

  const setYachtsPassengersNumberFilter = (passengers_number?: number) =>
    setYachtsFilters({
      ...yachtsFilters,
      passengers_number,
    });

  const setYachtsSizeFilter = (size_id?: number) =>
    setYachtsFilters({
      ...yachtsFilters,
      size_id,
    });

  const setYachtsMinHoursFilter = (min_hours?: number) =>
    setYachtsFilters({
      ...yachtsFilters,
      min_hours,
    });

  const onStartHourChange = (dateString: string) => {
    const startDate = getDateFromStringValue(dateString);
    setStartHour(startDate);
    const minHoursToReserve = selectedYacht?.min_hours || 0;
    const minEndDate = moment(startDate).add(minHoursToReserve, "hour");
    const datesAfterOrEqualToMinEndDate = availableStartHours.filter((date) =>
      moment(date).isSameOrAfter(moment(minEndDate))
    );
    let previousDate = minEndDate.toDate();
    const continuousDates = datesAfterOrEqualToMinEndDate.filter((date) => {
      const hoursDiff = moment(previousDate).diff(date, "hours");
      const isContinuous = hoursDiff === -1 || hoursDiff === 0;
      previousDate = isContinuous ? date : previousDate;
      return isContinuous;
    });
    setAvailableEndHours(continuousDates);
    setEndHour(undefined);
  };

  const onEndHourChange = (dateString: string) => {
    const endDate = getDateFromStringValue(dateString);
    setEndHour(endDate);
  };

  const onAddAddon = (addon: IYachtAddon) => {
    setSelectedAddons([...selectedAddons, addon]);
  };

  const onRemoveAddon = (addon: IYachtAddon) => {
    const newSelectedAddons = selectedAddons.filter(
      ({ id }) => id !== addon.id
    );
    setSelectedAddons(newSelectedAddons);
  };

  const contextValue: IReservationContext = {
    reservationFlowStatus,
    currentReservationStep,
    availableYachts,
    onSelectYacht,
    selectedYacht,
    onPreviousStep,
    setReservationConfig,
    goToCheckout,
    displayPreReservationModal,
    onClosePreReservationModal,
    onSendPreReservationRequest,
    onProceedToPayment,
    displaySuccessModal,
    onFinishReservation,
    onSelectPaymentMethod,
    availablePaymentMethods,
    selectedPaymentMethodId,
    startHour,
    onStartHourChange,
    endHour,
    onEndHourChange,
    date,
    onDateChange,
    availableLocations,
    isFetchingAvailableLocations,
    hasErrorFetchingAvailableLocations,
    onSelectLocation,
    selectedLocationId: yachtsFilters.location_id,
    setYachtsLocationFilter,
    setYachtsPassengersNumberFilter,
    isFetchingAvailableYachts,
    hasErrorFetchingYachts,
    setYachtsSizeFilter,
    updateUserPaymentMethods,
    onRemovePaymentMethod,
    onSetPaymentMethodAsDefault,
    createdReserveId,
    availableStartHours,
    availableEndHours,
    blockedDates,
    onAddAddon,
    onRemoveAddon,
    setNumberOfPassengers,
    numberOfPassengers,
    bookingTotal,
    selectedAddons,
    setYachtsMinHoursFilter,
    selectedYachtAddonsImages,
  };

  return (
    <ReservationContext.Provider value={contextValue}>
      {children}
    </ReservationContext.Provider>
  );
};

export { ReservationProvider };
