import { createContext, Dispatch, SetStateAction } from "react";
import { IPaymentMethod } from "shared/types/payment";
import {
  EReservationFlowStatus,
  EReservationSteps,
} from "shared/types/reservation";
import type { IYacht } from "shared/types/yacht";
import type { ILocation } from "shared/types/location";
import { IYachtAddon } from "shared/types/yacht/IYachtAditional";
import { IImage } from "shared/types/common";

interface IReservationContext {
  reservationFlowStatus: EReservationFlowStatus;
  currentReservationStep: EReservationSteps;
  availableYachts: IYacht[];
  selectedYacht?: IYacht;
  onSelectYacht: (yacht: IYacht) => void;
  onPreviousStep: () => void;
  setReservationConfig: () => void;
  goToCheckout: () => void;
  displayPreReservationModal: boolean;
  onClosePreReservationModal: () => void;
  onSendPreReservationRequest: () => Promise<void>;
  onProceedToPayment: () => Promise<void>;
  displaySuccessModal: boolean;
  onFinishReservation: () => void;
  availablePaymentMethods: IPaymentMethod[];
  onSelectPaymentMethod: (id: string) => void;
  selectedPaymentMethodId?: string;
  startHour?: Date;
  onStartHourChange: (dateString: string) => void;
  endHour?: Date;
  onEndHourChange: (dateString: string) => void;
  date: Date;
  onDateChange: Dispatch<SetStateAction<Date>>;
  availableLocations: ILocation[];
  isFetchingAvailableLocations: boolean;
  hasErrorFetchingAvailableLocations: boolean;
  onSelectLocation: (locationId: number) => void;
  selectedLocationId: number | undefined;
  setYachtsLocationFilter: (locationId?: number) => void;
  setYachtsPassengersNumberFilter: (passengers_number?: number) => void;
  isFetchingAvailableYachts: boolean;
  hasErrorFetchingYachts: boolean;
  setYachtsSizeFilter: (size_id?: number) => void;
  updateUserPaymentMethods: () => Promise<void>;
  onSetPaymentMethodAsDefault: (paymentId: string) => Promise<void>;
  onRemovePaymentMethod: (paymentId: string) => Promise<void>;
  createdReserveId: number | undefined;
  availableStartHours: Date[];
  availableEndHours: Date[];
  blockedDates: Date[];
  onAddAddon: (addon: IYachtAddon) => void;
  onRemoveAddon: (addon: IYachtAddon) => void;
  setNumberOfPassengers: Dispatch<SetStateAction<number | undefined>>;
  numberOfPassengers?: number;
  bookingTotal: number;
  selectedAddons: IYachtAddon[];
  setYachtsMinHoursFilter: (min_hours?: number) => void;
  selectedYachtAddonsImages: IImage[];
}

const ReservationContext = createContext<IReservationContext | undefined>(
  undefined
);

export type { IReservationContext };
export default ReservationContext;
