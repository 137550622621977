import styled from "styled-components";
import { colors, fontSizes, fonts, devices } from "shared/constants/design";

const { text } = colors;
const { md } = fontSizes;
const { nexaRegular } = fonts;
const { desktop } = devices;

const MyMembershipInformationItem = styled.p`
  color: ${text};
  font-family: ${nexaRegular};
  font-size: ${md};
  font-weight: unset;
  letter-spacing: 1px;
  line-height: 22px;
  margin: 0;
  text-align: center;

  @media ${desktop} {
    text-align: left;
  }
`;

export { MyMembershipInformationItem };
