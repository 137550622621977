import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const ProfileDetailsContainer = styled.div`
  display: grid;
  grid-gap: 30px;
  height: fit-content;
  padding: 30px 20px;
  place-items: center;

  @media ${desktop} {
    justify-items: flex-end;
  }
`;

export { ProfileDetailsContainer };
