import styled from "styled-components";
import { isBrowserAndDesktopMedia } from "utils/css";

const Details = styled.div`
  display: grid;
  grid-gap: 20px;
  height: 100%;
  padding-top: 20px;
  overflow-y: auto;

  ${isBrowserAndDesktopMedia(`
    padding: 50px 0;
  `)}
`;

export { Details };
