import styled from "styled-components";
import { colors, borders, fontSizes, fonts } from "shared/constants/design";

const { background, textInverted } = colors;
const { borderRadiusLg } = borders;
const { nexaBold } = fonts;

const ProfileDetailsMembershipTag = styled.div`
  background-color: ${background};
  border-radius: ${borderRadiusLg};
  color: ${textInverted};
  font-family: ${nexaBold};
  font-size: ${fontSizes["2xsm"]};
  padding: 10px 25px;
  text-transform: uppercase;
`;

export { ProfileDetailsMembershipTag };
