import { forwardRef, ChangeEventHandler, FocusEventHandler } from "react";
import styled from "styled-components";
import { When } from "react-if";
import { ControlGroup } from "./ControlGroup";
import { ErrorMessage } from "./ErrorMessage";
import { colors, fontSizes, fonts, borders } from "shared/constants/design";

const { text, borderColor, blackShadowLight } = colors;
const { sm } = fontSizes;
const { nexaBold } = fonts;
const { borderRadius } = borders;

const BaseInput = styled.input`
  border-radius: ${borderRadius};
  border: 1px solid ${borderColor};
  box-shadow: 0px 3px 6px ${blackShadowLight};
  color: ${text};
  font-family: ${nexaBold};
  font-size: ${sm};
  letter-spacing: 0.63px;
  line-height: 20px;
  padding: 6px;
  text-align: center;

  &:disabled {
    cursor: not-allowed;
  }

  &:read-only {
    cursor: not-allowed;
  }
`;

interface IInput {
  name: string;
  placeholder?: string;
  hasError?: boolean;
  errorMessage?: string;
  type?: "text" | "password" | "email";
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  readOnly?: boolean;
}

const Input = forwardRef<HTMLInputElement, IInput>(
  (
    {
      hasError = false,
      errorMessage = "",
      type = "text",
      ...inputProps
    }: IInput,
    ref
  ) => (
    <ControlGroup>
      <BaseInput
        {...inputProps}
        ref={ref}
        type={type}
        className="form-control"
      />
      <When condition={hasError && errorMessage}>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </When>
    </ControlGroup>
  )
);

export { Input };
