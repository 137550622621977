import styled from "styled-components";
import { useTranslation } from "react-i18next";
import PaymentMethods, {
  IPaymentMethodItem,
} from "components/organisms/PaymentMethods";
import Text from "components/atoms/Text";
import getCardBrandIcon from "utils/getCardBrandIcon";
import useAuthContext from "context/AuthContext";
import useFetch from "hooks/useFetch";
import paymentService from "services/payment/PaymentService";
import useNotification from "hooks/useNotification";
import { ENotificationType } from "shared/types/notification";
import FetchSection from "components/molecules/FetchSection";

const MyPaymentMethodsSection = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  height: 100%;
  overflow: hidden;
  padding-top: 30px;
`;

const MyPaymentMethods = () => {
  const { t } = useTranslation();
  const { user, updateUserData } = useAuthContext();
  const notify = useNotification();

  const getOnRemovePaymentMethod = (paymentId: string) => async () => {
    try {
      await paymentService.deleteUserPaymentMethod({
        payment_id: paymentId,
      });
      await updateUserData();
    } catch (error: any) {
      notify({
        type: ENotificationType.ERROR,
        description: error.message,
      });
    }
  };

  const getOnSetPaymentMethodAsDefault = (paymentId: string) => async () => {
    try {
      await paymentService.setUserPaymentMethodAsDefault({
        payment_id: paymentId,
      });
      await updateUserData();
    } catch (error: any) {
      notify({
        type: ENotificationType.ERROR,
        description: error.message,
      });
    }
  };

  const getParsedPaymentMethodsList = async (): Promise<
    IPaymentMethodItem[]
  > => {
    const paymentMethods = await paymentService.getUserPaymentMethods();
    const parsedPaymentMethods: IPaymentMethodItem[] = paymentMethods.map(
      (paymentMethod) => ({
        id: paymentMethod.id,
        last4: paymentMethod.card.last4,
        brandIconUrl: getCardBrandIcon(paymentMethod.card.brand),
        isSelected: true,
        isDefault: user?.profile?.payment_key === paymentMethod.id,
        onSetAsDefault: getOnSetPaymentMethodAsDefault(paymentMethod.id),
        onRemove: getOnRemovePaymentMethod(paymentMethod.id),
      })
    );
    return parsedPaymentMethods;
  };

  const {
    data: paymentMethods,
    isFetching: isFetchingPaymentMethods,
    hasError: hasErrorFetchingPaymentMethods,
  } = useFetch<IPaymentMethodItem[]>({
    initialData: [],
    fetcher: getParsedPaymentMethodsList,
    dependencies: [user],
  });

  return (
    <MyPaymentMethodsSection>
      <Text>{t("profile.payment-methods.message")}</Text>
      <FetchSection
        isFetching={isFetchingPaymentMethods}
        hasError={hasErrorFetchingPaymentMethods}
        isEmpty={false}
        hasErrorMessage={t("profile.payment-methods.error")}
        isEmptyMessage=""
      >
        <PaymentMethods
          paymentMethods={paymentMethods}
          onUpdatePaymentMethods={updateUserData}
        />
      </FetchSection>
    </MyPaymentMethodsSection>
  );
};

export default MyPaymentMethods;
