import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { colors, fontSizes, fonts, devices } from "shared/constants/design";

const { text, primaryHover } = colors;
const { xsm } = fontSizes;
const { nexaBold } = fonts;
const { desktop } = devices;

const OpenFiltersButton = styled(Button)`
  background-color: transparent;
  border-radius: 5px;
  border: none;
  color: ${text};
  font-family: ${nexaBold};
  font-size: ${xsm};
  height: fit-content;
  letter-spacing: 0.29px;
  line-height: 22px;
  padding: 0;
  width: fit-content;

  &:hover {
    background-color: transparent;
    color: ${primaryHover};
  }

  &:focus {
    background-color: transparent;
    color: ${primaryHover};
  }

  @media ${desktop} {
    display: none;
  }
`;

export { OpenFiltersButton };
