import styled, { css } from "styled-components";

interface IReservationConfigContainer {
  shouldDisplayScroll?: boolean;
}

const ReservationConfigContainer = styled.div<IReservationConfigContainer>(
  ({ shouldDisplayScroll = false }) => css`
    display: ${shouldDisplayScroll ? "flex" : "grid"};
    flex-direction: ${shouldDisplayScroll ? "column" : "unset"};
    grid-gap: 10px;
    height: ${shouldDisplayScroll ? "100%" : "fit-content"};
    overflow-y: ${shouldDisplayScroll ? "auto" : "unset"};
    width: 100%;
  `
);

export { ReservationConfigContainer };
