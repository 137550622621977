import { useTranslation } from "react-i18next";
import { Switch, Case } from "react-if";
import useReservationContext from "context/ReservationContext";
import ShipLayout from "components/templates/ShipLayout";
import SelectYacht from "./SelectYacht";
import SelectAddons from "./SelectAddons";
import ReservationResume from "./ReservationResume";
import SelectLocation from "./SelectLocation";
import Checkout from "./Checkout";
import useNotification from "hooks/useNotification";
import { ENotificationType } from "shared/types/notification";
import {
  PreReservationModal,
  SuccessModal,
} from "components/molecules/reservation";
import { EReservationSteps } from "shared/types/reservation";
import CenteredCard from "components/atoms/CenteredCard";
import getReservePdfUrl from "utils/getReservePdfUrl";
import getFormattedDate from "utils/getFormattedDate";
import getFormattedHour from "utils/getFormattedHour";

const CurrentReservationStepView = () => {
  const { t } = useTranslation();
  const {
    currentReservationStep,
    onClosePreReservationModal,
    displayPreReservationModal,
    onSendPreReservationRequest,
    displaySuccessModal,
    onFinishReservation,
    createdReserveId,
    date,
    startHour,
  } = useReservationContext();
  const notify = useNotification();

  const onSendPreReservationRequestAndFinish = async () => {
    try {
      await onSendPreReservationRequest();
      notify({
        description: t("reservation.pre-reservation-success-message"),
      });
    } catch (error: any) {
      notify({
        type: ENotificationType.ERROR,
        description: error.message,
      });
    }
  };

  return (
    <>
      <Switch>
        <Case condition={currentReservationStep === EReservationSteps.LOCATION}>
          <CenteredCard maxWidth="1100px" width="fit-content">
            <SelectLocation />
          </CenteredCard>
        </Case>
        <Case condition={currentReservationStep === EReservationSteps.YACHT}>
          <ShipLayout>
            <SelectYacht />
          </ShipLayout>
        </Case>
        <Case condition={currentReservationStep === EReservationSteps.ADDONS}>
          <ShipLayout>
            <SelectAddons />
          </ShipLayout>
        </Case>
        <Case condition={currentReservationStep === EReservationSteps.RESUME}>
          <ShipLayout>
            <ReservationResume />
          </ShipLayout>
        </Case>
        <Case condition={currentReservationStep === EReservationSteps.CHECKOUT}>
          <ShipLayout>
            <Checkout />
          </ShipLayout>
        </Case>
      </Switch>
      <PreReservationModal
        isOpen={displayPreReservationModal}
        onClose={onClosePreReservationModal}
        onSubmit={onSendPreReservationRequestAndFinish}
      />
      <SuccessModal
        isOpen={displaySuccessModal}
        onContinue={onFinishReservation}
        date={`${getFormattedDate(date)} @ ${getFormattedHour(startHour)}`}
        shareUrl={getReservePdfUrl(createdReserveId)}
      />
    </>
  );
};

export default CurrentReservationStepView;
