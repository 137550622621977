import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const DesktopFiltersContainer = styled.div`
  display: none;

  @media ${desktop} {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    height: fit-content;
    padding: 30px 0;
  }
`;

export { DesktopFiltersContainer };
