import styled from "styled-components";

const AmenityItem = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: auto auto;
  height: fit-content;
  place-items: center;
  width: fit-content;
`;

export { AmenityItem };
