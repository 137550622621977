import styled from "styled-components";

const ProfileDesktopContentLayout = styled.div`
  display: flex;
  grid-gap: 50px;
  grid-template-columns: 350px 1fr;
  height: 100%;
  overflow: hidden;
`;

export { ProfileDesktopContentLayout };
