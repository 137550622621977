import styled from "styled-components";
import { fontSizes } from "shared/constants/design";

const { xsm } = fontSizes;

const Currency = styled.sup`
  font-size: ${xsm};
  margin-left: 5px;
  text-decoration: underline;
  text-transform: lowercase;
`;

export { Currency };
