import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation, TFunction } from "react-i18next";
import useAuthContext from "context/AuthContext";
import Link from "components/atoms/Link";
import CenteredCard from "components/atoms/CenteredCard";
import PrimaryButton from "components/atoms/PrimaryButton";
import {
  Input,
  Checkbox,
  Form,
  FormSection,
  Select,
} from "components/atoms/form";
import {
  RegisterFormTitle,
  RegisterSectionContainer,
} from "components/atoms/Register";
import useCountries from "hooks/useCountries";
import useCustomForm from "hooks/useForm";
import routes from "shared/constants/routes";
import assets from "shared/constants/assets";
import PageTemplate from "components/templates/PageTemplate";
import { useEffect } from "react";

const { policies } = routes;

interface IActivateFormData {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  country_id: number;
  partner_code: string;
  acceptPolicies?: boolean;
}

const getValidationSchema = (t: TFunction<"translation">) => {
  const isRequiredMessage = t("register.required.message");
  const isInvalidEmailMessage = t("register.invalid-email.message");
  const acceptPoliciesMessage = t("policies.confirm.error");

  return yup.object().shape({
    first_name: yup.string().required(isRequiredMessage),
    last_name: yup.string().required(isRequiredMessage),
    email: yup
      .string()
      .email(isInvalidEmailMessage)
      .required(isRequiredMessage),
    phone: yup.string().required(isRequiredMessage),
    country_id: yup
      .number()
      .typeError(isRequiredMessage)
      .required(isRequiredMessage),
    partner_code: yup.string().required(isRequiredMessage),

    acceptPolicies: yup.boolean().isTrue(acceptPoliciesMessage),
  });
};

const Activate = () => {
  const { activateUserMembership, user, updateUserData } = useAuthContext();
  const { t } = useTranslation();
  const {
    submit,
    isSubmitting,
    register,
    reset,
    formState: { errors },
  } = useCustomForm<IActivateFormData>({
    resolver: yupResolver(getValidationSchema(t)),
    onSuccess: updateUserData,
    successMessage: t("activate.success.message"),
    onSubmit: async ({ ...body }) => {
      await activateUserMembership(body);
    },
    errorMessage: t("activate.error.message"),
  });
  const { countries } = useCountries();

  const setDefaultValues = () => {
    if (user) {
      reset({
        first_name: user.first_name,
        partner_code: user.partner_code,
      });
    }
  };

  useEffect(() => {
    setDefaultValues();
  }, [user]);

  return (
    <PageTemplate>
      <CenteredCard maxWidth="600px">
        <RegisterSectionContainer>
          <img
            src={assets.yachtsLogoSmall}
            alt="Yacht Vibes logo"
            width="79.49"
            height="79.9"
          />
          <Form onSubmit={submit} noValidate>
            <RegisterFormTitle>{t("activate.title")}</RegisterFormTitle>
            <FormSection>
              <Input
                {...register("first_name")}
                placeholder={t("activate.fields.first-name.placeholder")}
                hasError={Boolean(errors.first_name)}
                errorMessage={errors.first_name?.message}
              />
              <Input
                {...register("last_name")}
                placeholder={t("activate.fields.last-name.placeholder")}
                hasError={Boolean(errors.last_name)}
                errorMessage={errors.last_name?.message}
              />
              <Input
                {...register("email")}
                type="email"
                placeholder={t("activate.fields.email.placeholder")}
                hasError={Boolean(errors.email)}
                errorMessage={errors.email?.message}
              />
              <Input
                {...register("phone")}
                placeholder={t("activate.fields.phone-number.placeholder")}
                hasError={Boolean(errors.phone)}
                errorMessage={errors.phone?.message}
              />
              <Select
                {...register("country_id")}
                placeholder={t("activate.fields.country.placeholder")}
                hasError={Boolean(errors.country_id)}
                errorMessage={errors.country_id?.message}
              >
                {countries.map(({ id, name }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </Select>
              <Input
                {...register("partner_code")}
                placeholder={t("activate.fields.partner-code.placeholder")}
                hasError={Boolean(errors.partner_code)}
                errorMessage={errors.partner_code?.message}
                readOnly
              />

              <Checkbox
                {...register("acceptPolicies")}
                hasError={Boolean(errors.acceptPolicies)}
                errorMessage={errors.acceptPolicies?.message}
              >
                {t("policies.confirm.read")}{" "}
                <Link to={policies}>{t("policies.confirm.read.link")}</Link>
              </Checkbox>
              <PrimaryButton type="submit" isLoading={isSubmitting}>
                {t("activate.fields.submit")}
              </PrimaryButton>
            </FormSection>
          </Form>
        </RegisterSectionContainer>
      </CenteredCard>
    </PageTemplate>
  );
};

export default Activate;
