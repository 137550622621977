import { useState } from "react";
import { useTranslation, TFunction } from "react-i18next";
import { When } from "react-if";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import ImageUploading, { ImageType } from "react-images-uploading";
import userService from "services/user";
import useAuthContext from "context/AuthContext";
import PrimaryButton from "components/atoms/PrimaryButton";
import SecondaryButton from "components/atoms/SecondaryButton";
import {
  ProfileDetailsTitle,
  ProfileDetailsImage,
  ProfileDetailsMembershipTag,
  EditProfileFormContainer,
  EditProfileAvatarSection,
  EditProfileFormComponent,
} from "components/atoms/Profile";
import { FormSection, Input, Select } from "components/atoms/form";
import useForm from "hooks/useForm";
import useCountries from "hooks/useCountries";
import isDefined from "utils/isDefined";
import assets from "shared/constants/assets";
import useUserMembershipTitle from "hooks/useUserMembershipTitle";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const { defaultUserImage } = assets;

interface IEditProfileFormData {
  first_name?: string;
  last_name?: string;
  country_id?: number;
  email?: string;
  birthdate?: Date;
  address?: string;
  phone?: number;
}

const getValidationSchema = (t: TFunction<"translation">) => {
  const isInvalidNumberMessage = t("profile.form.invalid-number");
  const isInvalidEmailMessage = t("forms.errors.invalid-email");
  const isInvalidDateMessage = t("profile.form.invalid-date");

  return yup.object().shape({
    first_name: yup.string(),
    last_name: yup.string(),
    email: yup.string().email(isInvalidEmailMessage),
    country_id: yup.number().typeError(isInvalidNumberMessage),
    address: yup.string(),
    phone: yup
      .number()
      .nullable()
      .transform((value: string, originalValue: string) =>
        originalValue?.trim() === "" ? null : value
      )
      .integer(isInvalidNumberMessage)
      .typeError(isInvalidNumberMessage),
    birthdate: yup
      .date()
      .nullable()
      .transform((value: string, originalValue: string) =>
        originalValue?.trim() === "" ? null : value
      )
      .typeError(isInvalidDateMessage),
  });
};

interface IEditProfileForm {
  onCancel: () => void;
}

const EditProfileForm = ({ onCancel }: IEditProfileForm) => {
  const { t } = useTranslation();
  const { user, updateUserData } = useAuthContext();
  const userAvatarUrl = user?.avatar || defaultUserImage;
  const [userAvatarImage, setUserAvatarImage] = useState<ImageType>({
    dataURL: userAvatarUrl,
  });

  const onProfileEditionSuccess = async () => {
    await updateUserData();
    onCancel();
  };

  const onProfileEditionSubmit = async ({
    phone,
    ...body
  }: IEditProfileFormData) => {
    await userService.updateUserData(user!.id, {
      ...body,

      phone: phone?.toString(),
    });
    if (userAvatarImage.file) {
      await userService.updateUserAvatar(user!.id, userAvatarImage.file);
    }
  };

  const {
    submit,
    isSubmitting,
    register,
    formState: { errors },
  } = useForm<IEditProfileFormData>({
    resolver: yupResolver(getValidationSchema(t)),
    successMessage: t("profile.form.success-message"),
    onSuccess: onProfileEditionSuccess,
    onSubmit: onProfileEditionSubmit,
    defaultValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      country_id: user?.country_id,
      address: user?.profile?.address,
      phone: user?.profile?.phone,
      birthdate: user?.profile?.birthdate
        ? moment(user?.profile?.birthdate).format("L")
        : undefined,
    },
  });
  const { countries, isFetchingCountries } = useCountries();
  const { userMembershipTitle } = useUserMembershipTitle();

  const onUserAvatarChange = (imagesList: ImageType[]) => {
    if (imagesList.length) {
      setUserAvatarImage(imagesList[0]);
    }
  };

  return (
    <When condition={isDefined(user)}>
      <EditProfileFormContainer>
        <ProfileDetailsTitle>{t("profile.form.title")}</ProfileDetailsTitle>
        <EditProfileAvatarSection>
          <ImageUploading
            value={[userAvatarImage]}
            maxNumber={2}
            onChange={onUserAvatarChange}
            acceptType={["jpg", "png", "jpeg"]}
          >
            {({ onImageUpload, imageList }) => (
              <ProfileDetailsImage
                src={
                  imageList.length
                    ? imageList[0].dataURL || userAvatarUrl
                    : userAvatarUrl
                }
                onClick={onImageUpload}
              />
            )}
          </ImageUploading>
          <ProfileDetailsMembershipTag>
            {userMembershipTitle}
          </ProfileDetailsMembershipTag>
        </EditProfileAvatarSection>
        <EditProfileFormComponent onSubmit={submit} noValidate>
          <FormSection>
            <Input
              {...register("first_name")}
              placeholder={t("profile.form.fields.first-name")}
              hasError={Boolean(errors.first_name)}
              errorMessage={errors.first_name?.message}
            />
            <Input
              {...register("last_name")}
              placeholder={t("profile.form.fields.last-name")}
              hasError={Boolean(errors.last_name)}
              errorMessage={errors.last_name?.message}
            />
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="user-email-input-disabled">
                  {t("profile.form.disabled.email.message")}
                </Tooltip>
              }
            >
              <Input
                {...register("email")}
                placeholder={t("forms.fields.email")}
                hasError={Boolean(errors.email)}
                errorMessage={errors.email?.message}
                disabled
              />
            </OverlayTrigger>
            <When condition={!isFetchingCountries}>
              <Select
                {...register("country_id")}
                placeholder={t("profile.form.country")}
                hasError={Boolean(errors.country_id)}
                errorMessage={errors.country_id?.message}
              >
                {countries.map(({ id, name }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </Select>
            </When>
            <Input
              {...register("phone")}
              placeholder={t("profile.form.phone")}
              hasError={Boolean(errors.phone)}
              errorMessage={errors.phone?.message}
            />
            <Input
              {...register("birthdate")}
              placeholder={t("profile.form.birthdate")}
              hasError={Boolean(errors.birthdate)}
              errorMessage={errors.birthdate?.message}
            />
            <Input
              {...register("address")}
              placeholder={t("profile.form.address")}
              hasError={Boolean(errors.address)}
              errorMessage={errors.address?.message}
            />
          </FormSection>
          <FormSection>
            <PrimaryButton type="submit" isLoading={isSubmitting}>
              {t("profile.form.submit")}
            </PrimaryButton>
            <SecondaryButton onClick={onCancel} isLoading={isSubmitting}>
              {t("profile.form.cancel")}
            </SecondaryButton>
          </FormSection>
        </EditProfileFormComponent>
      </EditProfileFormContainer>
    </When>
  );
};

export { EditProfileForm };
