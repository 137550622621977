import styled from "styled-components";
import { colors, fontSizes, fonts, devices } from "shared/constants/design";

const { text } = colors;
const { lg } = fontSizes;
const { nexaBold } = fonts;
const { desktop } = devices;

const MyMembershipSectionTitle = styled.h5`
  color: ${text};
  font-family: ${nexaBold};
  font-size: ${lg};
  font-weight: unset;
  letter-spacing: 1.35px;
  line-height: 30px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;

  @media ${desktop} {
    text-align: left;
  }
`;

export { MyMembershipSectionTitle };
