import { useTranslation } from "react-i18next";
import { When } from "react-if";
import useAuthContext from "context/AuthContext";
import IconButton from "components/atoms/IconButton";
import {
  DesktopNavbar,
  MobileNavbar,
  NavbarLinksDivider,
  ProfileIcon,
} from "components/atoms/Navbar";
import Sidebar from "components/molecules/Sidebar";
import NavbarContent, { StaticNavbarContent } from "./NavbarContent";
import useDisclosure from "hooks/useDisclosure";
import { EAvailableLanguages } from "shared/types/common";
import assets from "shared/constants/assets";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import userService from "services/user/UserService";

const { hamburgerMenu } = assets;

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const { isLoggedIn, user } = useAuthContext();
  const {
    isOpen: sidebarIsOpen,
    onToggle: onToggleSidebar,
    onClose: onCloseSidebar,
  } = useDisclosure();
  const location = useLocation();

  useEffect(() => {
    onCloseSidebar();
  }, [location]);

  const onEnLanguageSelected = () => {
    i18n.changeLanguage(EAvailableLanguages.EN);
    if (isLoggedIn && user?.id) {
      userService.updateUserLanguage({
        userId: user.id,
        locale: EAvailableLanguages.EN,
      });
    }
  };

  const onEsLanguageSelected = () => {
    i18n.changeLanguage(EAvailableLanguages.ES);
    if (isLoggedIn && user?.id) {
      userService.updateUserLanguage({
        userId: user.id,
        locale: EAvailableLanguages.ES,
      });
    }
  };

  const navbarContentProps = {
    usFlagIsDisabled: i18n.language !== EAvailableLanguages.EN,
    mxFlagIsDisabled: i18n.language !== EAvailableLanguages.ES,
    onEnLanguageSelected,
    onEsLanguageSelected,
  };

  return (
    <>
      <DesktopNavbar>
        <NavbarContent {...navbarContentProps} />
        <NavbarLinksDivider />
      </DesktopNavbar>
      <MobileNavbar>
        <IconButton
          onClick={onToggleSidebar}
          src={hamburgerMenu}
          alt={t("navbar.open-menu")}
          width="27"
          height="18"
        />
        <When condition={isLoggedIn}>
          <ProfileIcon />
        </When>
      </MobileNavbar>
      <Sidebar show={sidebarIsOpen} onHide={onToggleSidebar}>
        <NavbarContent {...navbarContentProps} />
      </Sidebar>
    </>
  );
};

const StaticNavbar = () => {
  const { t, i18n } = useTranslation();
  const {
    isOpen: sidebarIsOpen,
    onToggle: onToggleSidebar,
    onClose: onCloseSidebar,
  } = useDisclosure();
  const location = useLocation();

  useEffect(() => {
    onCloseSidebar();
  }, [location]);

  const onEnLanguageSelected = () => {
    i18n.changeLanguage(EAvailableLanguages.EN);
  };

  const onEsLanguageSelected = () => {
    i18n.changeLanguage(EAvailableLanguages.ES);
  };

  const navbarContentProps = {
    usFlagIsDisabled: i18n.language !== EAvailableLanguages.EN,
    mxFlagIsDisabled: i18n.language !== EAvailableLanguages.ES,
    onEnLanguageSelected,
    onEsLanguageSelected,
  };

  return (
    <>
      <DesktopNavbar>
        <StaticNavbarContent {...navbarContentProps} />
        <NavbarLinksDivider />
      </DesktopNavbar>
      <MobileNavbar>
        <IconButton
          onClick={onToggleSidebar}
          src={hamburgerMenu}
          alt={t("navbar.open-menu")}
          width="27"
          height="18"
        />
      </MobileNavbar>
      <Sidebar show={sidebarIsOpen} onHide={onToggleSidebar}>
        <StaticNavbarContent {...navbarContentProps} />
      </Sidebar>
    </>
  );
};

export { StaticNavbar };
export default Navbar;
