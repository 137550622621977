import styled from "styled-components";
import assets from "shared/constants/assets";

const { seaBackgroundJpg, seaBackgroundWebp } = assets;

const Image = styled.img`
  height: 100vh;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
`;

const Background = () => (
  <picture>
    <source srcSet={seaBackgroundWebp} />
    <Image src={seaBackgroundJpg} alt="" />
  </picture>
);

export { Background };
