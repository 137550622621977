import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const YachtCardMobileContent = styled.div`
  display: grid;
  grid-gap: 10px;
  padding: 15px;

  @media ${desktop} {
    display: none;
  }
`;

export { YachtCardMobileContent };
