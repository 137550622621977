import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation, TFunction } from "react-i18next";
import useAuthContext from "context/AuthContext";
import Link from "components/atoms/Link";
import Text from "components/atoms/Text";
import CenteredCard from "components/atoms/CenteredCard";
import PrimaryButton from "components/atoms/PrimaryButton";
import {
  Input,
  TwoColumns,
  Checkbox,
  Form,
  FormSection,
  FormFooter,
  Select,
} from "components/atoms/form";
import {
  RegisterFormTitle,
  RegisterSectionContainer,
} from "components/atoms/Register";
import useCountries from "hooks/useCountries";
import useCustomForm from "hooks/useForm";
import routes from "shared/constants/routes";
import assets from "shared/constants/assets";
import tenantService from "services/tenant";

const { home, login, policies } = routes;

interface IRegisterFormData {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  address: string;
  country_id: number;
  hear_about_us: string;
  rememberMe?: boolean;
  acceptPolicies?: boolean;
}

const minPasswordLength = 8;

const howYouHeardAboutUsOptions = [
  "Member Referral",
  "Received a Mailing",
  "Online Advertisement",
  "Social Media",
  "Event",
  "Previous User",
  "Other",
];

const getValidationSchema = (t: TFunction<"translation">) => {
  const isRequiredMessage = t("register.required.message");
  const isInvalidEmailMessage = t("register.invalid-email.message");
  const isInvalidPasswordMessage = t("register.invalid-password.message");
  const acceptPoliciesMessage = t("policies.confirm.error");

  return yup.object().shape({
    first_name: yup.string().required(isRequiredMessage),
    last_name: yup.string().required(isRequiredMessage),
    email: yup
      .string()
      .email(isInvalidEmailMessage)
      .required(isRequiredMessage),
    password: yup
      .string()
      .min(minPasswordLength, isInvalidPasswordMessage)
      .required(isRequiredMessage),
    country_id: yup
      .number()
      .typeError(isRequiredMessage)
      .required(isRequiredMessage),
    address: yup.string().required(isRequiredMessage),
    hear_about_us: yup.string().required(isRequiredMessage),
    rememberMe: yup.boolean(),
    acceptPolicies: yup.boolean().isTrue(acceptPoliciesMessage),
  });
};

const Register = () => {
  const history = useHistory();
  const { registerUser } = useAuthContext();
  const { t } = useTranslation();
  const {
    submit,
    isSubmitting,
    register,
    formState: { errors },
  } = useCustomForm<IRegisterFormData>({
    resolver: yupResolver(getValidationSchema(t)),
    onSuccess: () => history.push(home),
    successMessage: t("register.success.message"),
    onSubmit: async ({ rememberMe, ...body }) =>
      await registerUser(body, rememberMe),
  });
  const { countries } = useCountries();

  return (
    <CenteredCard maxWidth="600px">
      <RegisterSectionContainer>
        <img
          src={assets.yachtsLogoSmall}
          alt="Yacht Vibes logo"
          width="79.49"
          height="79.9"
        />
        <Form onSubmit={submit} noValidate>
          <RegisterFormTitle>{t("register.title")}</RegisterFormTitle>
          <FormSection>
            <Input
              {...register("first_name")}
              placeholder={t("register.fields.first-name.placeholder")}
              hasError={Boolean(errors.first_name)}
              errorMessage={errors.first_name?.message}
            />
            <Input
              {...register("last_name")}
              placeholder={t("register.fields.last-name.placeholder")}
              hasError={Boolean(errors.last_name)}
              errorMessage={errors.last_name?.message}
            />
            <Input
              {...register("email")}
              type="email"
              placeholder={t("register.fields.email.placeholder")}
              hasError={Boolean(errors.email)}
              errorMessage={errors.email?.message}
            />
            <Input
              {...register("password")}
              type="password"
              placeholder={t("register.fields.password.placeholder")}
              hasError={Boolean(errors.password)}
              errorMessage={errors.password?.message}
            />
            <Input
              {...register("address")}
              placeholder={t("register.fields.address.placeholder")}
              hasError={Boolean(errors.address)}
              errorMessage={errors.address?.message}
            />
            <Select
              {...register("country_id")}
              placeholder={t("register.fields.country.placeholder")}
              hasError={Boolean(errors.country_id)}
              errorMessage={errors.country_id?.message}
            >
              {countries.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </Select>
            <Select
              {...register("hear_about_us")}
              placeholder={t("register.fields.hear-about-us.placeholder")}
              hasError={Boolean(errors.hear_about_us)}
              errorMessage={errors.hear_about_us?.message}
            >
              {howYouHeardAboutUsOptions.map((option) => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </Select>
            <TwoColumns>
              <Checkbox
                {...register("acceptPolicies")}
                hasError={Boolean(errors.acceptPolicies)}
                errorMessage={errors.acceptPolicies?.message}
              >
                {t("policies.confirm.read")}{" "}
                <Link to={policies}>{t("policies.confirm.read.link")}</Link>
              </Checkbox>
            </TwoColumns>
          </FormSection>
          <FormSection>
            <TwoColumns>
              <Checkbox
                label={t("register.remember-me")}
                {...register("rememberMe")}
              />
              <PrimaryButton type="submit" isLoading={isSubmitting}>
                {t("register.fields.submit")}
              </PrimaryButton>
            </TwoColumns>
            {tenantService.getConfigs().flags.allowLogin && (
              <FormFooter>
                <Text>{t("register.existing-account")}</Text>
                <Link to={login}>{t("register.go-to-login")}</Link>
              </FormFooter>
            )}
          </FormSection>
        </Form>
      </RegisterSectionContainer>
    </CenteredCard>
  );
};

export default Register;
