import styled from "styled-components";
import {
  isBrowserAndDesktopMedia,
  isBrowserAndDesktopMdMedia,
} from "utils/css";

const CheckoutContentLayout = styled.div`
  display: grid;
  grid-gap: 40px;
  overflow: hidden;

  ${isBrowserAndDesktopMedia(`
    grid-template-columns: 1fr 55%;
  `)}

  ${isBrowserAndDesktopMdMedia(`
    grid-gap: 60px;
  `)}
`;

export { CheckoutContentLayout };
