import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation, TFunction } from "react-i18next";
import useAuthContext from "context/AuthContext";
import Link from "components/atoms/Link";
import CenteredCard from "components/atoms/CenteredCard";
import Text from "components/atoms/Text";
import PrimaryButton from "components/atoms/PrimaryButton";
import {
  Input,
  TwoColumns,
  Checkbox,
  Form,
  FormSection,
  FormContainer,
  FormLogo,
  FormTitle,
  FormFooter,
} from "components/atoms/form";
import { LoginSectionContainer } from "components/atoms/Login";
import useForm from "hooks/useForm";
import routes from "shared/constants/routes";
import tenantService from "services/tenant";

const { home, resetPassword } = routes;

interface ILoginFormData {
  email: string;
  password: string;
  rememberMe?: boolean;
}

const getValidationSchema = (t: TFunction<"translation">) => {
  const isRequiredMessage = t("register.required.message");
  const isInvalidEmailMessage = t("register.invalid-email.message");

  return yup.object().shape({
    email: yup
      .string()
      .email(isInvalidEmailMessage)
      .required(isRequiredMessage),
    password: yup.string().required(isRequiredMessage),
    rememberMe: yup.boolean(),
  });
};

const Login = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isLoggedIn, loginUser } = useAuthContext();
  const {
    submit,
    isSubmitting,
    register,
    formState: { errors },
  } = useForm<ILoginFormData>({
    resolver: yupResolver(getValidationSchema(t)),
    onSubmit: async ({ rememberMe, ...body }) =>
      await loginUser(body, rememberMe),
    onSuccess: () => history.push(home),
    successMessage: t("login.form.success.message"),
  });

  useEffect(() => {
    if (isLoggedIn) {
      history.push(home);
    }
  }, []);

  return (
    <CenteredCard>
      <LoginSectionContainer>
        <FormContainer>
          <FormLogo />
          <Form onSubmit={submit}>
            <FormTitle>{t("login.form.title")}</FormTitle>
            <FormSection>
              <Input
                placeholder={t("login.form.field.email")}
                {...register("email")}
                hasError={Boolean(errors.email)}
                errorMessage={errors.email?.message}
                type="email"
              />
              <Input
                placeholder={t("login.form.field.password")}
                {...register("password")}
                hasError={Boolean(errors.password)}
                errorMessage={errors.password?.message}
                type="password"
              />
            </FormSection>
            <FormSection>
              <TwoColumns>
                <Checkbox
                  label={t("login.form.field.remember-me")}
                  {...register("rememberMe")}
                />
                <PrimaryButton type="submit" isLoading={isSubmitting}>
                  {t("login.form.field.submit")}
                </PrimaryButton>
              </TwoColumns>
            </FormSection>
          </Form>
        </FormContainer>
        <FormSection>
          {tenantService.getConfigs().flags.allowPasswordRecovery && (
            <Link to={resetPassword}>{t("login.form.forgot-password")}</Link>
          )}
          {tenantService.getConfigs().flags.allowUserRegister && (
            <FormFooter>
              <Text>{t("login.form.no-account")}</Text>
              <Link to="/register">{t("login.form.register-here")}</Link>
            </FormFooter>
          )}
        </FormSection>
      </LoginSectionContainer>
    </CenteredCard>
  );
};

export default Login;
