import styled, { css } from "styled-components";
import { devices } from "shared/constants/design";

const { tabletSmall } = devices;

interface IToastContainer {
  show: boolean;
}

const ToastContainer = styled.div<IToastContainer>(
  ({ show }) => css`
    display: ${show ? "block" : "none"};
    left: 50%;
    padding-top: 30px;
    position: fixed;
    top: 0;
    transform: translate(-50%, 0);
    z-index: 1061;

    @media ${tabletSmall} {
      left: unset;
      padding: 30px;
      right: 0;
      transform: none;
    }
  `
);

export { ToastContainer };
