import styled, { css } from "styled-components";
import { colors, borders } from "shared/constants/design";

const { text, white } = colors;
const { borderRadiusLg } = borders;

interface ICreditCardActionArea {
  isSelected?: boolean;
}

const CreditCardActionArea = styled.button<ICreditCardActionArea>(
  ({ isSelected = false }) => {
    const selectedBorder = `1px solid ${text}`;
    const border = isSelected ? selectedBorder : "1px solid transparent";

    return css`
      background: ${white};
      border-radius: ${borderRadiusLg};
      border: ${border};
      box-shadow: 3px 3px 18px #00000021;
      display: grid;
      height: 130px;
      place-items: center;

      &:hover {
        border: ${selectedBorder};
      }
    `;
  }
);

export type { ICreditCardActionArea };
export { CreditCardActionArea };
