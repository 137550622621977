import { createContext } from "react";
import type {
  IRegisterUser,
  ILoginUser,
  IChangePassword,
} from "shared/types/auth";
import { IActivateUser } from "shared/types/auth/IActivateUser";
import type { IUser } from "shared/types/user";

interface IAuthContext {
  isLoggedIn: boolean;
  user?: IUser;
  registerUser: (
    body: Omit<IRegisterUser, "role" | "partner_status">,
    rememberMe?: boolean
  ) => Promise<void>;
  activateUserMembership: (body: IActivateUser) => Promise<void>;
  loginUser: (body: ILoginUser, rememberMe?: boolean) => Promise<void>;
  sendResetPasswordTokenToEmail: (email: string) => Promise<void>;
  changePassword: (body: IChangePassword) => Promise<void>;
  updateUserData: () => Promise<void>;
  logOutUser: () => void;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

export default AuthContext;
export type { IAuthContext };
