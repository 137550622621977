import { forwardRef } from "react";
import { ISelect, Select } from "components/atoms/form";
import getDateStringValue from "utils/getDateStringValue";
import getFormattedHour from "utils/getFormattedHour";

interface IHourSelector extends Omit<ISelect, "children"> {
  availableHours: Date[];
}

const HourSelector = forwardRef<HTMLSelectElement, IHourSelector>(
  ({ availableHours, ...selectProps }, ref) => (
    <Select {...selectProps} disabled={!availableHours.length} ref={ref}>
      {availableHours.map((hour, index) => (
        <option value={getDateStringValue(hour)} key={getDateStringValue(hour)}>
          {getFormattedHour(hour)}
        </option>
      ))}
    </Select>
  )
);

export default HourSelector;
