const profile = "/profile";
const userReservations = `${profile}/reservations`;

const routes = {
  home: "/",
  login: "/login",
  register: "/register",
  experiences: "https://www.yachtvibes.com/#Experiences",
  yachts: "/yachts",
  memberships: "https://www.yachtvibes.com/memberships#Contact",
  book: "/book",
  resetPassword: "/reset-password",
  profile,
  userReservations,
  userPaymentMethods: `${profile}/payment-methods`,
  userReservationDetails: (reservationId: number) =>
    `${userReservations}/${reservationId}`,
  policies: "/terms-and-conditions",
};

export default routes;
