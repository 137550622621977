import styled from "styled-components";
import { colors, borders, devices } from "shared/constants/design";

const { backgroundSecondary, white } = colors;
const { borderRadiusLg } = borders;
const { desktop } = devices;

const PricingSection = styled.div`
  background-color: ${backgroundSecondary};
  border-radius: ${borderRadiusLg};
  color: ${white};
  display: grid;
  grid-gap: 30px;
  justify-items: center;
  padding: 20px;

  @media ${desktop} {
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
    padding: 30px;
  }
`;

export { PricingSection };
