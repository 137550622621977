import styled from "styled-components";

const TwoColumns = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap-reverse;
  gap: 20px;
`;

export { TwoColumns };
