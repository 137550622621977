import styled from "styled-components";
import {
  fonts,
  fontSizes,
  fontWeights,
  colors,
  devices,
} from "shared/constants/design";

const { primary } = colors;
const { semiBold } = fontWeights;
const { md, lg } = fontSizes;
const { conthrax } = fonts;
const { desktop } = devices;

const PoliciesTitle = styled.h1`
  color: ${primary};
  font: italic normal ${semiBold} ${md} ${conthrax};
  margin: 0;
  text-transform: uppercase;
  height: fit-content;
  padding-bottom: 0.5rem;
  padding-top: 1rem;

  @media ${desktop} {
    font-size: ${lg};
  }
`;

export { PoliciesTitle };
