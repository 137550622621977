import styled from "styled-components";
import { colors, fonts, fontSizes } from "shared/constants/design";

const { text } = colors;
const { nexaBold } = fonts;
const { md } = fontSizes;

const CreditCardText = styled.p`
  color: ${text};
  font-family: ${nexaBold};
  font-size: ${md};
  letter-spacing: 0.63px;
  line-height: 18px;
  margin: 0;
  padding-right: 20px;
  text-align: right;
`;

export { CreditCardText };
