import sizeService from "services/size";
import useFetch from "./useFetch";
import type { IYachtSize } from "shared/types/yacht";

const useYachtSizes = () => {
  const {
    data: yachtSizes,
    isFetching: isFetchingYachtSizes,
    hasError: hasErrorFetchingYachtSizes,
  } = useFetch<IYachtSize[]>({
    initialData: [],
    fetcher: sizeService.getYachtSizes,
  });

  return {
    yachtSizes,
    isFetchingYachtSizes,
    hasErrorFetchingYachtSizes,
  };
};

export default useYachtSizes;
