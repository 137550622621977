import * as rdd from "react-device-detect";
import { devices } from "shared/constants/design";

// @ts-ignore this line is for testing, comment it before shipping
// rdd.isMobile = true;

const { desktop } = devices;

const isMobileAndDesktopMedia = (css: string) =>
  rdd.isMobile
    ? `@media ${desktop} {
        ${css}
      }`
    : "";

export { isMobileAndDesktopMedia };
