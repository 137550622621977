import { breakpoints } from "./breakpoints";

const { md, lg, xl } = breakpoints;

const devices = {
  tabletSmall: `(min-width: ${md})`,
  tabletLg: `(min-width: ${lg})`,
  desktop: `(min-width: ${xl})`,
  desktopMd: `(min-width: ${breakpoints["2xl"]})`,
  desktopXl: `(min-width: ${breakpoints["3xl"]})`,
};

export { devices };
