import { useContext } from "react";
import ReservationContext from "./ReservationContext";

const useReservationContext = () => {
  const contextValue = useContext(ReservationContext);

  if (contextValue === undefined) {
    throw new Error(
      "You must use useReservationContext within a ReservationProvider"
    );
  }

  return contextValue;
};

export default useReservationContext;
