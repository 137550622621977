import http from "services/http";
import type {
  IRegisterUser,
  IAuthResponse,
  ILoginUser,
  IChangePassword,
} from "shared/types/auth";
import { IActivateUser } from "shared/types/auth/IActivateUser";
import { IUser } from "shared/types/user";
import mixpanelService from "services/mixpanel";

const AuthService = () => {
  const endpointName = "/auth";

  const register = async (body: IRegisterUser) => {
    try {
      const response = await http.post<IAuthResponse>(
        `${endpointName}/register`,
        body
      );

      const userInfo = response.user;
      const hearAbout = response.profile.hear_about_us;
      const userFullName = userInfo.first_name + " " + userInfo.last_name;

      mixpanelService.track.userRegistered({
        fullName: userFullName,
        email: userInfo.email,
        country: userInfo.country?.name || "Not specified",
        hearAboutUs: hearAbout || "Not specified",
      });
      mixpanelService.track.userLoggedIn({
        fullName: userFullName,
        email: userInfo.email,
        country: userInfo.country?.name || "Not specified",
        membership: userInfo.membership?.name || "Free",
        isAutoLoginWithToken: false,
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

  const login = async (body: ILoginUser) => {
    try {
      const response = await http.post<IAuthResponse>(
        `${endpointName}/login`,
        body
      );

      const userResponse = response.user;
      const userFullName =
        userResponse.first_name + " " + userResponse.last_name;
      mixpanelService.track.userLoggedIn({
        fullName: userFullName,
        email: userResponse.email,
        country: userResponse.country?.name || "Not specified",
        membership: userResponse.membership?.name || "Free",
        isAutoLoginWithToken: false,
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const sendResetPasswordTokenToEmail = (email: string) => {
    http.post<void>(`${endpointName}/reset`, { email }).then(() => {
      mixpanelService.track.passwordRecoveryRequested(email);
    });
  };

  const changePassword = (body: IChangePassword) =>
    http.post<void>(`${endpointName}/reset`, body).then(() => {
      mixpanelService.track.passwordRecovered();
    });

  const loginWithToken = async (queryToken: string) => {
    const config = {
      headers: {
        Authorization: `Bearer ${queryToken}`,
      },
    };

    try {
      const response = await http.post<IAuthResponse>(
        `${endpointName}/token-login`,
        {},
        config
      );

      return response;
    } catch (error) {
      throw error;
    }
  };

  const activateMembership = async (body: IActivateUser) => {
    try {
      const response = await http.post<IAuthResponse>(
        `${endpointName}/activate-membership`,
        body
      );

      const { user } = response;
      const userFullName = user.first_name + " " + user.last_name;
      mixpanelService.track.userMembershipActivated({
        fullName: userFullName,
        email: user.email,
        country: user.country?.name || "Not specified",
      });

      mixpanelService.track.userLoggedIn({
        fullName: userFullName,
        email: user.email,
        country: user.country?.name || "Not specified",
        membership: user.membership?.name || "Free",
        isAutoLoginWithToken: true,
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

  return {
    register,
    activateMembership,
    login,
    sendResetPasswordTokenToEmail,
    changePassword,
    loginWithToken,
  };
};

export default AuthService();
