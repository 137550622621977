import styled from "styled-components";

const FormContainer = styled.div`
  display: grid;
  grid-gap: 70px;
  height: fit-content;
  place-items: center;
  width: 100%;
`;

export { FormContainer };
