import styled from "styled-components";
import { colors } from "shared/constants/design";

const { white } = colors;

const AnnotationsSection = styled.div`
  color: ${white};
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  grid-template-rows: auto 1fr;
  overflow-y: auto;
  width: 100%;
`;

export { AnnotationsSection };
