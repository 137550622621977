import styled from "styled-components";
import { devices } from "shared/constants/design";

const {desktop} = devices

const MyBookingsNavigation = styled.div`
  align-items: baseline;
  display: grid;
  grid-template-columns: auto 1fr auto;

  @media ${desktop} {
    grid-gap: 30px;
  }
`;

export { MyBookingsNavigation };
