import styled from "styled-components";
import { colors, fonts, devices } from "shared/constants/design";

const { text } = colors;
const { nexaBold } = fonts;
const { desktop } = devices;

const PoliciesSectionTitle = styled.h5`
  color: ${text};
  font-family: ${nexaBold};
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 1.35px;
  line-height: 30px;
  margin: 0;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  text-decoration: underline;

  @media ${desktop} {
    font-size: 20px;
  }
`;

export { PoliciesSectionTitle };
