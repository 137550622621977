import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation, TFunction } from "react-i18next";
import useAuthContext from "context/AuthContext";
import {
  Input,
  Form,
  FormSection,
  FormContainer,
  FormLogo,
  FormTitle,
  FormDescription,
} from "components/atoms/form";
import PrimaryButton from "components/atoms/PrimaryButton";
import useForm from "hooks/useForm";

interface ISendTokenEmailFormData {
  email: string;
}

const getValidationSchema = (t: TFunction<"translation">) => {
  const isRequiredMessage = t("forms.errors.required");
  const isInvalidEmailMessage = t("forms.errors.invalid-email");

  return yup.object().shape({
    email: yup
      .string()
      .email(isInvalidEmailMessage)
      .required(isRequiredMessage),
  });
};

const SendResetTokenToEmailForm = () => {
  const { t } = useTranslation();
  const { sendResetPasswordTokenToEmail } = useAuthContext();
  const {
    submit,
    isSubmitting,
    register,
    formState: { errors },
  } = useForm<ISendTokenEmailFormData>({
    resolver: yupResolver(getValidationSchema(t)),
    onSubmit: async ({ email }) => await sendResetPasswordTokenToEmail(email),
    successMessage: t("reset-password.email-form.success-message"),
  });

  return (
    <FormContainer>
      <FormLogo />
      <Form onSubmit={submit}>
        <FormTitle>{t("reset-password.email-form.title")}</FormTitle>
        <FormSection>
          <FormDescription>
            {t("reset-password.email-form-description")}
          </FormDescription>
          <Input
            placeholder={t("forms.fields.email")}
            {...register("email")}
            hasError={Boolean(errors.email)}
            errorMessage={errors.email?.message}
            type="email"
          />
        </FormSection>
        <PrimaryButton type="submit" isLoading={isSubmitting}>
          {t("reset-password.email-form.field.submit")}
        </PrimaryButton>
      </Form>
    </FormContainer>
  );
};

export default SendResetTokenToEmailForm;
