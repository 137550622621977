import styled from "styled-components";
import { borders } from "shared/constants/design";
import IconButton from "../IconButton";
import assets from "shared/constants/assets";

const { borderRadiusLg } = borders;
const { share } = assets;

const YachtReserveCardCoverContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
`;

const YachtReserveCardCoverImage = styled.img`
  border-radius: ${borderRadiusLg} ${borderRadiusLg} 0 0;
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

const YachtReserveCardShareContainer = styled.div`
  bottom: 15px;
  position: absolute;
  right: 15px;
`;

interface IYachtReserveCardCover {
  src: string;
  alt: string;
  onShare?: () => void;
}

const YachtReserveCardCover = ({
  onShare,
  ...imageProps
}: IYachtReserveCardCover) => (
  <YachtReserveCardCoverContainer>
    <YachtReserveCardCoverImage {...imageProps} />
    <YachtReserveCardShareContainer>
      <IconButton
        src={share}
        alt="share"
        width="25px"
        height="25px"
        onClick={onShare}
      />
    </YachtReserveCardShareContainer>
  </YachtReserveCardCoverContainer>
);

export { YachtReserveCardCover };
