import styled from "styled-components";

const CreditCardBrandIcon = styled.img`
  height: 30px;
  object-fit: contain;
  object-position: center;
  width: 50px;
`;

export { CreditCardBrandIcon };
