import styled from "styled-components";

const PaymentMethodsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 30px;
  width: 100%;
`;

export { PaymentMethodsContainer };
