import { useTranslation } from "react-i18next";

const useTranslatedProperty = () => {
  const { i18n } = useTranslation();

  const getTranslation = (
    propertyName: string,
    object: any,
    formatToHTML?: boolean
  ) => {
    if (object) {
      if (formatToHTML && object[`${propertyName}_${i18n.language}`]) {
        return object[`${propertyName}_${i18n.language}`].replace(
          /\n/g,
          "<br>"
        );
      }
      return object[`${propertyName}_${i18n.language}`];
    }
    return "";
  };

  return getTranslation;
};

export default useTranslatedProperty;
