import styled, { css } from "styled-components";

interface ILocationsList {
  alignItems?: string;
  gap?: string;
}

const LocationsList = styled.div<ILocationsList>(
  ({ alignItems = "unset", gap = "20px" }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: ${gap};
    justify-content: center;
    align-items: ${alignItems};
    width: 100%;
  `
);

export { LocationsList };
