import styled from "styled-components";
import { colors, fontSizes, fonts, devices } from "shared/constants/design";

const { textInverted } = colors;
const { md } = fontSizes;
const { nexaRegular } = fonts;
const { desktop } = devices;

const ProfileDetailText = styled.p`
  color: ${textInverted};
  font-family: ${nexaRegular};
  font-size: ${md};
  letter-spacing: 0.81px;
  line-height: 24px;
  margin: 0;
  text-align: center;

  @media ${desktop} {
    text-align: right;
  }
`;

export { ProfileDetailText };
