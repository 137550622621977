import { ITenantConfig } from "shared/types/tenant/ITenantConfig";

const yachtVibesConfig: ITenantConfig = {
  colors: {
    primary: "#00B8B6",
    secondary: "#191919cc",
    tertiary: "#948418",
    background: "#191919",
    primaryOpaque: "#7FDCD9",
    primaryBorderColor: "#48A29F",
    primaryHover: "#02817f",
  },
  fonts: {
    primary: "nexaRegular",
    secondary: "nexaBold",
  },
  logos: {
    smallSrc: "/assets/yachts-logo-small.svg",
    largeSrc: "/assets/yachts-logo.svg",
  },
  flags: {
    allowUserRegister: true,
    allowPasswordRecovery: true,
    allowLogin: true,
    allowPWAAttributes: true,
    allowAutoLogin: false,
    allowActivateMembership: false,
  },
  pageAttributes: {
    title: "YACHTVIBES | Sealux Rentals",
    faviconSrc: "%PUBLIC_URL%/favicon.ico",
  },
  pwaAttributes: {
    manifestPath: "/manifest.json",
  },
  queryParams: {
    tokenParam: "",
  },
  links: {
    tenantLink: "",
  },
};

export { yachtVibesConfig };
