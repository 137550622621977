enum EAvailableLanguages {
  ES = "es",
  EN = "en",
}

const mapLocaleToLanguage = {
  es_MX: EAvailableLanguages.ES,
  en_US: EAvailableLanguages.EN,
};

export { EAvailableLanguages, mapLocaleToLanguage };
