import { useTranslation } from "react-i18next";
import { Prompt } from "react-router";
import useAuthContext from "context/AuthContext";
import useReservationContext from "context/ReservationContext";
import {
  YachtCard,
  YachtsListFilters,
} from "components/molecules/reservation/SelectYacht";
import Sidebar from "components/molecules/Sidebar";
import {
  YachtsListContainer,
  YachtsListTitle,
  YachtsListItems,
  DesktopFiltersContainer,
  MobileFiltersContainer,
  OpenFiltersButton,
  FiltersTitle,
  ColumnNames,
  ColumnName,
  ListHeader,
  ListHeaderDivider,
} from "components/atoms/reservation/SelectYacht";
import useDisclosure from "hooks/useDisclosure";
import FetchSection from "components/molecules/FetchSection";
import getUserMembershipPrice, {
  getFreeMembershipPrice,
} from "utils/getUserMembershipPrice";

const SelectYacht = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const {
    availableYachts,
    onSelectYacht,
    isFetchingAvailableYachts,
    hasErrorFetchingYachts,
  } = useReservationContext();
  const {
    isOpen: shouldDisplayMobileFilters,
    onToggle: toggleDisplayMobileFilters,
  } = useDisclosure();
  const availableYachtsIsEmpty = availableYachts.length === 0;

  return (
    <>
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
          }

          return location.pathname === "/"
            ? true
            : `Si sales de esta página perderás la información que has introducido. ¿Estás seguro de que quieres salir?`;
        }}
      />
      <YachtsListContainer>
        <DesktopFiltersContainer>
          <YachtsListFilters />
        </DesktopFiltersContainer>
        <div>
          <ListHeader>
            <YachtsListTitle>
              {t("yachts.list.title")} ({availableYachts.length})
            </YachtsListTitle>
            <ColumnNames>
              <ColumnName>{t("yachts.list.headers.regular-rate")}</ColumnName>
              <ColumnName>
                {t("yachts.list.headers.membership-rate")}
              </ColumnName>
            </ColumnNames>
          </ListHeader>
          <ListHeaderDivider />
        </div>
        <OpenFiltersButton onClick={toggleDisplayMobileFilters}>
          {t("yachts.list.filters.filters-label")}
        </OpenFiltersButton>
        <Sidebar
          show={shouldDisplayMobileFilters}
          onHide={toggleDisplayMobileFilters}
        >
          <MobileFiltersContainer>
            <FiltersTitle>
              {t("yachts.list.filters.filters-label")}
            </FiltersTitle>
            <YachtsListFilters />
          </MobileFiltersContainer>
        </Sidebar>
        <FetchSection
          isFetching={isFetchingAvailableYachts}
          hasError={hasErrorFetchingYachts}
          isEmpty={availableYachtsIsEmpty}
          hasErrorMessage={t("yachts.list.error")}
          isEmptyMessage={t("yachts.list.empty")}
        >
          <YachtsListItems>
            {availableYachts.map((yacht) => {
              const {
                name,
                passengers_number,
                default_image,
                model,
                location,
                size,
                prices,
              } = yacht;
              const onClick = () => onSelectYacht(yacht);

              return (
                <YachtCard
                  key={name}
                  yachtName={name}
                  yachtModelName={model}
                  size={size?.number}
                  measurementUnit={size?.measurement_unity}
                  passengersCount={passengers_number}
                  locationName={location?.name}
                  membershipRate={getUserMembershipPrice(
                    prices,
                    user?.membership
                  )}
                  regularRate={getFreeMembershipPrice(prices)}
                  imageUrl={default_image?.url}
                  onClick={onClick}
                />
              );
            })}
          </YachtsListItems>
        </FetchSection>
      </YachtsListContainer>
    </>
  );
};

export default SelectYacht;
