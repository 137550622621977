import environment from "shared/constants/environment";
import { tenantsConfigs } from "./configs/tenantsConfigs";
import { ITenantConfig } from "shared/types/tenant/ITenantConfig";

const TenantService = () => {
  let tenantConfiguration: ITenantConfig;
  const tenantId = environment.tenantId;

  const init = () => {
    // @ts-ignore - We know this can return undefined, so we use a fallback value
    tenantConfiguration = tenantsConfigs[tenantId] || tenantsConfigs.default;
  };

  const getConfigs = (): ITenantConfig => {
    return tenantConfiguration;
  };

  const getId = () => {
    return tenantId;
  };

  return { getConfigs, getId, init };
};

const tenantService = TenantService();

export default tenantService;
