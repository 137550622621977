import { FC } from "react";
import styled from "styled-components";
import {
  colors,
  fontWeights,
  fontSizes,
  fonts,
  devices,
} from "shared/constants/design";

const { text } = colors;
const { semiBold } = fontWeights;
const { lg, xl } = fontSizes;
const { conthrax } = fonts;
const { desktop, desktopXl } = devices;

const MyMembershipTitleText = styled.h3`
  color: ${text};
  font: italic normal ${semiBold} ${lg} ${conthrax};
  text-decoration: none;
  text-transform: uppercase;
  line-height: 30px;
  text-align: center;

  @media ${desktop} {
    font-size: ${xl};
    line-height: 34px;
    text-align: left;
  }

  @media ${desktopXl} {
    font-size: ${fontSizes["3xl"]};
    line-height: 54px;
  }
`;

const MyMembershipTitleContainer = styled.div`
  height: fit-content;
`

const MyMembershipTitle: FC = ({children}) => (
  <MyMembershipTitleContainer>
    <MyMembershipTitleText>{children}</MyMembershipTitleText>
  </MyMembershipTitleContainer>
)

export { MyMembershipTitle };
