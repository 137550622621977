import CenteredCard from "components/atoms/CenteredCard";
import {
  PoliciesContainer,
  PoliciesTitle,
  PoliciesSubtitle,
  PoliciesSectionTitle,
} from "components/atoms/Policies";
import Text from "components/atoms/Text";
import { useTranslation, Trans } from "react-i18next";

const Policies = () => {
  const { t } = useTranslation();

  return (
    <CenteredCard maxWidth="70ch">
      <PoliciesContainer>
        <div>
          <Text>{t("terms.subtitle")}</Text>
        </div>
        <div>
          <PoliciesTitle>{t("terms.title")}</PoliciesTitle>
          <Text>{t("terms.intro")}</Text>
        </div>

        <div>
          <PoliciesSectionTitle>
            {t("terms.definitions.title")}
          </PoliciesSectionTitle>
          <Text>
            <Trans i18nKey="terms.definitions.paragraph-1" />
          </Text>
          <Text>
            <Trans i18nKey="terms.definitions.paragraph-2" />
          </Text>
          <Text>
            <Trans i18nKey="terms.definitions.paragraph-3" />
          </Text>
          <Text>
            <Trans i18nKey="terms.definitions.paragraph-4" />
          </Text>
          <Text>
            <Trans i18nKey="terms.definitions.paragraph-5" />
          </Text>
          <Text>
            <Trans i18nKey="terms.definitions.paragraph-6" />
          </Text>
          <Text>
            <Trans i18nKey="terms.definitions.paragraph-7" />
          </Text>
        </div>

        <div>
          <PoliciesSectionTitle>
            {t("terms.statements.title")}
          </PoliciesSectionTitle>
          <PoliciesSubtitle>
            {t("terms.statements.general.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.statements.general.content")}</Text>
        </div>

        <div>
          <PoliciesSectionTitle>
            {t("terms.rights-obligations.title")}
          </PoliciesSectionTitle>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.payment.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.payment.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.fees.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.fees.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.services.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.services.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.reservation.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.reservation.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.policies.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.policies.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.cancellations.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.cancellations.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.access-codes.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.access-codes.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.suspension.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.suspension.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.insurance.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.insurance.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.marketing.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.marketing.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.reactivation.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.reactivation.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.quality.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.quality.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.privacy.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.privacy.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.rescission.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.rescission.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.notifications.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.notifications.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.law.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.law.content")}</Text>
          <PoliciesSubtitle>
            {t("terms.rights-obligations.protection.title")}
          </PoliciesSubtitle>
          <Text>{t("terms.rights-obligations.protection.content")}</Text>
        </div>
      </PoliciesContainer>
    </CenteredCard>
  );
};

export default Policies;
