import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 45px;

  @media ${desktop} {
    flex-direction: row;
  }
`;

export { ActionsContainer };
