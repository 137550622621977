import { ITenantConfig } from "shared/types/tenant/ITenantConfig";
import { yachtVibesConfig } from "./yachtVibesConfig";

const exoticTravelersConfig: ITenantConfig = {
  ...yachtVibesConfig,
  flags: {
    allowUserRegister: false,
    allowPasswordRecovery: false,
    allowLogin: false,
    allowPWAAttributes: false,
    allowAutoLogin: true,
    allowActivateMembership: true,
  },
  queryParams: {
    tokenParam: "exotic-token",
  },
  links: {
    tenantLink: "https://exotictravelers.com/",
  },
};

export { exoticTravelersConfig };
