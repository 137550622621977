import { useTranslation } from "react-i18next";
import useReservationContext from "context/ReservationContext";
import LocationCard from "components/molecules/LocationCard";
import {
  SelectLocationSubtitle,
  SelectLocationSection,
  SelectLocationSubSection,
  SoonLocationItem,
  LocationsList,
} from "components/atoms/reservation/SelectLocation";
import { FormLogo } from "components/atoms/form";
import FetchSection from "components/molecules/FetchSection";
import { ELocationStatus } from "shared/types/location/ELocationStatus";
import { When } from "react-if";
import mixpanelService from "services/mixpanel";

const defaultLocationImage = "/assets/regular-membership/1.jpeg";

const SelectLocation = () => {
  const { t } = useTranslation();
  const {
    availableLocations,
    onSelectLocation,
    isFetchingAvailableLocations,
    hasErrorFetchingAvailableLocations,
  } = useReservationContext();
  const soonLocations = availableLocations.filter(
    ({ status }) => status === ELocationStatus.SOON
  );
  const activeLocations = availableLocations.filter(
    ({ status }) => status === ELocationStatus.ACTIVE
  );
  const availableLocationsIsEmpty = availableLocations.length === 0;

  const onLocationCardClick =
    (locationId: number, locationName: string) => () => {
      /**
       * @todo: Move track event to reservation context
       */
      mixpanelService.track.destinationSelected({
        destinationName: locationName,
      });
      onSelectLocation(locationId);
    };

  return (
    <SelectLocationSection>
      <SelectLocationSubSection>
        <SelectLocationSubtitle>
          {t("reservation.locations.title")}
        </SelectLocationSubtitle>
        <FetchSection
          isFetching={isFetchingAvailableLocations}
          isEmpty={availableLocationsIsEmpty}
          hasError={hasErrorFetchingAvailableLocations}
          isEmptyMessage={t("reservation.locations.empty")}
          hasErrorMessage={t("reservation.locations.error")}
        >
          <LocationsList>
            {activeLocations.map(({ name, id, image }) => (
              <LocationCard
                key={id}
                name={name}
                coverUrl={image || defaultLocationImage}
                onClick={onLocationCardClick(id, name)}
              />
            ))}
          </LocationsList>
        </FetchSection>
      </SelectLocationSubSection>
      <When condition={soonLocations.length}>
        <SelectLocationSubSection>
          <SelectLocationSubtitle>
            {t("reservation.soon-locations.title")}
          </SelectLocationSubtitle>
          <LocationsList gap="60px" alignItems="center">
            {soonLocations.map(({ name, id }) => (
              <SoonLocationItem key={id}>{name}</SoonLocationItem>
            ))}
          </LocationsList>
        </SelectLocationSubSection>
      </When>
      <SelectLocationSubSection>
        <FormLogo />
      </SelectLocationSubSection>
    </SelectLocationSection>
  );
};

export default SelectLocation;
