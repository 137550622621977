const assets = {
  mxFlag: "/assets/mx-flag.svg",
  mxFlagDisabled: "/assets/mx-flag-disabled.svg",
  usFlag: "/assets/us-flag.svg",
  usFlagDisabled: "/assets/us-flag-disabled.svg",
  yachtsLogo: "/assets/yachts-logo.svg",
  yachtsLogoSmall: "/assets/yachts-logo-small.svg",
  hamburgerMenu: "/assets/hamburger-menu.svg",
  seaBackgroundWebp: "/assets/sea-background.webp",
  seaBackgroundJpg: "/assets/sea-background.jpg",
  waves: "/assets/waves.svg",
  locationArrow: "/assets/location-arrow.svg",
  ruler: "/assets/ruler.svg",
  users: "/assets/users.svg",
  bed: "/assets/bed.svg",
  bluetooth: "/assets/bluetooth.svg",
  restroom: "/assets/restroom.svg",
  snowflake: "/assets/snowflake.svg",
  sun: "/assets/sun.svg",
  arrowLeft: "/assets/arrow-left.svg",
  arrowRight: "/assets/arrow-right.svg",
  mapMarker: "/assets/map-marker-alt.svg",
  ship: "/assets/ship.svg",
  hashtag: "/assets/hashtag.svg",
  whatsApp: "/assets/whatsapp.svg",
  email: "/assets/email.svg",
  plus: "/assets/plus.svg",
  star: "/assets/star.svg",
  starFilled: "/assets/star-filled.svg",
  trash: "/assets/trash.svg",
  americanExpress: "/assets/american-express.png",
  mastercard: "/assets/mastercard.png",
  visa: "/assets/visa.png",
  user: "/assets/user.svg",
  defaultUserImage: "/assets/default_user_image.jpg",
  edit: "/assets/edit.svg",
  arrowSecondaryLeft: "/assets/arrow-1-left.svg",
  arrowSecondaryRight: "/assets/arrow-2-right.svg",
  share: "/assets/share.svg",
  defaultYachtImage: "/assets/regular-membership/1.jpeg",
  chatDiamond: "/assets/chat.svg",
  pdf: "/assets/pdf.svg",
  serviceDiamond: "/assets/service-diamond.svg",
  dinersClub: "/assets/diners-club.png",
  discover: "/assets/discover.png",
  jcb: "/assets/jcb.png",
  unionPay: "/assets/unionpay.png",
  ContingencyWarning: "/assets/yacht_beryl.jpeg",
};

export default assets;
