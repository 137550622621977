import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const MyMembershipInformationSection = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 30px;
  height: fit-content;

  @media ${desktop} {
    align-items: flex-start;
    width: 40%;
  }
`;

export { MyMembershipInformationSection };
