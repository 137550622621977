import * as rdd from "react-device-detect";
import { devices } from "shared/constants/design";

// @ts-ignore this line is for testing, comment it before shipping
// rdd.isBrowser = false;

const { desktopMd } = devices;

const isBrowserAndDesktopMdMedia = (css: string) =>
  rdd.isBrowser
    ? `@media ${desktopMd} {
        ${css}
      }`
    : "";

export { isBrowserAndDesktopMdMedia };
