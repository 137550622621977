import styled from "styled-components";
import {
  fonts,
  fontSizes,
  fontWeights,
  colors,
  devices,
} from "shared/constants/design";

const { primary } = colors;
const { semiBold } = fontWeights;
const { md, sm } = fontSizes;
const { conthrax } = fonts;
const { desktop, desktopMd } = devices;

const AmenitiesTitle = styled.h1`
  color: ${primary};
  font: italic normal ${semiBold} ${md} ${conthrax};
  margin: 0;
  text-transform: uppercase;
  height: fit-content;

  @media ${desktop} {
    font-size: ${sm};
  }

  @media ${desktopMd} {
    font-size: ${md};
  }
`;

export { AmenitiesTitle };
