import countriesService from "services/country";
import useFetch from "./useFetch";
import type { ICountry } from "shared/types/country";

const useCountries = () => {
  const {
    data: countries,
    isFetching: isFetchingCountries,
    hasError: countriesFetchHasError,
  } = useFetch<ICountry[]>({
    initialData: [],
    fetcher: countriesService.getCountries,
  });

  return {
    countries,
    isFetchingCountries,
    countriesFetchHasError,
  };
};

export default useCountries;
