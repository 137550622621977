import styled from "styled-components";
import {
  borders,
  colors,
  fontSizes,
  fontWeights,
  fonts,
  devices,
} from "shared/constants/design";

const { borderRadiusLg } = borders;
const { white, grayShadow, text } = colors;
const { semiBold } = fontWeights;
const { lg, md } = fontSizes;
const { conthrax } = fonts;
const { desktop } = devices;

const LocationCardContainer = styled.button`
  border-radius: ${borderRadiusLg};
  border: 1px solid transparent;
  box-shadow: 0 3px 18px ${grayShadow};
  height: 220px;
  max-width: 300px;
  min-width: 220px;
  padding: 0;
  position: relative;
  width: 100%;

  &:hover {
    border: 1px solid ${text};
  }
`;

const LocationCardCover = styled.img`
  border-radius: ${borderRadiusLg};
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

const LocationCardTitle = styled.h4`
  bottom: 15px;
  color: ${white};
  font: italic normal ${semiBold} ${md} ${conthrax};
  left: 15px;
  line-break: anywhere;
  line-height: ${md};
  margin: 0;
  margin-right: 15px;
  position: absolute;
  text-transform: uppercase;

  @media ${desktop} {
    font-size: ${lg};
    line-height: ${lg};
  }
`;

interface ILocationCard {
  name: string;
  coverUrl: string;
  onClick?: () => void;
}

const LocationCard = ({ name, coverUrl, onClick }: ILocationCard) => (
  <LocationCardContainer onClick={onClick}>
    <LocationCardCover src={coverUrl} alt={name} />
    <LocationCardTitle>{name}</LocationCardTitle>
  </LocationCardContainer>
);

export default LocationCard;
