import styled from "styled-components";

const LoginSectionContainer = styled.div`
  display: grid;
  grid-gap: 70px;
  grid-template-rows: 1fr auto;
  place-items: center;
`;

export { LoginSectionContainer };
