import { FC } from "react";
import styled, { css } from "styled-components";
import ShipCard from "components/atoms/ShipCard";
import { isBrowserAndDesktopMedia } from "utils/css";
import { EShipPosition } from "shared/types/common";
import { limits } from "shared/constants/design";

const { desktopMaxWidth } = limits;

interface IShipLayoutContainer {
  position?: EShipPosition;
  shouldDisplayDesktopQueries?: boolean;
}

const ShipLayoutContainer = styled.div<IShipLayoutContainer>(
  ({ position = EShipPosition.LEFT, shouldDisplayDesktopQueries = false }) => {
    const isLeftPosition = position === EShipPosition.LEFT;
    const templateColumns = isLeftPosition ? "1fr 0px" : "0px 1fr";
    const templateAreas = isLeftPosition ? '"ship diamond"' : '"diamond ship"';

    return css`
      align-items: center;
      display: grid;
      margin: 0 auto;
      max-width: 1000px;
      min-height: 100%;
      padding: 20px;

      ${isBrowserAndDesktopMedia(
        `
        grid-gap: 50px;
        grid-template-areas: ${templateAreas};
        grid-template-columns: ${templateColumns};
        max-width: ${desktopMaxWidth};
        padding: 30px 0px;
      `,
        shouldDisplayDesktopQueries
      )}
    `;
  }
);

const ShipLayoutContent = styled(ShipCard)`
  height: 100%;
  max-height: 1000px;
`;

interface IShipLayout {
  position?: EShipPosition;
  shouldDisplayDesktopQueries?: boolean;
}

const ShipLayout: FC<IShipLayout> = ({
  position = EShipPosition.LEFT,
  shouldDisplayDesktopQueries = false,
  children,
}) => (
  <ShipLayoutContainer
    position={position}
    shouldDisplayDesktopQueries={shouldDisplayDesktopQueries}
  >
    <ShipLayoutContent
      gridArea="ship"
      position={position}
      shouldDisplayDesktopQueries={shouldDisplayDesktopQueries}
    >
      {children}
    </ShipLayoutContent>
  </ShipLayoutContainer>
);

export default ShipLayout;
