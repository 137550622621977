import { Input } from "components/atoms/form";
import DatePicker from "react-datepicker";

interface IDateSelector {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  minDate?: Date;
  dateFormat?: string;
  blockedDates?: Date[];
}

const DateSelector = ({
  selectedDate,
  onDateChange,
  minDate = new Date(),
  dateFormat = "MMMM d, yyyy",
  blockedDates = [],
}: IDateSelector) => (
  <DatePicker
    onChange={onDateChange}
    selected={selectedDate}
    minDate={minDate}
    dateFormat={dateFormat}
    customInput={<Input name="date" />}
    excludeDates={blockedDates}
  />
);

export default DateSelector;
