import { Link } from "react-router-dom";
import routes from "shared/constants/routes";
import assets from "shared/constants/assets";

const NavbarLogo = () => (
  <Link to={routes.home}>
    <img
      src={assets.yachtsLogo}
      alt="Yachts logo"
      width="164.83"
      height="27.9"
    />
  </Link>
);

export { NavbarLogo };
