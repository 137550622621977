import { createContext } from "react";
import { INotifyConfiguration } from "shared/types/notification";

interface INotificationContext {
  notify: (configuration: INotifyConfiguration) => void;
}

const NotificationContext = createContext<INotificationContext | undefined>(
  undefined
);

export default NotificationContext;
