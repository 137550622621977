import styled from "styled-components";
import { colors } from "shared/constants/design";

const { text } = colors;

const NewPaymentMethodFormInputContainer = styled.div`
  border-radius: 10px;
  border: 1px solid ${text};
  padding: 10px;
`;

export { NewPaymentMethodFormInputContainer };
