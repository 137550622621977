import styled from "styled-components";
import { devices, colors, borders } from "shared/constants/design";

const { desktop } = devices;
const { backgroundSecondary, blackShadowDark } = colors;
const { borderRadiusLg } = borders;

const ProfileMobileDetails = styled.div`
  background: ${backgroundSecondary};
  border-radius: ${borderRadiusLg};
  box-shadow: 6px 6px 64px ${blackShadowDark};
  display: grid;
  padding: 20px;

  @media ${desktop} {
    display: none;
  }
`;

export { ProfileMobileDetails };
