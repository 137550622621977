import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const ReservationDetailsContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  overflow-y: scroll;
  padding-top: 20px;

  @media ${desktop} {
    padding: 50px 0;
  }
`;

export { ReservationDetailsContainer };
