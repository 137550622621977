import styled from "styled-components";

const NewPaymentMethodFormActions = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto;
  width: fit-content;
`;

export { NewPaymentMethodFormActions };
