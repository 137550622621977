import { useState } from "react";
import PopConfirm from "components/molecules/PopConfirm";
import {
  CreditCardActionArea,
  ICreditCardActionArea,
  CreditCardBrandContainer,
  CreditCardBrandIcon,
  CreditCardContent,
  CreditCardIcons,
  CreditCardText,
} from "components/atoms/CreditCard";
import IconButton from "components/atoms/IconButton";
import assets from "shared/constants/assets";

const { trash, star, starFilled } = assets;

interface ICreditCard extends ICreditCardActionArea {
  last4: string;
  brandIconUrl: string;
  onClick?: () => void;
  onRemove?: () => void;
  onSetAsDefault?: () => void;
  isDefault?: boolean;
}

const CreditCard = ({
  last4,
  brandIconUrl,
  isSelected = false,
  onClick = () => {},
  onRemove = () => {},
  onSetAsDefault = () => {},
  isDefault = false,
}: ICreditCard) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = (event: any) => {
    setShow(!show);
    setTarget(event.target);
  };

  const onClose = () => setShow(!show);

  return (
    <CreditCardActionArea onClick={onClick} isSelected={isSelected}>
      <CreditCardContent>
        <CreditCardIcons>
          <PopConfirm
            show={show}
            target={target}
            onConfirm={onRemove}
            onClose={onClose}
          >
            <IconButton
              src={trash}
              width="14px"
              height="14px"
              alt="remove card"
              onClick={handleClick}
            />
          </PopConfirm>
          <IconButton
            src={isDefault ? starFilled : star}
            width="14px"
            height="14px"
            alt="set as default"
            onClick={onSetAsDefault}
          />
        </CreditCardIcons>
        <CreditCardText>**** **** **** {last4}</CreditCardText>
        <CreditCardBrandContainer>
          <CreditCardBrandIcon src={brandIconUrl} alt="" />
        </CreditCardBrandContainer>
      </CreditCardContent>
    </CreditCardActionArea>
  );
};

export type { ICreditCard };
export default CreditCard;
