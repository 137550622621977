import { useState } from "react";
import { Else, If, Then } from "react-if";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { NewPaymentMethodForm } from "./NewPaymentMethodForm";
import CreditCard, { ICreditCard } from "components/molecules/CreditCard";
import {
  NewPaymentMethodButton,
  PaymentMethodsContainer,
  CreditCardsList,
} from "components/atoms/PaymentMethods";
import environment from "shared/constants/environment";
import PaymentService from "services/payment";
import { IStripeCard } from "shared/types/payment";

const { stripeKey } = environment;
const stripePromise = loadStripe(stripeKey);

interface IPaymentMethodItem extends ICreditCard {
  id: string;
}

interface IPaymentMethods {
  paymentMethods: IPaymentMethodItem[];
  onUpdatePaymentMethods?: () => Promise<void>;
}

const PaymentMethods = ({
  paymentMethods,
  onUpdatePaymentMethods = async () => {},
}: IPaymentMethods) => {
  const [displayPaymentMethodForm, setDisplayPaymentMethodForm] =
    useState(false);

  const toggleDisplayPaymentMethodForm = () =>
    setDisplayPaymentMethodForm(!displayPaymentMethodForm);

  const onSubmitNewPaymentMethod = async ({ id }: IStripeCard) => {
    await PaymentService.createUserPaymentMethod({ token_id: id });
    await onUpdatePaymentMethods();
    toggleDisplayPaymentMethodForm();
  };

  return (
    <Elements stripe={stripePromise}>
      <PaymentMethodsContainer>
        <If condition={displayPaymentMethodForm}>
          <Then>
            <NewPaymentMethodForm
              onSubmit={onSubmitNewPaymentMethod}
              onCancel={toggleDisplayPaymentMethodForm}
            />
          </Then>
          <Else>
            <CreditCardsList>
              {paymentMethods.map(({ id, ...restOfProps }) => (
                <CreditCard key={id} {...restOfProps} />
              ))}
              <NewPaymentMethodButton
                onClick={toggleDisplayPaymentMethodForm}
              />
            </CreditCardsList>
          </Else>
        </If>
      </PaymentMethodsContainer>
    </Elements>
  );
};

export type { IPaymentMethodItem };
export default PaymentMethods;
