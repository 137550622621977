import { useTranslation } from "react-i18next";
import { When } from "react-if";
import useAuthContext from "context/AuthContext";
import Link from "components/atoms/Link";
import {
  NavbarLogo,
  NavbarLink,
  NavbarLinksDivider,
  NavbarContentContainer,
  ActionsContainer,
  PrimaryActionsContainer,
  LinksContainer,
  FlagsContainer,
  LanguageButton,
  NavbarPrimaryButton,
  DisplayInDesktop,
  ProfileIcon,
  DisplayInMobile,
} from "components/atoms/Navbar";
import routes from "shared/constants/routes";
import assets from "shared/constants/assets";

const { home, experiences, memberships, login, policies } = routes;
const { usFlag, mxFlag, usFlagDisabled, mxFlagDisabled } = assets;

interface INavbarContent {
  usFlagIsDisabled: boolean;
  mxFlagIsDisabled: boolean;
  onEnLanguageSelected: () => void;
  onEsLanguageSelected: () => void;
}

const NavbarContent = ({
  usFlagIsDisabled,
  mxFlagIsDisabled,
  onEnLanguageSelected,
  onEsLanguageSelected,
}: INavbarContent) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuthContext();

  return (
    <NavbarContentContainer>
      <NavbarLogo />
      <ActionsContainer>
        <PrimaryActionsContainer>
          <When condition={isLoggedIn}>
            <DisplayInDesktop>
              <ProfileIcon />
            </DisplayInDesktop>
          </When>
          <Link to={isLoggedIn ? home : login}>
            <NavbarPrimaryButton>{t("navbar.routes.book")}</NavbarPrimaryButton>
          </Link>
          <LinksContainer>
            <NavbarLink to={home}>{t("navbar.routes.home")}</NavbarLink>
            <NavbarLinksDivider />
            <NavbarLink to={experiences} target="_blank" isExternal>
              {t("navbar.routes.experiences")}
            </NavbarLink>
            <NavbarLinksDivider />
            <NavbarLink to={memberships} target="_blank" isExternal>
              {t("navbar.routes.memberships")}
            </NavbarLink>
          </LinksContainer>
        </PrimaryActionsContainer>
        <FlagsContainer>
          <LanguageButton
            src={usFlag}
            disabledSrc={usFlagDisabled}
            alt={t("navbar.select-language.english")}
            disabled={usFlagIsDisabled}
            onClick={onEnLanguageSelected}
          />
          <LanguageButton
            src={mxFlag}
            disabledSrc={mxFlagDisabled}
            alt={t("navbar.select-language.spanish")}
            disabled={mxFlagIsDisabled}
            onClick={onEsLanguageSelected}
          />
        </FlagsContainer>
      </ActionsContainer>
      <DisplayInMobile>
        <Link to={policies}>{t("policies.title")}</Link>
      </DisplayInMobile>
    </NavbarContentContainer>
  );
};

const StaticNavbarContent = ({
  usFlagIsDisabled,
  mxFlagIsDisabled,
  onEnLanguageSelected,
  onEsLanguageSelected,
}: INavbarContent) => {
  const { t } = useTranslation();

  return (
    <NavbarContentContainer>
      <NavbarLogo />
      <ActionsContainer>
        <PrimaryActionsContainer>
          <LinksContainer>
            <NavbarLink to={home}>{t("navbar.routes.home")}</NavbarLink>
            <NavbarLinksDivider />
            <NavbarLink to={experiences} target="_blank" isExternal>
              {t("navbar.routes.experiences")}
            </NavbarLink>
            <NavbarLinksDivider />
            <NavbarLink to={memberships} target="_blank" isExternal>
              {t("navbar.routes.memberships")}
            </NavbarLink>
          </LinksContainer>
        </PrimaryActionsContainer>
        <FlagsContainer>
          <LanguageButton
            src={usFlag}
            disabledSrc={usFlagDisabled}
            alt={t("navbar.select-language.english")}
            disabled={usFlagIsDisabled}
            onClick={onEnLanguageSelected}
          />
          <LanguageButton
            src={mxFlag}
            disabledSrc={mxFlagDisabled}
            alt={t("navbar.select-language.spanish")}
            disabled={mxFlagIsDisabled}
            onClick={onEsLanguageSelected}
          />
        </FlagsContainer>
      </ActionsContainer>
      <DisplayInMobile>
        <Link to={policies}>{t("policies.title")}</Link>
      </DisplayInMobile>
    </NavbarContentContainer>
  );
};

export { StaticNavbarContent };
export default NavbarContent;
