import styled from "styled-components";
import {
  colors,
  fontWeights,
  fontSizes,
  fonts,
  devices,
} from "shared/constants/design";

const { textInverted } = colors;
const { semiBold } = fontWeights;
const { lg } = fontSizes;
const { conthrax } = fonts;
const { desktop } = devices;

const ProfileDetailsTitle = styled.h4`
  color: ${textInverted};
  font: italic normal ${semiBold} ${lg} ${conthrax};
  margin: 0;
  text-align: center;
  text-transform: uppercase;

  @media ${desktop} {
    display: none;
  }
`;

export { ProfileDetailsTitle };
