import styled from "styled-components";
import { fonts, fontSizes, colors, devices } from "shared/constants/design";

const { textLight } = colors;
const { xsm } = fontSizes;
const { nexaRegular } = fonts;
const { desktop } = devices;

const YachtCardDetail = styled.div`
  color: ${textLight};
  display: grid;
  font-family: ${nexaRegular};
  font-size: ${fontSizes["3xsm"]};
  grid-gap: 5px;
  grid-template-columns: auto auto;
  letter-spacing: 0.36;
  line-height: 10px;
  margin: 0;
  margin-bottom: 5px;
  place-items: center;
  width: fit-content;

  @media ${desktop} {
    font-size: ${xsm};
    grid-gap: 10px;
    letter-spacing: 0.54;
    line-height: 12px;
  }
`;

export { YachtCardDetail };
