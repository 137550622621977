import styled from "styled-components";

const YachtCardDesktopDetails = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: auto auto auto;
  width: fit-content;
  text-align: center;
`;

export { YachtCardDesktopDetails };
