import http from "services/http";
import type {
  IUpdateUserData,
  IUserGetMeResponse,
  IUser,
} from "shared/types/user";

const UserService = () => {
  const getMe = async () => {
    const userGetMeResponse = await http.get<IUserGetMeResponse>("/user/me");
    return userGetMeResponse;
  };

  const updateUserLanguage = async ({
    userId,
    locale,
  }: {
    userId: number;
    locale: string;
  }) => {
    const updatedUser = await http.put<IUser>(`/user/${userId}`, {
      profile: { locale },
    });
    console.log({ updatedUser });
    return updatedUser;
  };

  const updateUserData = async (
    userId: number,
    {
      first_name,
      last_name,
      country_id,
      birthdate,
      email,
      address,
      phone,
    }: IUpdateUserData
  ) => {
    const updatedUser = await http.put<IUser>(`/user/${userId}`, {
      user: {
        email,
        first_name,
        last_name,
        country_id,
      },
      profile: {
        birthdate,
        address,
        phone,
      },
    });

    return updatedUser;
  };

  const updateUserAvatar = async (userId: number, avatarImageFile: any) => {
    const formData = new FormData();
    formData.append("avatar", avatarImageFile || "");
    await http.post<void>(`/user/${userId}/avatar`, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  return {
    getMe,
    updateUserData,
    updateUserAvatar,
    updateUserLanguage,
  };
};

export default UserService();
