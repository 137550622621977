import { FC } from "react";
import Navbar, { StaticNavbar } from "components/organisms/Navbar";
import {
  MainLayout,
  Background,
  MainContent,
} from "components/atoms/PageTemplate";

interface IPageTemplateProps {
  renderStaticNavbar?: boolean;
}

const PageTemplate: FC<IPageTemplateProps> = ({
  children,
  renderStaticNavbar = false,
}) => (
  <>
    <Background />
    <MainLayout>
      {renderStaticNavbar && <StaticNavbar />}
      {!renderStaticNavbar && <Navbar />}
      <MainContent>{children}</MainContent>
    </MainLayout>
  </>
);

export default PageTemplate;
