import { forwardRef, ReactNode } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { devices, limits } from "shared/constants/design";

const { desktop, tabletSmall } = devices;
const { desktopMaxWidth } = limits;

const totalMobilePaddingCount = "100px";
const totalTabletSmallPaddingCount = '180px';
const chatbotIconAreaWidth = "130px";
const shipLayoutContainerMinWidth = `calc(100vw - ${chatbotIconAreaWidth})`;
const sliderWidthPercentage = 100;
const profileDetailsAndPaddingWidth = '385px'

const CustomSlider = styled(Slider)`
  max-width: calc(100vw - ${totalMobilePaddingCount});

  & .slick-list {
    height: 100%;
  }

  & .slick-track {
    height: 100%;
    margin: 0;
  }

  & .slick-slide > div {
    height: fit-content;
  }

  & .slick-dots {
    bottom: unset;
    top: 15px;
  }

  & .slick-dots > li > button::before {
    border: 3px solid white;
    color: transparent;
    height: 15px;
    width: 15px;
  }

  & .slick-dots > .slick-active > button::before {
    background-color: white;
  }

  @media ${tabletSmall} {
    max-width: calc(100vw - ${totalTabletSmallPaddingCount});

    & .slick-slide > div {
    height: fit-content;
    padding: 0 10px;
  }
  }

  @media ${desktop} {
    height: 100%;
    max-width: calc(calc(calc(min(${desktopMaxWidth}, ${shipLayoutContainerMinWidth}) - ${profileDetailsAndPaddingWidth}) / 100) * ${sliderWidthPercentage});
  }
`;

const sliderSettings = {
  infinite: false,
  dots: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

interface IMyBookingsSlider {
  children: ReactNode
}

const MyBookingsSlider = forwardRef<Slider, IMyBookingsSlider>(
  ({ children }, ref) => (
    <CustomSlider {...sliderSettings} ref={ref}>
      {children}
    </CustomSlider>
  )
);

export { MyBookingsSlider };