import styled from "styled-components";
import { borders, colors } from "shared/constants/design";

const { borderRadiusLg } = borders;
const { grayShadow, text } = colors;

const YachtCardContainer = styled.button`
  background: transparent;
  border-radius: ${borderRadiusLg};
  border: 1px solid transparent;
  box-shadow: 0 3px 18px ${grayShadow};
  display: grid;
  grid-template-columns: 25% 1fr;
  padding: 0;

  &:hover {
    border: 1px solid ${text};
  }
`;

export { YachtCardContainer };
