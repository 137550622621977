import tenantService from "services/tenant";

const tenantConfigs = tenantService.getConfigs();
const white = "#FFFFFF";
const almostBlack = "#191919";
const secondary = "#191919cc";

const colors = {
  white,
  black: "#000000",
  background: tenantConfigs.colors.background,
  backgroundSecondary: secondary,
  text: almostBlack,
  textLight: "#313131",
  textInverted: white,
  primary: tenantConfigs.colors.primary,
  primaryHover: tenantConfigs.colors.primaryHover,
  secondary: tenantConfigs.colors.secondary,
  tertiary: tenantConfigs.colors.tertiary,
  success: "#4BB543",
  error: "#ff3333",
  warning: "#eed202",
  borderColor: almostBlack,
  primaryBorderColor: tenantConfigs.colors.primaryBorderColor,
  primaryOpaque: tenantConfigs.colors.primaryOpaque,
  blackShadowLight: "#00000029",
  blackShadowDark: "#000000B3",
  grayShadow: "#9A9A9A8F",
};

export { colors };
