import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const LinksContainer = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media ${desktop} {
    flex-direction: row;
  }
`;

export { LinksContainer };
