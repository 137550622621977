import styled from "styled-components";
import { colors, fontSizes, fonts, devices } from "shared/constants/design";

const { text } = colors;
const { sm, md } = fontSizes;
const { nexaBold } = fonts;
const { desktop } = devices;

const PoliciesSubtitle = styled.h5`
  color: ${text};
  font-family: ${nexaBold};
  font-size: ${sm};
  font-weight: unset;
  letter-spacing: 1.35px;
  line-height: 30px;
  margin: 0;
  padding-bottom: 0.5rem;
  padding-top: 1rem;

  @media ${desktop} {
    font-size: ${md};
  }
`;

export { PoliciesSubtitle };
