import mixpanel from "mixpanel-browser";

interface ITrackUserEnterBookingDetailsPagePayload {
  bookingId: string;
}

interface IInitUserActionsEvents {
  mixpanelIsEnabled: boolean;
}

const UserActionsEvents = ({ mixpanelIsEnabled }: IInitUserActionsEvents) => {
  const userEnterBookingDetailsPage = ({
    bookingId,
  }: ITrackUserEnterBookingDetailsPagePayload) => {
    if (!mixpanelIsEnabled) return;
    mixpanel.track("User Enter Booking Details Page", {
      "Booking ID": bookingId,
    });
  };

  return {
    userEnterBookingDetailsPage,
  };
};

export default UserActionsEvents;
