import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const Amenities = styled.div`
  display: grid;
  grid-gap: 15px;
  height: 100%;
  overflow-y: auto;

  @media ${desktop} {
    padding-top: 20px;
  }
`;

export { Amenities };
