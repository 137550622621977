import styled from "styled-components";
import Button, { ButtonProps } from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { When } from "react-if";
import { colors, fontSizes, fonts, fontWeights } from "shared/constants/design";

const { secondary, background } = colors;
const { sm } = fontSizes;
const { semiBold } = fontWeights;
const { conthrax } = fonts;

const BaseSecondaryButton = styled(Button)`
  align-items: flex-end;
  background-color: ${secondary};
  border-radius: 14px;
  border: 1px solid ${background};
  display: flex;
  font: italic normal ${semiBold} ${sm} ${conthrax};
  gap: 10px;
  letter-spacing: 1.15px;
  line-height: 1;
  padding: 7px 30px;
  text-transform: uppercase;
  width: fit-content;

  &:hover {
    background-color: ${background};
  }

  &:focus {
    background-color: ${background};
  }

  &:disabled {
    background-color: ${background};
    cursor: not-allowed;
  }
`;

interface ISecondaryButton extends ButtonProps {
  isLoading?: boolean;
}

const SecondaryButton = ({
  children,
  isLoading = false,
  disabled,
  ...restOfProps
}: ISecondaryButton) => (
  <BaseSecondaryButton disabled={disabled || isLoading} {...restOfProps}>
    <When condition={isLoading}>
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    </When>
    <span>{children}</span>
  </BaseSecondaryButton>
);

export default SecondaryButton;
