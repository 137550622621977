import { useTranslation } from "react-i18next";
import styled from "styled-components";
import BaseModal from "react-bootstrap/Modal";
import { EmailShareButton, WhatsappShareButton } from "react-share";
import PrimaryButton from "components/atoms/PrimaryButton";
import BaseCard from "components/atoms/Card";
import IconButton from "components/atoms/IconButton";
import { colors, fonts, fontSizes, devices } from "shared/constants/design";
import assets from "shared/constants/assets";
import "./share-booking-modal.css";

const { text } = colors;
const { nexaRegular } = fonts;
const { sm, md } = fontSizes;
const { desktop } = devices;
const { whatsApp, email } = assets;

const Modal = styled(BaseModal)`
  & .modal-content {
    background-color: transparent;
    border: none;
  }
`;

const Card = styled(BaseCard)`
  margin: auto;
  width: fit-content;
`;

const Content = styled.div`
  display: grid;
  grid-gap: 35px;
  place-items: center;
`;

const ShareSection = styled.div`
  display: grid;
  grid-gap: 10px;
  padding: 20px 0;
  place-items: center;
`;

const IconsContainer = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: auto auto;
  width: fit-content;
`;

const Description = styled.div`
  color: ${text};
  font-family: ${nexaRegular};
  font-size: ${sm};
  letter-spacing: 0.45;
  line-height: 18px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;

  @media ${desktop} {
    font-size: ${md};
  }
`;

interface IPreReservationModal {
  isOpen: boolean;
  onContinue: () => void;
  shareUrl: string;
}

const ShareBookingModal = ({
  isOpen,
  onContinue,
  shareUrl,
}: IPreReservationModal) => {
  const { t } = useTranslation();

  return (
    <Modal show={isOpen} centered size="lg" backdropClassName="glass-backdrop">
      <Card>
        <Content>
          <ShareSection>
            <Description>{t("reservation.success-modal.share")}</Description>
            <IconsContainer>
              <EmailShareButton
                subject={t("reservation.share.title")}
                url={shareUrl}
              >
                <IconButton
                  src={email}
                  alt={t("reservation.success-modal.email-alt")}
                  width="40px"
                  height="40px"
                />
              </EmailShareButton>
              <WhatsappShareButton
                title={t("reservation.share.title")}
                url={shareUrl}
              >
                <IconButton
                  src={whatsApp}
                  alt={t("reservation.success-modal.whatsapp-alt")}
                  width="40px"
                  height="40px"
                />
              </WhatsappShareButton>
            </IconsContainer>
          </ShareSection>
          <PrimaryButton onClick={onContinue}>
            {t("reservation.success-modal.continue")}
          </PrimaryButton>
        </Content>
      </Card>
    </Modal>
  );
};

export { ShareBookingModal };
