import styled from "styled-components";
import {
  fonts,
  fontSizes,
  fontWeights,
  colors,
  devices,
} from "shared/constants/design";

const { white } = colors;
const { semiBold } = fontWeights;
const { md, lg } = fontSizes;
const { conthrax } = fonts;
const { desktopMd } = devices;

const AddonsTitle = styled.h1`
  color: ${white};
  font: italic normal ${semiBold} ${md} ${conthrax};
  margin: 0;
  text-transform: uppercase;
  height: fit-content;

  @media ${desktopMd} {
    font-size: ${lg};
  }
`;

export { AddonsTitle };
