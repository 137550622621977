import { useParams } from "react-router-dom";
import ShipLayout from "components/templates/ShipLayout";
import useReservationDetails from "hooks/useReservationDetails";
import { useTranslation } from "react-i18next";
import { YachtImagesSlider } from "components/molecules/reservation/SelectYacht";
import SecondaryButton from "components/atoms/SecondaryButton";
import {
  MainReservationLayout,
  ReservationStepTitle,
  ReservationStepSubtitle,
  Currency,
  GoBackButtonContainer,
  TotalSectionContainer,
} from "components/atoms/reservation";
import {
  ReservationDetailItem,
  ReservationDetails,
  Pricing,
  PricingItem,
  AddressMap,
  AddressDetailContainer,
  ReservationResumeContent,
  PricingSection,
} from "components/atoms/reservation/ReservationResume";
import {
  TotalPrice,
  TotalPriceTitle,
} from "components/atoms/reservation/SelectAddons";
import assets from "shared/constants/assets";
import routes from "shared/constants/routes";
import FetchSection from "components/molecules/FetchSection";
import isDefined from "utils/isDefined";
import getFormattedDate from "utils/getFormattedDate";
import getFormattedHour from "utils/getFormattedHour";
import getUserMembershipPrice from "utils/getUserMembershipPrice";
import useAuthContext from "context/AuthContext";
import getFormattedPrice from "utils/getFormattedPrice";
import getReservationNumberOfHours from "utils/getReservationNumberOfHours";
import useTranslatedProperty from "hooks/useTranslatedProperty";
import mixpanelService from "services/mixpanel";
import { useEffect } from "react";

const { ship, arrowRight, arrowLeft, users, mapMarker, hashtag } = assets;
const { userReservations } = routes;

interface IBookingDetailsRouteParams {
  bookingId: string;
}

const BookingDetails = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { bookingId } = useParams<IBookingDetailsRouteParams>();
  const { reservationDetails, isFetchingDetails, hasErrorFetchingDetails } =
    useReservationDetails(Number(bookingId));
  const getTranslation = useTranslatedProperty();
  const numberOfHours = getReservationNumberOfHours(reservationDetails);
  const coordinates = {
    lat: Number(reservationDetails?.yacht?.latitude),
    lng: Number(reservationDetails?.yacht?.longitude),
  };
  const yachtPrice =
    getUserMembershipPrice(
      reservationDetails?.yacht?.prices || [],
      user?.membership
    ) * numberOfHours;

  useEffect(() => {
    mixpanelService.track.userEnterBookingDetailsPage({ bookingId: bookingId });
  }, []);

  return (
    <ShipLayout>
      <FetchSection
        isFetching={isFetchingDetails}
        hasError={hasErrorFetchingDetails}
        isEmpty={!isDefined(reservationDetails)}
        hasErrorMessage={t("profile.reserve-details.error")}
        isEmptyMessage={t("profile.reserve-details.not-found")}
      >
        <MainReservationLayout>
          <GoBackButtonContainer>
            <SecondaryButton href={userReservations}>
              {t("go-back-button-label")}
            </SecondaryButton>
          </GoBackButtonContainer>
          <ReservationResumeContent>
            <ReservationDetails>
              <ReservationStepTitle>
                {t("reservation-details.title")}
              </ReservationStepTitle>
              <ReservationDetailItem
                label={t("reservation.detail.ship")}
                name={`${reservationDetails?.yacht?.name || ""}${
                  reservationDetails?.yacht?.model
                    ? `, ${reservationDetails?.yacht?.model}`
                    : ""
                }`}
                iconSrc={ship}
              />
              <ReservationDetailItem
                label={t("reservation.detail.departure")}
                name={`${getFormattedDate(
                  reservationDetails?.start_date
                )} @ ${getFormattedHour(reservationDetails?.start_date)}`}
                iconSrc={arrowRight}
              />
              <ReservationDetailItem
                label={t("reservation.detail.arrival")}
                name={`${getFormattedDate(
                  reservationDetails?.end_date
                )} @ ${getFormattedHour(reservationDetails?.end_date)}`}
                iconSrc={arrowLeft}
              />
              <ReservationDetailItem
                label={t("reservation.detail.code")}
                name={reservationDetails?.code || ""}
                iconSrc={hashtag}
              />
            </ReservationDetails>
            <ReservationDetails>
              <ReservationDetailItem
                label={t("reservation.detail.size")}
                name={`${reservationDetails?.passengers_number} ${t(
                  "profile.reserves.card.passengers"
                )}`}
                iconSrc={users}
              />
              <AddressDetailContainer>
                <ReservationDetailItem
                  label={t("reservation.detail.location")}
                  name={reservationDetails?.location.name || ""}
                  iconSrc={mapMarker}
                />
                <AddressMap {...coordinates} />
              </AddressDetailContainer>
            </ReservationDetails>
            <PricingSection>
              <Pricing>
                <ReservationStepSubtitle>
                  {t("reservation.pricing.title")}
                </ReservationStepSubtitle>
                <PricingItem
                  name={`${t(
                    "reservation.detail.yacht-rate"
                  )} (${numberOfHours} hrs)`}
                  price={yachtPrice}
                />
                {reservationDetails?.yachts_additionals.map(
                  ({ additional }) => (
                    <PricingItem
                      name={getTranslation("name", additional)}
                      price={getUserMembershipPrice(
                        additional.prices || [],
                        user?.membership
                      )}
                      key={additional.id}
                    />
                  )
                )}
              </Pricing>
              <TotalSectionContainer>
                <div>
                  <TotalPriceTitle>Total</TotalPriceTitle>
                  <TotalPrice>
                    {getFormattedPrice(reservationDetails?.charge.paid)}{" "}
                    <Currency>{reservationDetails?.charge.currency}</Currency>
                  </TotalPrice>
                </div>
              </TotalSectionContainer>
            </PricingSection>
          </ReservationResumeContent>
          <YachtImagesSlider images={reservationDetails?.yacht?.images || []} />
        </MainReservationLayout>
      </FetchSection>
    </ShipLayout>
  );
};

export default BookingDetails;
