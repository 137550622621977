import { FC } from "react";
import styled from "styled-components";
import Card, { ICard } from "components/atoms/Card";

const Container = styled.div`
  display: grid;
  height: 100%;
  padding: 20px;
  place-items: center;
  width: 100%;
`;

const CenteredCard: FC<ICard> = ({ children, ...cardProps }) => (
  <Container>
    <Card {...cardProps}>{children}</Card>
  </Container>
);

export default CenteredCard;
