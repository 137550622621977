import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const MyBookingsFilters = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto;
  margin: 0 auto;
  width: fit-content;

  @media ${desktop} {
    margin: 0;
  }
`;

export { MyBookingsFilters };
