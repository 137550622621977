import styled from "styled-components";

const PoliciesContainer = styled.div`
  display: grid;
  grid-gap: 30px;

  & p {
    padding-bottom: 0.5rem;
  }

  & strong {
    font-size: 16px;
    font-weight: normal;
    color: black;
    text-decoration: underline;
  }
`;

export { PoliciesContainer };
