import axios, { AxiosRequestConfig } from "config/axios";
import type { IFetchResponse } from "shared/types/common";

const Http = () => {
  const get = async <T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<T> => {
    const axiosResponse = await axios.get<IFetchResponse<T>>(url, config);
    return axiosResponse.data.data;
  };

  const post = async <T>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig
  ): Promise<T> => {
    const axiosResponse = await axios.post<IFetchResponse<T>>(
      url,
      data,
      config
    );
    return axiosResponse.data.data;
  };

  const put = async <T>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig
  ): Promise<T> => {
    const axiosResponse = await axios.put<IFetchResponse<T>>(url, data, config);
    return axiosResponse.data.data;
  };

  const remove = async <T>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<T> => {
    const axiosResponse = await axios.delete<IFetchResponse<T>>(url, config);
    return axiosResponse.data.data;
  };

  return {
    get,
    post,
    put,
    remove,
  };
};

export default Http();
