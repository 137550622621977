import useAuthContext from "context/AuthContext";
import {
  MyMembershipSection,
  MyMembershipTitle,
  MyMembershipInformationItem,
  MyMembershipInformationSection,
  MyMembershipSectionTitle,
  MyMembershipSectionsContainer,
  MembershipImagesSlider,
  MyMembershipInformationList,
} from "components/atoms/Profile/MyMembership";
import { useTranslation } from "react-i18next";
import useUserMembershipTitle from "hooks/useUserMembershipTitle";
import regularMembershipImages from "data/regularMembershipImages";
import getFormattedDate from "utils/getFormattedDate";
import { When } from "react-if";
import IsEmptyMessageSection from "components/atoms/IsEmptyMessageSection";
import useTranslatedProperty from "hooks/useTranslatedProperty";

const MyMembership = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { userMembershipTitle } = useUserMembershipTitle();
  const getTranslation = useTranslatedProperty();
  const membershipImages = user?.membership?.images || regularMembershipImages;
  const userHasMembership = Boolean(user?.membership);
  const membershipDescription = userHasMembership
    ? getTranslation("description", user?.membership)
    : t("profile.membership.regular.benefit");
  const userConvertors = user?.convertors || [];
  const membershipExpirationDate = user?.profile?.membership_expiration;
  const newDescription = membershipDescription.replace(/\n/g, "<br>");

  return (
    <MyMembershipSection>
      <MyMembershipTitle>{userMembershipTitle}</MyMembershipTitle>
      <MyMembershipSectionsContainer>
        <MyMembershipInformationSection>
          <MyMembershipInformationList>
            <MyMembershipSectionTitle>
              {t("profile.membership.info.title")}
            </MyMembershipSectionTitle>
            <When
              condition={Boolean(membershipExpirationDate && userHasMembership)}
            >
              <MyMembershipInformationItem>
                <div dangerouslySetInnerHTML={{ __html: newDescription }} />
              </MyMembershipInformationItem>
              <MyMembershipInformationItem>
                {t("profile.membership.valid-until")}{" "}
                {getFormattedDate(membershipExpirationDate)}
              </MyMembershipInformationItem>
            </When>
          </MyMembershipInformationList>
          <MyMembershipInformationList>
            <MyMembershipSectionTitle>
              {t("profile.membership.convertors.title")}
            </MyMembershipSectionTitle>
            {userConvertors.map((convertor) => (
              <MyMembershipInformationItem key={convertor.id}>
                {getTranslation("name", convertor)}
              </MyMembershipInformationItem>
            ))}
            <When condition={!userConvertors.length}>
              <IsEmptyMessageSection>
                {t("profile.convertors.empty")}
              </IsEmptyMessageSection>
            </When>
          </MyMembershipInformationList>
        </MyMembershipInformationSection>
        <MembershipImagesSlider images={membershipImages} />
      </MyMembershipSectionsContainer>
    </MyMembershipSection>
  );
};

export default MyMembership;
