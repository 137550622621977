import styled from "styled-components";
import { FormSection } from "components/atoms/form";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const EditProfileAvatarSection = styled(FormSection)`
  @media ${desktop} {
    justify-items: flex-end;
    padding: 30px;
  }
`;

export { EditProfileAvatarSection };
