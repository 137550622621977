import http from "services/http";
import type { IYachtSize } from "shared/types/yacht";

const SizeService = () => {
  const getYachtSizes = () => http.get<IYachtSize[]>("/size");

  return {
    getYachtSizes,
  };
};

export default SizeService();
