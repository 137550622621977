import { Switch } from "react-router-dom";
import ProfileTemplate from "components/templates/ProfileTemplate";
import MyMembership from "./MyMembership";
import MyBookings from "./MyBookings";
import MyPaymentMethods from "./MyPaymentMethods";
import PrivateRoute from "components/atoms/PrivateRoute";
import routes from "shared/constants/routes";

const { profile, userPaymentMethods, userReservations } = routes;

const Profile = () => (
  <ProfileTemplate>
    <Switch>
      <PrivateRoute path={profile} exact>
        <MyMembership />
      </PrivateRoute>
      <PrivateRoute path={userPaymentMethods} exact>
        <MyPaymentMethods />
      </PrivateRoute>
      <PrivateRoute path={userReservations} exact>
        <MyBookings />
      </PrivateRoute>
    </Switch>
  </ProfileTemplate>
);

export default Profile;
