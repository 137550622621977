import { forwardRef, ChangeEventHandler, FocusEventHandler } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  colors,
  fontSizes,
  fonts,
  borders,
  devices,
  fontWeights,
} from "shared/constants/design";

const { text, borderColor, blackShadowLight, white } = colors;
const { xsm, md, sm } = fontSizes;
const { nexaBold, conthrax } = fonts;
const { borderRadius } = borders;
const { desktop } = devices;
const { semiBold } = fontWeights;

const Container = styled.div`
  display: grid;
  grid-gap: 10px;
  height: fit-content;
  width: 100%;
`;

const Label = styled.label`
  color: ${white};
  font: italic normal ${semiBold} ${xsm} ${conthrax};
  line-break: anywhere;
  line-height: ${xsm};
  margin: 0;
  text-transform: uppercase;

  @media ${desktop} {
    font-size: ${md};
    line-height: ${md};
  }
`;

const BaseTextArea = styled.textarea`
  border-radius: ${borderRadius};
  border: 1px solid ${borderColor};
  box-shadow: 0px 3px 6px ${blackShadowLight};
  color: ${text};
  font-family: ${nexaBold};
  font-size: ${xsm};
  height: 130px;
  letter-spacing: 0.63px;
  line-height: 20px;
  padding: 6px;
  width: 100%;
  overflow: hidden;

  ::placeholder {
    opacity: 0.5;
  }

  @media ${desktop} {
    height: 120px;
    font-size: ${sm};
  }
`;

interface IInput {
  name: string;
  placeholder?: string;
  hasError?: boolean;
  errorMessage?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  textarea?: boolean;
}

const Annotations = forwardRef<HTMLTextAreaElement, IInput>(
  (
    {
      hasError = false,
      errorMessage = "",
      textarea = false,
      ...inputProps
    }: IInput,
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <Container>
        <Label>{t("reservation.checkout.comments")}</Label>
        <BaseTextArea
          {...inputProps}
          ref={ref}
          className="form-control"
          placeholder={t("reservation.checkout.text-area.placeholder")}
        />
      </Container>
    );
  }
);

export { Annotations };
