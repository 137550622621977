import styled from "styled-components";

const PaymentMethodsSection = styled.div`
  display: grid;
  grid-gap: 20px;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
`;

export { PaymentMethodsSection };
