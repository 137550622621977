import { useTranslation } from "react-i18next";
import { ENotificationType } from "shared/types/notification";
import useNotification from "./useNotification";

const useValidateReserveConfiguration = (
  startHour?: Date,
  endHour?: Date,
  numberOfPassengers?: number
) => {
  const { t } = useTranslation();
  const notify = useNotification();

  const validateEndHourAndPassengersNumber = () => {
    if (!endHour && !numberOfPassengers) {
      notify({
        type: ENotificationType.ERROR,
        description: t("reservation.config.error.passengers-end-hour"),
      });
    }
  };

  const validateWholeConfiguration = () => {
    if (!startHour && !numberOfPassengers && !endHour) {
      notify({
        type: ENotificationType.ERROR,
        description: t("reservation.config.error.config"),
      });
    }
  };

  const validateStartAndEndHours = () => {
    if (!startHour && !endHour) {
      notify({
        type: ENotificationType.ERROR,
        description: t("reservation.config.error.start-end-hour"),
      });
    }
  };

  const validateStartHour = () => {
    if (!startHour) {
      notify({
        type: ENotificationType.ERROR,
        description: t("reservation.config.error.start-hour"),
      });
    }
  };

  const validateEndHour = () => {
    if (!endHour) {
      notify({
        type: ENotificationType.ERROR,
        description: t("reservation.config.error.end-hour"),
      });
    }
  };

  const validateNumberOfPassengers = () => {
    if (!numberOfPassengers) {
      notify({
        type: ENotificationType.ERROR,
        description: t("reservation.config.error.passengers"),
      });
    }
  };

  return {
    validateEndHourAndPassengersNumber,
    validateWholeConfiguration,
    validateStartAndEndHours,
    validateStartHour,
    validateEndHour,
    validateNumberOfPassengers,
  };
};

export default useValidateReserveConfiguration;
