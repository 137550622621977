import styled from "styled-components";
import { devices } from "shared/constants/design";

const { desktop } = devices;

const YachtCardDetailIcon = styled.img`
  height: 6px;
  width: auto;

  @media ${desktop} {
    height: 12px;
  }
`;

export { YachtCardDetailIcon };
