import { useState, FC, useEffect } from "react";
import AuthContext, { IAuthContext } from "./AuthContext";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import tenantService from "services/tenant";
import authService from "services/auth";
import userService from "services/user";
import storageKeys from "shared/constants/storageKeys";
import { IUser, EUserRole, EPartnerStatus } from "shared/types/user";
import type {
  IRegisterUser,
  ILoginUser,
  IChangePassword,
} from "shared/types/auth";
import useQueryParams from "hooks/useQueryParams";
import usePageUrl from "hooks/usePageUrl";
import { IActivateUser } from "shared/types/auth/IActivateUser";
import LoginError from "pages/LoginError";
import Activate from "pages/Activate";
import PageTemplate from "components/templates/PageTemplate";
import { EAvailableLanguages, mapLocaleToLanguage } from "shared/types/common";
import mixpanelService from "services/mixpanel";
import routes from "shared/constants/routes";

interface IAutoLoginState {
  isValidatingSession: boolean;
  hasSessionValidationError: boolean;
  userRequiresMembershipActivation: boolean;
  sessionValidated: boolean;
}

const { userTokenKey } = storageKeys;

const getInitialIsLoggedIn = () => {
  const tokenInLocalStorage = localStorage.getItem(userTokenKey);
  const tokenInSessionStorage = sessionStorage.getItem(userTokenKey);
  return Boolean(tokenInLocalStorage || tokenInSessionStorage);
};

const allowedRoutesWithDisabledMembership = [routes.policies];

const AuthProvider: FC = ({ children }) => {
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const isAllowedRouteWithDisabledMembership =
    allowedRoutesWithDisabledMembership.includes(pathname);
  const sessionToken = useQueryParams([
    tenantService.getConfigs().queryParams.tokenParam,
  ])[tenantService.getConfigs().queryParams.tokenParam];
  const history = useHistory();
  const pageUrl = usePageUrl();
  const allowLoginFlag = tenantService.getConfigs().flags.allowAutoLogin;
  const [autoLoginState, setAutoLoginState] = useState<IAutoLoginState>({
    isValidatingSession: false,
    hasSessionValidationError: false,
    userRequiresMembershipActivation: false,
    sessionValidated: false,
  });
  const [isLoggedIn, setIsLoggedIn] = useState(getInitialIsLoggedIn());
  const [user, setUser] = useState<IUser | undefined>(undefined);

  const fetchAndSetLoggedInUser = async () => {
    setAutoLoginState({
      isValidatingSession: true,
      hasSessionValidationError: false,
      userRequiresMembershipActivation: false,
      sessionValidated: false,
    });

    try {
      const { user, needsMembershipActivation } = await userService.getMe();
      setUser(user);

      if (needsMembershipActivation) {
        setAutoLoginState({
          isValidatingSession: false,
          hasSessionValidationError: false,
          userRequiresMembershipActivation: true,
          sessionValidated: false,
        });
      } else {
        setAutoLoginState({
          isValidatingSession: false,
          hasSessionValidationError: false,
          userRequiresMembershipActivation: false,
          sessionValidated: true,
        });
      }
    } catch (error: any) {
      mixpanelService.track.userLoggedOut();
      setIsLoggedIn(false);
      localStorage.removeItem(userTokenKey);
      sessionStorage.removeItem(userTokenKey);

      setAutoLoginState({
        isValidatingSession: false,
        hasSessionValidationError: true,
        userRequiresMembershipActivation: false,
        sessionValidated: false,
      });
    }
  };

  const autoLogin = async () => {
    if (!sessionToken) {
      setAutoLoginState({
        isValidatingSession: false,
        hasSessionValidationError: true,
        userRequiresMembershipActivation: false,
        sessionValidated: false,
      });
      return;
    }

    setAutoLoginState({
      isValidatingSession: true,
      hasSessionValidationError: false,
      userRequiresMembershipActivation: false,
      sessionValidated: false,
    });

    pageUrl.delete(tenantService.getConfigs().queryParams.tokenParam);
    history.replace({ search: pageUrl.toString() });

    try {
      const {
        token: { token },
        needsMembershipActivation,
        language,
        user,
      } = await authService.loginWithToken(sessionToken || "");

      if (language) {
        i18n.changeLanguage(
          // @ts-ignore - we know that language can invalid, that's why we have the fallback
          mapLocaleToLanguage[language] || EAvailableLanguages.ES
        );
        userService.updateUserLanguage({
          userId: user.id,
          // @ts-ignore - we know that language can invalid, that's why we have the fallback
          locale: mapLocaleToLanguage[language] || EAvailableLanguages.ES,
        });
      }

      if (needsMembershipActivation) {
        setAutoLoginState({
          isValidatingSession: false,
          hasSessionValidationError: false,
          userRequiresMembershipActivation: true,
          sessionValidated: false,
        });
      } else {
        setAutoLoginState({
          isValidatingSession: false,
          hasSessionValidationError: false,
          userRequiresMembershipActivation: false,
          sessionValidated: true,
        });
        const userFullName = user.first_name + " " + user.last_name;
        mixpanelService.track.userLoggedIn({
          fullName: userFullName,
          email: user.email,
          country: user.country?.name || "Not specified",
          membership: user.membership?.name || "Free",
          isAutoLoginWithToken: true,
        });
      }
      saveSession(token);
    } catch (error) {
      setAutoLoginState({
        isValidatingSession: false,
        hasSessionValidationError: true,
        userRequiresMembershipActivation: false,
        sessionValidated: false,
      });
    }
  };

  useEffect(() => {
    const redirectLink = tenantService.getConfigs().links.tenantLink;
    if (!sessionToken && !isLoggedIn && redirectLink) {
      window.location.href = redirectLink;
      return;
    }

    if (allowLoginFlag) {
      autoLogin();
    }
  }, []);

  const updateUserData = async () => {
    if (isLoggedIn) {
      await fetchAndSetLoggedInUser();
    }
  };

  useEffect(() => {
    updateUserData();
  }, [isLoggedIn]);

  const saveSession = (token: string, rememberMe?: boolean) => {
    if (rememberMe) {
      localStorage.setItem(userTokenKey, token);
    } else {
      sessionStorage.setItem(userTokenKey, token);
    }
    setIsLoggedIn(true);
  };

  const logOutUser = () => {
    localStorage.removeItem(userTokenKey);
    sessionStorage.removeItem(userTokenKey);
    mixpanelService.track.userLoggedOut();
    window?.location.reload();
  };

  const registerUser = async (
    body: Omit<IRegisterUser, "role" | "partner_status">,
    rememberMe?: boolean
  ) => {
    const {
      token: { token },
    } = await authService.register({
      ...body,
      role: EUserRole.PARTNER,
      partner_status: EPartnerStatus.LEAD,
    });
    saveSession(token, rememberMe);
  };

  const activateUserMembership = async (body: IActivateUser) => {
    const { user } = await authService.activateMembership(body);
    setUser(user);
  };

  const loginUser = async (body: ILoginUser, rememberMe?: boolean) => {
    const {
      token: { token },
    } = await authService.login(body);
    saveSession(token, rememberMe);
  };

  const sendResetPasswordTokenToEmail = async (email: string) =>
    await authService.sendResetPasswordTokenToEmail(email);

  const changePassword = async (body: IChangePassword) =>
    await authService.changePassword(body);

  const contextValue: IAuthContext = {
    isLoggedIn,
    user,
    registerUser,
    activateUserMembership,
    loginUser,
    sendResetPasswordTokenToEmail,
    changePassword,
    updateUserData,
    logOutUser,
  };

  const {
    isValidatingSession,
    hasSessionValidationError,
    userRequiresMembershipActivation,
    sessionValidated,
  } = autoLoginState;
  if (allowLoginFlag) {
    return (
      <AuthContext.Provider value={contextValue}>
        {isValidatingSession && <PageTemplate />}
        {hasSessionValidationError && <LoginError />}
        {userRequiresMembershipActivation &&
          !isAllowedRouteWithDisabledMembership && <Activate />}
        {userRequiresMembershipActivation &&
          isAllowedRouteWithDisabledMembership &&
          children}
        {sessionValidated && children}
      </AuthContext.Provider>
    );
  }

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export { AuthProvider };
