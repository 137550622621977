import styled from "styled-components";
import { colors, fontSizes, fonts } from "shared/constants/design";

const { error } = colors;
const { nexaRegular } = fonts;
const { xsm } = fontSizes;

const ErrorMessage = styled.p`
  color: ${error};
  font-family: ${nexaRegular};
  font-size: ${xsm};
  margin: 0;
`;

export { ErrorMessage };
