import styled from "styled-components";
import { fonts, fontSizes, colors } from "shared/constants/design";

const { white } = colors;
const { sm } = fontSizes;
const { nexaRegular } = fonts;

const TotalPriceTitle = styled.h6`
  color: ${white};
  font-family: ${nexaRegular};
  font-size: ${sm};
  line-height: ${sm};
  margin: 0;
  text-transform: uppercase;
`;

export { TotalPriceTitle };
