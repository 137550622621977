import styled from "styled-components";
import { colors, fontSizes, fonts } from "shared/constants/design";

const { text } = colors;
const { md } = fontSizes;
const { nexaBold } = fonts;

const YachtReserveCardDate = styled.h6`
  color: ${text};
  font: normal normal ${md} ${nexaBold};
  text-decoration: none;
  text-transform: uppercase;
`;

export { YachtReserveCardDate };
