import styled from "styled-components";
import { CreditCardActionArea } from "components/atoms/CreditCard";
import IconButton from "components/atoms/IconButton";
import assets from "shared/constants/assets";

const { plus } = assets;

const PlusContainer = styled.div`
  display: grid;
  place-items: center;
`;

interface INewPaymentMethodButton {
  onClick: () => void;
}

const NewPaymentMethodButton = (props: INewPaymentMethodButton) => (
  <CreditCardActionArea {...props}>
    <PlusContainer>
      <IconButton
        src={plus}
        width="14px"
        height="14px"
        alt="add new payment method"
      />
    </PlusContainer>
  </CreditCardActionArea>
);

export { NewPaymentMethodButton };
