import styled from "styled-components";

const Pricing = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  overflow-y: auto;
  width: 100%;
`;

export { Pricing };
