import { useTranslation } from "react-i18next";
import { Prompt } from "react-router";
import useReservationContext from "context/ReservationContext";
import PrimaryButton from "components/atoms/PrimaryButton";
import {
  MainReservationLayout,
  ReservationStepTitle,
  ReservationStepSubtitle,
  Currency,
  TotalSectionContainer,
} from "components/atoms/reservation";
import {
  ReservationDetailItem,
  ReservationDetails,
  Pricing,
  PricingItem,
  AddressMap,
  AddressDetailContainer,
  ReservationResumeContent,
  PricingSection,
} from "components/atoms/reservation/ReservationResume";
import {
  TotalPrice,
  TotalPriceTitle,
} from "components/atoms/reservation/SelectAddons";
import assets from "shared/constants/assets";
import getFormattedDate from "utils/getFormattedDate";
import getFormattedHour from "utils/getFormattedHour";
import getUserMembershipPrice from "utils/getUserMembershipPrice";
import useAuthContext from "context/AuthContext";
import getFormattedPrice from "utils/getFormattedPrice";
import getReservationNumberOfHours from "utils/getReservationNumberOfHours";
import { IReservation } from "shared/types/reservation";
import ImagesSliderSection from "./ImagesSliderSection";
import useTranslatedProperty from "hooks/useTranslatedProperty";
import { GoBackButtonContainer } from "components/atoms/reservation";
import SecondaryButton from "components/atoms/SecondaryButton";

const { ship, arrowRight, arrowLeft, users, mapMarker } = assets;

const ReservationResume = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const {
    selectedYacht,
    goToCheckout,
    date,
    startHour,
    endHour,
    numberOfPassengers,
    bookingTotal,
    selectedAddons,
    onPreviousStep,
  } = useReservationContext();
  const getTranslation = useTranslatedProperty();
  const numberOfHours = getReservationNumberOfHours({
    start_date: startHour,
    end_date: endHour,
  } as IReservation);
  const yachtPrice =
    getUserMembershipPrice(selectedYacht?.prices || [], user?.membership) *
    numberOfHours;
  const coordinates = {
    lat: Number(selectedYacht?.latitude),
    lng: Number(selectedYacht?.longitude),
  };

  return (
    <>
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
          }

          return location.pathname === "/"
            ? true
            : `Si sales de esta página perderás la información que has introducido. ¿Estás seguro de que quieres salir?`;
        }}
      />
      <MainReservationLayout>
        <GoBackButtonContainer>
          <SecondaryButton onClick={onPreviousStep}>
            {t("go-back-button-label")}
          </SecondaryButton>
        </GoBackButtonContainer>
        <ReservationResumeContent>
          <ReservationDetails>
            <ReservationStepTitle>
              {t("reservation-details.title")}
            </ReservationStepTitle>
            <ReservationDetailItem
              label={t("reservation.detail.ship")}
              name={`${selectedYacht?.name || ""}${
                selectedYacht?.model ? `, ${selectedYacht?.model}` : ""
              }`}
              iconSrc={ship}
            />
            <ReservationDetailItem
              label={t("reservation.detail.departure")}
              name={`${getFormattedDate(date)} @ ${getFormattedHour(
                startHour
              )}`}
              iconSrc={arrowRight}
            />
            <ReservationDetailItem
              label={t("reservation.detail.arrival")}
              name={`${getFormattedDate(date)} @ ${getFormattedHour(endHour)}`}
              iconSrc={arrowLeft}
            />
          </ReservationDetails>
          <ReservationDetails>
            <ReservationDetailItem
              label={t("reservation.detail.size")}
              name={`${numberOfPassengers} ${t(
                "profile.reserves.card.passengers"
              )}`}
              iconSrc={users}
            />
            <AddressDetailContainer>
              <ReservationDetailItem
                label={t("reservation.detail.location")}
                name={selectedYacht?.location.name || ""}
                iconSrc={mapMarker}
              />
              <AddressMap {...coordinates} />
            </AddressDetailContainer>
          </ReservationDetails>
          <PricingSection>
            <Pricing>
              <ReservationStepSubtitle>
                {t("reservation.pricing.title")}
              </ReservationStepSubtitle>
              <PricingItem
                name={`${t(
                  "reservation.detail.yacht-rate"
                )} (${numberOfHours} hrs)`}
                price={yachtPrice}
              />
              {selectedAddons.map((addon) => (
                <PricingItem
                  name={getTranslation("name", addon)}
                  price={getUserMembershipPrice(
                    addon.prices || [],
                    user?.membership
                  )}
                  key={addon.id}
                />
              ))}
            </Pricing>
            <TotalSectionContainer>
              <div>
                <TotalPriceTitle>Total</TotalPriceTitle>
                <TotalPrice>
                  {getFormattedPrice(bookingTotal)} <Currency>usd</Currency>
                </TotalPrice>
              </div>
              <PrimaryButton onClick={goToCheckout}>
                {t("book-button-label")}
              </PrimaryButton>
            </TotalSectionContainer>
          </PricingSection>
        </ReservationResumeContent>
        <ImagesSliderSection />
      </MainReservationLayout>
    </>
  );
};

export default ReservationResume;
