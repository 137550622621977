import styled from "styled-components";
import {
  fonts,
  fontSizes,
  fontWeights,
  colors,
  devices,
} from "shared/constants/design";

const { text } = colors;
const { semiBold } = fontWeights;
const { md, xl } = fontSizes;
const { conthrax } = fonts;
const { desktop } = devices;

const YachtCardTitle = styled.h3`
  color: ${text};
  font: italic normal ${semiBold} ${md} ${conthrax};
  line-height: 24px;
  margin: 0;
  text-transform: uppercase;
  width: fit-content;

  @media ${desktop} {
    font-size: ${xl};
    line-height: 42px;
  }
`;

export { YachtCardTitle };
