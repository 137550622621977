import { FC } from "react";
import Text from "components/atoms/Text";
import { LoaderSectionContainer } from "components/atoms/LoaderSection";

const IsEmptyMessageSection: FC = ({ children }) => (
  <LoaderSectionContainer>
    <Text>{children}</Text>
  </LoaderSectionContainer>
);

export default IsEmptyMessageSection;
