import styled from "styled-components";

const CreditCardIcons = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: auto auto;
  width: fit-content;
`;

export { CreditCardIcons };
