import styled from "styled-components";
import { devices } from "shared/constants/design";

const { tabletSmall } = devices;

const SoonLocationsList = styled.div`
  display: grid;
  grid-gap: 15px;
  place-items: center;

  @media ${tabletSmall} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export { SoonLocationsList };
