import axios, { AxiosError, AxiosRequestConfig } from "axios";
import i18n from "i18next";
import environment from "shared/constants/environment";
import storageKeys from "shared/constants/storageKeys";
import tenantService from "services/tenant";

const { apiUrl } = environment;
const { userTokenKey } = storageKeys;

const axiosInstance = axios.create({
  baseURL: apiUrl,
});

axiosInstance.interceptors.request.use((req) => {
  const tokenInLocalStorage = localStorage.getItem(userTokenKey);
  const tokenInSessionStorage = sessionStorage.getItem(userTokenKey);
  const tenantId = tenantService.getId();

  if (tokenInLocalStorage) {
    req.headers.authorization = `Bearer ${tokenInLocalStorage}`;
  }
  if (tokenInSessionStorage) {
    req.headers.authorization = `Bearer ${tokenInSessionStorage}`;
  }
  if (tenantId) {
    req.headers["YV-Tenant-ID"] = tenantId;
  }
  req.headers["Accept-Language"] = i18n.language;
  return req;
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error: AxiosError) => {
    if (error.response?.data.message) {
      throw new Error(error?.response?.data.message);
    }
    throw error;
  }
);

export default axiosInstance;
export type { AxiosRequestConfig };
